import React from "react";
import { Provider } from "react-redux";
import store from "./Appstore/store";
import RouteComp from "./routes/router";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.scss";
import config from "./config";
import IdleTimerContainer from "./components/IdleTimerContainer";


console.log(`App version- ${config.appVersion}`);
console.log('ENV--->',process.env.REACT_APP_API_ENV)
function App() {
  return (
    <Provider store={store}>
      <IdleTimerContainer/>
      <RouteComp />
    </Provider>
  );
}

export default App;
