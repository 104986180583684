import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Dashboard from "../containers/Dashboard";

const RouteComp = (props) => {  
  return (
    <div>
      <div>
        <Switch>
          <Route exact path="/app" component={Dashboard} />
          <Route
            render={() => <div history={props.history}> Route Not Found </div>}
          />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(RouteComp);
