import React from "react";
import { toast } from "react-toastify";
import correctImg from "../Assets/images/correct_green.svg";
import errorImg from "../Assets/images/new_error.svg";

export const isMobile = () => {
  if (process.env.BROWSER || window) {
    const viewSize = window && window.innerWidth;
    if (viewSize < 768) {
      return true;
    }
    return false;
  }
};
export const agentCode = localStorage.getItem('agentCode');

const Msg = ({ closeToast, heading, message, icon }) => {
  return (<div className="toastMainWrap">
    <img src={icon ? icon : correctImg} alt="" />
    <p>{heading && <span>{heading}</span>} {message}</p>
  </div>)
}


export const toaster = (type, msg, heading) => {
  const text = msg || "Something went wrong !";
  switch (type) {
    case "success":
      toast.success(<Msg message={text} heading={heading} />);
      break;
    case "error":
      toast.error(<Msg message={text} heading={heading} icon={errorImg} />);
      break;
    case "warn":
      toast.warn(<Msg message={text} heading={heading} />);
      break;
    case "info":
      toast.info(<Msg message={text} heading={heading} />);
      break;
    default:
      toast.info(<Msg message={text} heading={heading} />);
  }
};

export const formateDate = (dv) => {
  if (!dv) {
    return null;
  } else {
    let dd = String(dv.getDate()).padStart(2, "0");
    let mm = String(dv.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = dv.getFullYear();
    let formatted = `${dd}/${mm}/${yyyy}`;
    return formatted;
  }
};
export const formateDateAndTime = (dv) => {
  if (!dv) {
    return null;
  } else {
    var dateTime = dv.split(" ");
    var date = dateTime[0].split("-");
    let newdate = `${date[1]}/${date[0]}/${date[2]} ${dateTime[1]}`;
    return newdate;
  }
};

export const formateDateFormat = (dv) => {
  if (!dv) {
    return null;
  } else {
    var date = dv.split("-");
    let newdate = `${date[1]}/${date[0]}/${date[2]}`;
    return newdate;
  }
};

export const convertNumberToWords = (amount) => {
  if(!amount) return "";
  var words = new Array();
  words[0] = "";
  words[1] = "One";
  words[2] = "Two";
  words[3] = "Three";
  words[4] = "Four";
  words[5] = "Five";
  words[6] = "Six";
  words[7] = "Seven";
  words[8] = "Eight";
  words[9] = "Nine";
  words[10] = "Ten";
  words[11] = "Eleven";
  words[12] = "Twelve";
  words[13] = "Thirteen";
  words[14] = "Fourteen";
  words[15] = "Fifteen";
  words[16] = "Sixteen";
  words[17] = "Seventeen";
  words[18] = "Eighteen";
  words[19] = "Nineteen";
  words[20] = "Twenty";
  words[30] = "Thirty";
  words[40] = "Forty";
  words[50] = "Fifty";
  words[60] = "Sixty";
  words[70] = "Seventy";
  words[80] = "Eighty";
  words[90] = "Ninety";
  amount = amount.toString();
  var atemp = amount.split(".");
  var number = atemp[0].split(",").join("");
  var n_length = number.length;
  var words_string = "";
  if (n_length <= 9) {
    var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    var received_n_array = new Array();
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    let value = "";
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += words[value] + " ";
      }
      if (
        (i == 1 && value != 0) ||
        (i == 0 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Crores ";
      }
      if (
        (i == 3 && value != 0) ||
        (i == 2 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Lakhs ";
      }
      if (
        (i == 5 && value != 0) ||
        (i == 4 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Thousand ";
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += "Hundred and ";
      } else if (i == 6 && value != 0) {
        words_string += "Hundred ";
      }
    }
    words_string = `${words_string && "Rupees "} ${words_string
      .split("  ")
      .join(" ")}`;
  }
  return words_string;
};
export const isDateValid = (date) => {
  var d = new Date(date);
  return !(d.toString() == "Invalid Date");
};

export const copyLinkToClipboard = (link) => {
  let x = document.createElement("INPUT");
  x.setAttribute("type", "text");
  x.setAttribute("value", link);
  x.setAttribute("id", "div1");
  document.body.appendChild(x);
  let a = document.getElementById("div1");
  a.select();
  document.execCommand("copy");
  a.style.display = "none";
  document.getElementById("div1").remove();
  toaster("success", "Link Copied!");
}

export const  isNativeApp = () =>{
  var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);
  
  if (ios) {
    if (!standalone && safari) {
      return false
    } else if (!standalone && !safari) {
      return true
    }else return  false
  } else {
    if (userAgent.includes('wv')) {
      return true
    } else {
      return false
    }
  };
  
  }