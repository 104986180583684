import React, { useState, useEffect } from "react";
import MobilePopUp from "../../MobilePopUp/MobilePopUp";
import sortOptions from "../../../constants/sortOptions";
import filterMobile from "../../../Assets/images/filterMobile.svg";
import sortMobile from "../../../Assets/images/sortMobile.svg";
import Calendar from "../../../components/Calendar/Calendar";
import { formateDate, isMobile } from "../../../utils/utils";

const SortAndFilterMobile = ({
  filterOptionsList,
  sortValue,
  applySort,
  applyFilter,
  filterValue,
  applyDateRange,
  endTimestamp,
  startTimestamp,
  setDateReq,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedSort, setSelectedSort] = useState("LastUpdated");
  // const [showByDateRange, setShowByDateRange] = useState(false);
  const [showByDateRangeFrom, setShowByDateRangeFrom] = useState(false);
  const [showDateRangeTo, setShowDateRangeTo] = useState(false);
  const [showDurationSec, setShowDurationSec] = useState(true);
  const [showStatusSec, setShowStatusSec] = useState(false);

  const [disableToDate, setDisableToDate] = useState(false);
  const [enableEndDate, setEnableEndDate] = useState(false);
  const [showDateRangeMsg, setDateRangeMsg] = useState(false);

  const showSelectedMobilePopUp = (
    filter = false,
    sort = false,
    dateRangeFrom = false,
    dateRangeTo = false
  ) => {
    setShowFilter(filter);
    setShowSort(sort);
    setShowByDateRangeFrom(dateRangeFrom);
    setShowDateRangeTo(dateRangeTo);
  };
  const selectFilter = (filter) => {
    setSelectedFilter(filter);
    showSelectedMobilePopUp();
    applyFilter(filter);
  };
  const selectSort = (sort) => {
    setSelectedSort(sort);
    applySort(sort);
    setShowSort(false);
  };
  //date range mindate 60 days from today date
  let todayDate = new Date();
  let minCalDate=todayDate.setDate(todayDate.getDate() - 60);

  useEffect(() => {
    if (Object.keys(filterOptionsList).length > 0) {
      setSelectedFilter(filterOptionsList.by_time[0].reqKey);
    }
    if (sortValue) {
      setSelectedSort(sortValue);
    }
    if (filterValue) {
      setSelectedFilter(filterValue);
    }
  }, [filterOptionsList, sortValue, selectedSort, filterValue]);

  useEffect(() => {
    if(startTimestamp){
      setEnableEndDate(true)
    
      let tsDifference = endTimestamp - startTimestamp;
      let dateDiff = Math.floor(tsDifference / (1000 * 60 * 60 * 24));
      
      if (dateDiff > 60 ) {
        setDateRangeMsg(true)
        setDisableToDate(true)    
      }
      else{
        setDateRangeMsg(false)
        setDisableToDate(false)
      }
    }
  }, [startTimestamp, endTimestamp])

  return (
    <>
      <div role="sortFilterMobile_testRole" data-testid="sortFilterMobile_testId" className="sort-filter-container">
        {isMobile() ? (
          <>
            <div
              className="filter-container"
              onClick={() => showSelectedMobilePopUp(true, false, false, false)}
            >
              <span className="label">
                <strong>
                  <img
                    src={filterMobile}
                    alt="filter-icon"
                    className="mobile-icons"
                  />{" "}
                  FILTER BY
                  <sup
                    className={`${(selectedFilter && selectedFilter !== "all") ||
                      (startTimestamp && endTimestamp)
                      ? "visible"
                      : "invisible"
                      }`}
                  >
                    <div className="sup-red-dot"></div>
                  </sup>
                </strong>
              </span>
            </div>
            <div
              className="sort-container"
              onClick={() => showSelectedMobilePopUp(false, true, false, false)}
            >
              <span className="label">
                <strong>
                  <img
                    src={sortMobile}
                    alt="sort-icon"
                    className="mobile-icons"
                  />{" "}
                  SORT BY
                  <sup
                    className={`${selectedSort && selectedSort !== "LastUpdated"
                      ? "visible"
                      : "invisible"
                      }`}
                  >
                    <div className="sup-red-dot"></div>
                  </sup>
                </strong>
              </span>
            </div>
          </>
        ) : (
          <div className="desk-sort-filter-wrapper">
            <div
              className="bkg-wrapper"
              onClick={() => showSelectedMobilePopUp(true, false, false, false)}
            >
              <div className="filter" data-testid="sortFilterMobile_filterIcon"></div>
            </div>
            <div
              className="bkg-wrapper"
              onClick={() => showSelectedMobilePopUp(false, true, false, false)}
            >
              <div className="sort" data-testid="sortFilterMobile_sortIcon"></div>
            </div>
          </div>
        )}
        {showFilter && (
          <MobilePopUp
            popUpBottom={true}
            heading="Filter by"
            cssClass="filter-pop-up"
            onClose={() => setShowFilter(false)}
          >
            <div className="filter-btm-wrapper">
              <div className="left-nav-sec">
                <div
                  onClick={() => {
                    setShowDurationSec(true);
                    setShowStatusSec(false);
                  }}
                  className={`${showDurationSec ? "active" : ""}`}
                >
                  Duration
                </div>
                <div
                  onClick={() => {
                    setShowStatusSec(true);
                    setShowDurationSec(false);
                  }}
                  className={`${showStatusSec ? "active" : ""}`}
                >
                  Status
                </div>
              </div>
              <div className="right-sec">
                {showDurationSec && (
                  <div className="duration-sec-wrapper">
                    {Object.keys(filterOptionsList).length > 0 &&
                      filterOptionsList.by_time.map((item, index) => {
                        return (
                          <div
                            className={`duration-option 
                                  ${selectedFilter === item.reqKey &&
                              !startTimestamp &&
                              !endTimestamp &&
                              "selected"
                              }
                                  `}
                            onClick={() => selectFilter(item.reqKey)}
                            key={index}
                          >
                            <div>{item.name}</div>
                            <div className="count">({item.count})</div>
                          </div>
                        );
                      })}
                    <div
                      className={`dateRangeSec ${startTimestamp && endTimestamp && "selected"
                        }`}
                    // onClick={() => {
                    //   setSelectedFilter(null);
                    // }}
                    >
                      <label className="date-range-text">Date Range</label>
                      <div className="date-input-row">
                        <div className="circle line"></div>
                        <label>From</label>
                        <div
                          className="input-box"
                          onClick={() => {
                            showSelectedMobilePopUp(false, false, true, false);
                            setSelectedFilter(null);
                          }}
                        >
                          <span
                            className={`date-value ${startTimestamp ? "active" : ""
                              }`}
                          >
                            {startTimestamp
                              ? formateDate(new Date(startTimestamp))
                              : "DD/MM/YYYY"}
                          </span>
                          <span className="calendar_icon"></span>
                        </div>
                      </div>
                      <div className="date-input-row">
                        <div className="circle"></div>
                        <label>To</label>

                        <div
                          className={`input-box ${!enableEndDate ? "disableClass" : ""} `}
                         

                          onClick={() => {
                            if (enableEndDate) {
                              showSelectedMobilePopUp(false, false, false, true);
                              setSelectedFilter(null);
                            }
                          }}
                        >
                          <span
                            className={`date-value ${endTimestamp ? "active" : ""
                              }`}
                          >
                            {endTimestamp
                              ? formateDate(new Date(endTimestamp))
                              : "DD/MM/YYYY"}
                          </span>
                          <span className="calendar_icon"></span>
                        </div>
                      </div>
                      {showDateRangeMsg && <div className="dateMsg">
                        Date Range should not be older than 60 calendar days.
                      </div>
                      }
                    </div>
                  </div>
                )}
                {showStatusSec && (
                  <div className="duration-sec-wrapper">
                    {Object.keys(filterOptionsList).length > 0 &&
                      filterOptionsList.by_status.map((item, index) => {
                        return (
                          <div
                            className={`duration-option ${selectedFilter === item.reqKey && "selected"
                              }`}
                            onClick={() => selectFilter(item.reqKey)}
                            key={index}
                          >
                            <div>{item.name}</div>
                            <div className="count">({item.count})</div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
              {startTimestamp && endTimestamp && 
              (
                <div className="date-range-apply-btn">
                  <button
                    onClick={() => {
                      applyDateRange();
                      showSelectedMobilePopUp();
                    }}
                    className={`${disableToDate ? 'disableClass' :''}`}
                    disabled={disableToDate}
                  >
                    Apply
                  </button>
                </div>
              )}
            </div>
          </MobilePopUp>
        )}
        {showSort && (
          <MobilePopUp
            popUpBottom={true}
            cssClass="filter-pop-up"
            onClose={() => setShowSort(false)}
            heading={"Sort By"}
          >
            {sortOptions.length > 0 &&
              sortOptions.map((item, index) => (
                <p
                  className={`sortOptions ${selectedSort === item.reqKey ? "selected" : ""
                    }`}
                  onClick={() => selectSort(item.reqKey)}
                  key={index}
                >
                  {item.name}
                </p>
              ))}
          </MobilePopUp>
        )}
      </div>
      {/* {showByDateRange && (
        <DateRange
          applyDateRange={applyDateRange}
          onCancel={closeDateRange}
          startTimestamp={startTimestamp}
          endTimestamp={endTimestamp}
        />
      )} */}
      {showByDateRangeFrom && (
        <MobilePopUp
          hideCloseBtn={true}
          removeHeader={true}
          cssClass="daterange-mobilepopup"
        >
          <Calendar
            dob={startTimestamp}
            minDate={minCalDate}
            maxDate={endTimestamp ? endTimestamp : new Date()}
            onCancel={() => {
              setShowByDateRangeFrom(false);
              showSelectedMobilePopUp(true);
              setDateReq("startTimestamp", "");
            }}
            onApply={(sd) => {
              setShowByDateRangeFrom(false);
              showSelectedMobilePopUp(true);
              setDateReq("startTimestamp", sd);
            }}
          />
        </MobilePopUp>
      )}
      {showDateRangeTo && (
        <MobilePopUp
          hideCloseBtn={true}
          removeHeader={true}
          cssClass="daterange-mobilepopup"
        >
          <Calendar
            dob={endTimestamp}
            minDate={startTimestamp}
            maxDate={new Date()}
            onCancel={() => {
              setShowDateRangeTo(false);
              showSelectedMobilePopUp(true);
              setDateReq("endTimestamp", "");
            }}
            onApply={(sd) => {
              setShowDateRangeTo(false);
              showSelectedMobilePopUp(true);
              setDateReq("endTimestamp", sd);
            }}
          />
        </MobilePopUp>
      )}
    </>
  );
};

export default SortAndFilterMobile;
