import React from "react";
import { connect } from 'react-redux';
import Info from "../../Assets/images/info.svg";
import starImg from "../../Assets/images/star.svg";
import starFillImg from "../../Assets/images/starfilled.svg";
import editIcon from "../../Assets/images/ic_edit.svg";
import apiConstants from "../../constants/apiConstants";
import { toast } from "react-toastify";
import {isMobile, isNativeApp} from "../../utils/utils"

class AppCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHighlight:false
    }
  }
  goToMCustomer = () => {
      this.setState({
        showHighlight:true
      })
   this.props.showHighlightFunc()
    const { data: { policyNumber, channelName } = {} } = this.props;
    if (policyNumber && (channelName === "CAB" || channelName === "BANCA")) {
      toast.error("Proposal form is freezed, please contact Digital App Support team at digitalappsupport@bhartiaxa.com", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      })
    } else if(isNativeApp()) {
      window.open(
        apiConstants.M_CUSTOMER_URL + "?proposal=" + this.props.data.uniqueProposalId + "&isCoBrowsing=true",
        "_self"
      );
    }
    else{
      window.open(
        apiConstants.M_CUSTOMER_URL + "?proposal=" + this.props.data.uniqueProposalId + "&isCoBrowsing=true",
        "_blank"
      );
    }
  };
  render() {
    const { data } = this.props;
    console.log("data", data);
    const item = {
      id: data.workflowId,
      workFlowStage: data.workFlowStage,
      showLink: data.workflowLink

    }
  // console.log('this.props.data.issuanceType',this.props.data)

    const status = data && data.journeyStatus && data.journeyStatus.split("_");
    return (
      <div data-testid="AppCard_component" className={`col-xs-12 col-lg-12 ax-white-card ${this.state.showHighlight && isNativeApp()  && "app-card"}`} onClick={this.goToMCustomer}>
        <div className="payment-wrap">
          <div className={`favourite-wrap`}>
            {/* <div onClick={this.goToMCustomer} className="edit-proposal">
              <img src={editIcon} alt="edit proposal"></img>
            </div> */}
            <div
              className={`${this.props.bookmarkChanging ? "disabled" : ""
                } ax-star`}
              onClick={(event) => {
                event.stopPropagation();
                this.props.createBookMark(
                  data.proposalId,
                  data.proposalBookmarked,
                  data.firstName
                );
              }}
            >
              {data.proposalBookmarked ? (
                <img src={starFillImg} alt="star icon" />
              ) : (
                <img src={starImg} alt="star icon" />
              )}
            </div>
            {/* <div
              className="ax-more-item hideOnDesktop"
              onClick={(event) => {
                event.stopPropagation();
                this.props.showInfo(data);
              }}
            >
              <img alt="Info" src={Info} />
            </div> */}
          </div>
          {data.pasaCase &&
            <div className="btn payment-btn removeHover" style={{ "marginRight": "10px" }}>
              Pasa Case
            </div>}
          <button
            data-testid="button"
            className="btn payment-btn removeHover"
            // data-tooltip={status && status.join(" ").toLowerCase()}
            onClick={(event) => {
              event.stopPropagation();
              this.props.showStatus(data, this.props.data.uniqueProposalId, this.props.data.subChannelName,this.props.data.issuanceType);
              
            }}  >
            {/* <span className="indicText">Status: </span> */}
            <span data-testid="status" className="statusText">{status && status.join(" ").toLowerCase()}</span>
            <img alt="Info" src={Info} />
          </button>

        </div>
        <div className="oneCol">
          <div className="innerOneCol">
            <h3 data-testid="c_name">{data.customerName}</h3>
            <p data-testid="plan_name">
              {data.planName}
            </p>
            <p data-testid="proposal_no">
              <span>Proposal Number:</span> {data.proposalNo}
              {data.journeyStatus === "Proposal Submitted" &&  data?.issuanceType === "EASE" &&(
                <span
                  className="exception-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.showException(
                      data.proposalNo,
                      data.customerName,
                      data.planName
                    );
                  }}
                ></span>
              )}
            </p>
          </div>

        </div>

        <div>
          {(item.showLink && item.workFlowStage !== "Medical TPA") ?
            <button className="btn btn-link btn-link-resendLink" onClick={(e) => this.props.openTpaProp(e, data, item)}>

              Additional Documents
            </button> : ""}
        </div>

        {/* <div className="left-one-col middle-one-col hideOnMobile twoCol">
          <div className="half-mid-col">
            <p>
              <span>Sum Assured:</span>
            </p>
            <h3>
              <span className="currency-icon">&#8377;</span>
              {data.sumAssured}
            </h3>
            <p>
              <span>Customer ID:</span>
            </p>
            <h3>{data.customerId}</h3>
          </div>
          <div className="half-mid-col">
            <p>
              <span>Premium:</span>
            </p>
            <h3>
              <span className="currency-icon">&#8377;</span>
              {data.premium}
            </h3>
            <p>
              <span>Lead ID:</span>
            </p>
            <h3>{data.leadId}</h3>
          </div>
        </div> */}

      </div >
    );
  }
}

export default AppCard;

