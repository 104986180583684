import React from "react";
import BackIcon from "../Assets/images/back.svg";
import userManualPdf from "../Assets/PDFs/UserManual.pdf";
import logout from "../Assets/images/logout.svg";
import config from "../config";
import PropTypes from "prop-types";

const UserProfileMobile = ({
  userName,
  userImg,
  UserManualIcon,
  HierarchyIcon,
  onBack,
  toggleHierarchy,
  logoutUser,
}) => {
  return (
    <div data-testid="UserProfileMobile_Component" className="u-p-mobile-container">
      <div className="user-header">
        <div className="u-p-mobile-name">
          <img
            src={BackIcon}
            alt="back"
            className="back-icon"
            onClick={() => onBack()}
          />{" "}
          {userName}
        </div>
        <div className="u-p-mobile-img-container">
          <img src={userImg} alt="user img" className="user-img-mobile" />
        </div>
      </div>
      <div
        className="u-p-mobile-options"
        onClick={() => window.open(userManualPdf, "_blank")}
      >
        <img src={UserManualIcon} alt="manual" className="option-img" /> User
        Manual
      </div>
      <div className="u-p-mobile-options" onClick={() => toggleHierarchy()}>
        <img src={HierarchyIcon} alt="manual" className="option-img" />{" "}
        Hierarchy
      </div>
      <div className="u-p-mobile-options" onClick={() => logoutUser()}>
        <img src={logout} alt="manual" className="option-img" /> Logout
      </div>

      <div className="app-version-wrapper">{`version: ${config.appVersion}`}</div>
    </div>
  );
};

UserProfileMobile.propTypes={
  userName: PropTypes.string,
  userImg: PropTypes.string,
  UserManualIcon: PropTypes.string,
  HierarchyIcon: PropTypes.string,
  onBack: PropTypes.func,
  toggleHierarchy: PropTypes.func,
  logoutUser: PropTypes.func,
}
export default UserProfileMobile;
