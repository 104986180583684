import actionTypes from '../constants/actionTypes';
const initialState = {
	appStatus: [],
	paymentStatus: [],
	instaVerifyLink: "",
	emandateLink: "",
	onlinePaymentLink: ""
};
export default function ( state = initialState, action) {
	switch (action.type) {
	  case actionTypes.fetchPaymentDetails:      
		return {
			...state,
			paymentStatus: action.payload
		};
	  case actionTypes.clearPaymentStatus:      
		return {
			...state,
			paymentStatus: []
		};
	  case actionTypes.fetchAppStatus:      
		return {
			...state,
			appStatus: action.payload
		};
	  case actionTypes.clearAppStatus:
		return {
		  ...state,
		  appStatus: [],
		};
		case actionTypes.copyInstaVerifyLink:
			return {
				...state,
				instaVerifyLink: action.payload
			};
		case actionTypes.copyEmandateLink:
		return {
			...state,
			emandateLink: action.payload
		};
		case actionTypes.copyOnlinePaymentLink:
		return {
			...state,
			onlinePaymentLink: action.payload
		};
	  default:
		return state;
	}
  }
  