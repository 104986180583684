import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import IdleTimer from 'react-idle-timer';
import { agentLogout } from "../../actions/dashBoardActions";
const urlParams = new URLSearchParams(window.location.search);
const sessionTimeout = urlParams.get("sessionTimeout");

const IdleTimerContainer = () => {
    const dispatch = useDispatch()
    const [isLoggedIn, setisLoggedIn] = useState(true)
    const idleTimerRef = useRef(null)

    const onIdle = () => {
        setisLoggedIn(false)
        dispatch(agentLogout())
    }
    return (
        <div>
            <IdleTimer
                ref={idleTimerRef}
                // timeout={1000 * 60 * 30}
                timeout={sessionTimeout === 'true' ? 10 : 1000 * 60 * 30}
                onIdle={onIdle}
            ></IdleTimer>
        </div>
    )
}
export default IdleTimerContainer
