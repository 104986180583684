import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { consentForChangeData } from "../data";
// import Input from "../../component/Input";
import ConsentLayout from "./ConsentLayout";
// import FormFieldConsent from "../../component/FormFieldConsent";
// import Image from "next/image";
// // import dwnImg from "../../Assets/images/pdf-dwn-arrow.png";
import { toaster } from "../../../utils/utils";
import { uploadAction, downloadAction, saveDetailAction } from "../action";
import { customerPortalStatus } from "../../../actions/dashBoardActions";
import UploadDocModal from "../../../containers/UploadDocModal";
import uplImg from "../../../Assets/images/upload_btn.png";
import greentick from "../../../Assets/images/green_check.svg";
import dowImg from "../../../Assets/images/download_icon.svg"
import dowImgArw from "../../../Assets/images/dwn-arw.png";
// import { sendOTPAction, verifyOTPAction } from "../../redux/action/OTPAction";
// import Aggree from "../../Assets/images/insta-verify.svg";
// import Reject from "../../Assets/images/reject-icon.png";
// import OTPInput from "../../container/OTPInput";
// import otpCross from "../../Assets/images/otp-cross-icon.png";
// import thankYou from "../../Assets/images/thank-you-bg.png";
// import ConsentReject from "../../component/PopUpPage/ConsentReject";
// import ConsentRejectMsg from "../../component/PopUpPage/ConsentRejectMsg";
import PopUp from "../../../containers/PopUp";

const Consent = ({ accDetails, accordionDetails, proposalNo,policyNo }) => {
  const [showConsent, setShowConsent] = useState(false);
  const [showConsentUpload, setShowConsentUpload] = useState(false);
  const [showUpload, setshowUpload] = useState(false);
  const [showConsentAction, setshowConsentAction] = useState("");
  const [document, setDocument] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showLabel, showSetLabel] = useState(false);
  const [uploadTrue, setUploadTrue] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [showOtp, setShowOtp] = useState(false);
  const [showThankyou, setShowThankyou] = useState();
  const [otp, setOtp] = useState("");
  const [refId, setRefId] = useState();
  const [overlay, setOverlay] = useState(false);
  const [showRejectPopUp, setshowRejectPopUp] = useState(false);
  const [showRejectMsgPopUp, setShowRejectMsgPopUp] = useState(false);
  const [showGreenTick, setshowGreenTick] = useState(false)
  const [rejectMsg, setRejectMsg] = useState("");
  const dispatch = useDispatch();
  const consentDetail =
    accordionDetails &&
    accordionDetails.filter((item) => item.status === "QUALITY_CHECK");
  const addConsentInfo =
    consentDetail &&
    Object.keys(consentDetail[0]?.additionalInfo)?.length !== 0 &&
    consentDetail &&
    consentDetail[0]?.additionalInfo;
  const policyDocuments = accDetails?.policyDocuments;
  const [action, setAction] = useState("");
  const dataChangeConsentdoc =
    accDetails?.dataChangeRequirement?.serviceDocumentList; // policy documents for data change
  const dataChangeConsentAction =
    accDetails?.dataChangeRequirement?.dataChangeConsentAction;

  // console.log("dataChangeConsentAction", dataChangeConsentAction);
  const renderElement = (data, title) => {
    let label;
    if (title === "Proposer Details") {
      label = "ProposerDetails";
    } else if (title === "Insured Details") {
      label = "InsuredDetails";
    } else if (title === "Plan Details") {
      label = "PlanDetails";
    } else if (title === "Health Questionnaire Details") {
      label = "QuestionnaireDetails";
    }

    return (
      <ConsentLayout
        accDetails={accDetails}
        data={data.detail}
        title={title}
        label={label}
        consentData={
          label !== "QuestionnaireDetails"
            ? addConsentInfo && Object.entries(addConsentInfo[label])
            : addConsentInfo && addConsentInfo[label]
        }
      />
    );
  };
  const clickHandler = () => {};
  const toggleAccordion = (id) => {
    setOpenAccordion(openAccordion === id ? null : id);
  };

  const downloadHandler = () => {};
  const downloadPdf = (e, item) => {
    let fileName;
    if (item.docCategoryCd === "PAF") {
      fileName = accDetails?.policyDocuments["PDF_TAG_NAME"];
    } 
    // else if (item.docCategoryCd === "COVID") {
    //   fileName =
    //     "COVID_TAG_NAME_2" in accDetails?.policyDocuments
    //       ? accDetails?.policyDocuments["COVID_TAG_NAME_2"]
    //       : accDetails?.policyDocuments["NEW_COVID_TAG_NAME_2"];
     else if (item.docCategoryCd === "COVID" && item.partyType === "insurer") {
        fileName = accDetails?.policyDocuments["NEW_COVID_TAG_NAME_2"]
     }
     else if (item.docCategoryCd === "COVID" && item.partyType !== "insurer") {
       fileName = accDetails?.policyDocuments["COVID_TAG_NAME_1"]
     }
     else if (item.docCategoryCd === "RBI") {
      fileName =
        accDetails?.policyDocuments["REVISED_BI_DOC"] !== " "
          ? accDetails?.policyDocuments["REVISED_BI_DOC"]
          : false;
    }
    else if (item.docCategoryCd === "CIS") {
      fileName = accDetails?.policyDocuments["REVISED_CIS_TAG_NAME"];
    } 

    if (fileName) {
      dispatch(downloadAction(proposalNo, fileName));
    } else {
      toaster("error", "File not exist");
    }
  };
  const uploadData = (e, item) => {
    // setShowConsentUpload(true)
    setShowUploadModal(true);
    showSetLabel(item.docCategoryCd)

    setDocument(item.docCategoryCd);
    // setShowUploadModal(true);
  };
  const acceptHandler = (action) => {
    let consentAction = action === "rejected" ? "REJECTED" : "ACCEPTED";
    // setShowConsent(true);
    setShowConsentUpload(true);
    setshowConsentAction(consentAction);
  };
  const consentUploadHandler = () => {
    setShowUploadModal(true);
  };
  const closeConsentUploadHandler = () => {
    setShowConsentUpload(false);
  };
  const consentHandler = () => {
    const payload = {
      policyNumber: proposalNo,
      proposalNumber: accDetails?.proposalBasicDetails?.proposalNumber,
      requestType: "SAVE",

      dataChangeConsent: {
        consentAction: showConsentAction,
        consentType: "DATA_CHANGE",
        consentBy: "AGENT",
      },
    };

    dispatch(
      saveDetailAction(payload, (res) => {
        setShowConsent(false);
        setshowUpload(true);
        setShowConsentUpload(false);
      })
    );
    // setShowUploadModal(true)
  };

  const rejectChangeHandler = (value) => {
    setRejectMsg(value);
  };
  const closeConsentHandler = () => {
    setShowConsent(!showConsent);
  };
  const consentAcc =
    accDetails?.proposalBasicDetails?.policyFor === "OTHER"
      ? consentForChangeData
      : consentForChangeData.filter((item) => {
          return item.title !== "Proposer Details";
        });

  const uploadDocHandler = (fileValue) => {
    let data = dataChangeConsentdoc?.filter(
      (item) => item.docCategoryCd === document
    );

    let file = fileValue;
    let fileSize = fileValue.size;
    let formData = new FormData();
    formData.append("file", file);
    let headerData;
    if (fileSize <= 10485760) {
      headerData = {
        documentCd: data[0]?.docCategoryCd,
        docCategoryCd: data[0]?.docCategoryCd,
        docCategoryTypeCd: data[0].docCategoryTypeCd,
        documentType: data[0]?.documentType,
        partyType: (data[0]?.partyType).toUpperCase(),
        id: 1,
        documentSide: "FRONT_SIDE",
        policyNo:policyNo ,
        documentNumber: 1,
        proposalNo: proposalNo,
        uwId: accDetails?.additionalInfoDocs?.uwId,

      };
      dispatch(
        uploadAction(headerData, formData, (res) => {
          if (res.status === "ACCEPTED") {
            setShowUploadModal(false);
            setShowConsentUpload(false);
            setUploadTrue(true);
             setshowGreenTick(true);
            dispatch(customerPortalStatus(proposalNo, (res) => {}));
          }
        })
      );
    }
  };
  const imageStyle = {
    marginRight: "8px",
    width: "20px",
    height: "20px",
  };
  const greenImageStyle = {
    marginRight: "8px",
    width: "20px",
    height: "16px",
  };
  return (
    <>
      <ul className="addNonMedAcc">
        {consentAcc.map((item) => {
          return (
            <li className="addNonMedAccList" key={item.id}>
              <div
                className="acc-non-block"
                onClick={() => toggleAccordion(item.id)}
              >
                <div>{item.title}</div>
                <div className="acc-active-icon ">
                  <img
                    className={openAccordion === item.id ? "upArrow" : ""}
                    src={dowImgArw}
                    alt="icon"
                  />
                </div>
              </div>
              {openAccordion === item.id ? (
                <div className="show consent-block">
                  {renderElement(item, item.title)}
                </div>
              ) : (
                ""
              )}
            </li>
          );
        })}
      </ul>
      <div className="consent-blk consent-tag-blk">
        {dataChangeConsentdoc?.length !== 0 ? (
          <>
            <div className="consent-tag">
              Please check the below documents for your reference
            </div>
            {/* <div className="consent-doc">
              {dataChangeConsentdoc &&
                dataChangeConsentdoc?.map((item, idx) => {
                  return (
                    <div className="progressWrapper" key={idx}>
                      <span>{item.documentCd}</span>
                  
                <div
                  className="download_icon"
                  onClick={(e) => downloadPdf(e, item)}
                ></div>
                <div
                  className="upload_icon"
                  onClick={(e) => uploadData(e, item)}
                ></div>
                    </div>
                  );
                })}
            </div> */}
            <ul className="conset-doc">
              {dataChangeConsentdoc?.map((item, idx) => {
                let isCovid = item.documentCd === "COVID"
                let isCovidOwner = isCovid && item.partyType === 'owner'
                let isCovidInsurer = isCovid && item.partyType === 'insurer'
                let showText = isCovidInsurer ? '(Insured)' : isCovidOwner ? '(Proposer)' : ''
                 return (
                  <li className="progressWrapper">
                      <span>{isCovid ? item.documentCd + " " + 'Questionnaire' +  " " + showText : item.documentCd}</span>
                      <div className="action-blk">
                      {/* <div
                        className="download_icon"
                        onClick={(e) => downloadPdf(e, item)}
                      ></div> */}

                      {dataChangeConsentAction !== null && !item.url && (
                        // <div
                        //   className="upload_icon"
                        //   onClick={(e) => uploadData(e, item)}
                        // ></div>
                        <a
                          className="upl-img-link"
                          onClick={(e) => uploadData(e, item)}
                        >
                          <img src={uplImg} alt="uplImg" style={imageStyle} />
                        </a>
                      )}
                      {item.url  && 
                <img src={greentick} alt="greenTick" style={greenImageStyle} />
                      }
                      <a
                        className="upl-img-link"
                        onClick={(e) => downloadPdf(e, item)}
                      >
                        <img src={dowImg} alt="dowImg" style={imageStyle} />
                      </a>
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <div className="consent-tag">
            <p>Documents are not available.</p>
          </div>
        )}
        <div className="consent-status">
          {(dataChangeConsentAction === "ACCEPTED" &&
            "Consent: ACCEPTED has been given for Data Change") ||
            (dataChangeConsentAction === "REJECTED" &&
              "Consent: REJECTED has been given for Data Change")}
        </div>
        {
          //  consentDetail[0]?.actual_status !== 'COMPLETED' ||
          accDetails?.proposalBasicDetails?.proposalFinalSubmitted ||
            ((dataChangeConsentAction === "" ||
              dataChangeConsentAction === null) && (
              <>
                <label>
                  <input
                    type="radio"
                    id="agree"
                    name="radio"
                    onChange={(e) => {
                      e.target.checked ? setDisabled(false) : setDisabled(true);
                    }}
                  />
                  <span>I agree to the above changes</span>
                </label>
                <div className="consent-btn">
                  <button
                    className={"activeBtn"}
                    onClick={() => acceptHandler("accepted")}
                    type="button"
                    buttonText={"Accept"}
                    disabled={disabled}
                  >
                    Accept
                  </button>
                  <button
                    className={"activeBtn"}
                    // clickHandler={() => { setshowRejectPopUp(true), setShowOtp(false) }}
                    onClick={() => acceptHandler("rejected")}
                    type="button"
                    buttonText={"Reject"}
                    disabled={disabled}
                  >
                    Reject
                  </button>
                </div>
              </>
            ))
        }
      </div>

      {/* {showOtp && (
        <div className={`header-otp-popup popupcmn ${showOtp && "active"}`}>
          <div className="header-otp-popup-head">
            <div className="header-otp-popup-left">
              <h2> OTP </h2>
            </div>
            <div className="header-reject-popup-right">
              <Image
                onClick={() => {
                  setShowOtp(false);
                  setOverlay(false);
                }}
                // src={otpCross}
                alt="otp-cross-icon"
              />{" "}
            </div>
          </div>

          <OTPInput
            otp={otp}
            resendFunc={() => {
              setOtp("");
              // sendOtp();
              //    this.showOtpHandler();
            }}
            //  triggerTimmer={this.state.showOTP}
            getOTP={(otp) => setOtp(otp)}
            otpKeyDownFunc={() => {}}
          />
          <div className="header-reject-popup-content-fot">
            <button 
            // onClick={submitHandler} 
            className="sbmt_btn">
              Submit
            </button>
          </div>
        </div>
      )}

      {showThankyou && (
        <div
          className={`popupcmn_otpthnks popupcmn ${showThankyou && "active"}`}
        >
          <div className="header-otp-popup-head-thank-you">
            <div className="header-reject-popup-right">
              <Image
                onClick={() => {
                  setShowThankyou(false);
                  setOverlay(false);
                }}
                // src={otpCross}
                alt="otp-cross-icon"
              />{" "}
            </div>
          </div>
          <div className="header-otp-popup-content pt-4 pl-5 pr-5 pb-3">
            <div className="row">
              <div className="col-md-12 pt-0 pb-4">
                <div>
                  {" "}
                  <Image
                    className="img"
                    style={{ width: "100%", height: "12rem" }}
                    // src={thankYou}
                    alt="thank-you-bg"
                  />{" "}
                </div>
                <div className="popup-thank-you-content-bot text-center">
                  <h2> Thank you </h2>
                  <h3>
                    {" "}
                    for successfully completing the application review and
                    providing consent.{" "}
                  </h3>
                  <p className="pt-3">
                    {" "}
                    The proposal will be processed further.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {/* {showRejectPopUp && (
        <ConsentReject
          text={"Please reconfirm if you want to reject the application"}
          leftHandler={() => setShowRejectMsgPopUp(true)}
          leftSideBtnText={"Yes"}
          rightHandler={""}
          rightSideBtnText={"No"}
          onClose={() => setshowRejectPopUp(false)}
        />
      )}
      {showRejectMsgPopUp && (
        <ConsentRejectMsg
          text={"Please let us know what went wrong"}
          message={rejectMsg}
          changeHandler={rejectChangeHandler}
          btnHandler={() => acceptHandler("rejected")}
          btnText={"Submit"}
          onClose={() => setShowRejectMsgPopUp(false)}
        />
      )} */}
      {/* {showConsent && ( 
        <div className="card-body">
          <PopUp onClose={closeConsentHandler} addCss={"counter-consent"}>
            <div className="counter-consent">
              <div className="counter-text">
                By clicking 'Accept' or 'Reject,' your consent will be saved.
                Please ensure you are making the right choice.
              </div>
              <div className="d-flex counter-btns">
                <div className="btn" onClick={closeConsentHandler}>
                  CANCEL
                </div>
                <div className="btn" onClick={consentHandler}>
                  OK
                </div>
              </div>
            </div>
          </PopUp>
        </div>
      )} */}

      {showConsentUpload && (
        <div className="card-body">
          <PopUp onClose={closeConsentUploadHandler} addCss={"counter-consent"}>
            <div className="counter-consent">
              <div className="counter-text">
                Once you select an option, it cannot be changed. Please ensure
                your choice is final before proceeding.
                <br />
                <br />
                You are required to upload signed documents (whichever is/are
                applicable) for your choice:
                <div className="ul-container">
                  <ul className="ul-item">
                    <li>Revised Benefit Illustration</li>
                    <li>Revised Proposal Form</li>
                    <li>Revised Covid Questionnaire</li>
                    <li>Revised Customer Information Sheet</li>
                  </ul>
                </div>
                <br />
                Please make sure to review your documents carefully before
                uploading. Once uploaded, documents cannot be previewed or
                deleted.
              </div>
              <div className="d-flex counter-btns">
                <div className="btn" onClick={closeConsentUploadHandler}>
                  CANCEL
                </div>
                <div className="btn" onClick={consentHandler}>
                  OK
                </div>
              </div>
            </div>
          </PopUp>
        </div>
      )}

      {showUploadModal && (
        <UploadDocModal
          heading={`Upload`}
          // subheading={'uploadModalHeading'}
          onClose={() => {
            setShowUploadModal(false);
          }}
          label={showLabel}
          uploadDocHandler={uploadDocHandler}
          proposalNo={accDetails?.proposalBasicDetails?.proposalNumber}
        />
      )}
    </>
  );
};

export default Consent;
