import Axios from "axios";
import apiConstants from "../../constants/apiConstants";
import actionTypes from "../../constants/actionTypes";
import { toaster } from "../../utils/utils";

export const downloadAction = (proposalNo, file, cb) => (dispatch) => {
    Axios({
        method: "get",
        mode: "no-cors",
        url: `${apiConstants.PROPOSEL_API_URL}/api/v1/proposal/document/download?proposalNo=${proposalNo}&file=${file}`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + localStorage.getItem("accessToken")
        },
        responseType: "blob",
    }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', file); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    })
        .catch((error) => {
            toaster('error', error.message);
        });
};

export const videoPIVCAction = (data, cb) => (dispatch) => {
    dispatch({
        type: "LOADER_ON",
    });
    Axios({
        method: "post",
        mode: "no-cors",
        url: `${apiConstants.PROPOSEL_API_URL}/api/v1/proposal/insta2-notification/${data.proposalNumber}`,
        headers: {
            "Content-Type": "application/json",
            "agentCode": localStorage.getItem('agentCode'),
            "Authorization": 'Bearer' + " " + localStorage.getItem('accessToken')
        },
        data
    })
        .then((res) => {
            dispatch({
                type: "LOADER_OFF",
            });
            if (res.data.status !== "FORBIDDEN") {
                toaster("success", res.data.message);
            }
            else {
                toaster("error", res.data.message);
            }
        })
        .catch((error) => {
            toaster("error", error.message);
            dispatch({
                type: "LOADER_OFF",
            });
        });
};

export const consentHandlerAction = (data, cb) => (dispatch) => {
    dispatch({
        type: "LOADER_ON",
    });
    Axios({
        method: "post",
        mode: "no-cors",
        url: `${apiConstants.PROPOSEL_API_URL}/api/v1/proposal/send/customerConsentLink`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer' + " " + localStorage.getItem('accessToken')
        },
        data
    })
        .then((res) => {
            dispatch({
                type: "LOADER_OFF",
            });
            if (res.data.status === "OK") {
                toaster("success", res.data.message);
            }
            else {
                toaster("error", res.data.message);
            }
        })
        .catch((error) => {
            toaster("error", error.message);
            dispatch({
                type: "LOADER_OFF",
            });
        });
};

export const enachInitiated = (payload, cb) => {
    Axios.post(`https://dev-api-proposal.bhartiaxa.com/public/api/v1/eNACH/paymentBegin`, payload, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer' + ' ' + localStorage.getItem("accessToken")
            // "Authorization" :"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMjkyMTIiLCJhdXRob3JpdGllcyI6W3siYXV0aG9yaXR5IjoiQUdFTlQifV0sImlhdCI6MTY5NDc1NDgxMCwiZXhwIjoxNjk0ODQxMjEwfQ.u6VwxAj4GZuIIuSldDPXjTtE3NigvLZqlVQt8MDJokWTZaN4UjIjPC8A0PGeWiYvxfz7SXE2PMwET6-iJcTKTQ"

        },
    })
        .then((resp) => {
            toaster("success", "E-nach initiated successfully");
            if (cb) {
                cb(resp.data.body)
            }
        })
        .catch((err) => {

            toaster("error", err.response?.data?.errors);
            if (err.status == '401') {
                window.location.href = apiConstants.TRACKER_URL
            }
            if (cb) {
                // cb(false)
            }

        });
}

const blobToFile = (blob, name) => {
    let x = new File([blob], name, { type: "image/jpg" });
    return x;
}
export const upLoadData = (proposalNo, file, cb) => (dispatch) => {
    const data = { ...this.state.selectedValue }
    const docNumber = this.state.proofNumber ? this.state.proofType === "Aadhaar Card" ? parseInt(this.state.proofNumber.slice(8)) : this.state.proofNumber : '';
    const image = blobToFile(data.url, data.url.name);
    let uploadData = new FormData();
    uploadData.append('file', image)

    Axios.post(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/document/addInfo/uploadFile?documentCd=${data.documentCd}&docCategoryCd=${data.docCategoryCd}&documentSide=FRONT_SIDE&docCategoryTypeCd=${data.docCategoryTypeCd}&documentNumber=${docNumber}&partyType=${this.state.docType}&proposalNo=${this.props.data && this.props.data.proposalNumber}&policyNo=${localStorage.getItem('policyNumberTpa')}&serviceDocListId=${data.id}&uwId=${this.props.data.id}
    `, uploadData, {
        headers: {
            accept: "*/*",
            "Authorization": localStorage.getItem('agentAuth'),
        },
    }).then((res) => {
        if (res.data && res.data.body) {
            toaster("success", res.data.message)
            this.setState({
                showPopup: false,
                attachFile: false,
                proofNumber: '',
                proofType: '',
                enableSave: false
            });
            this.props.completeFormCheck(true);
        } else if (res.data && res.data.message) {
            toaster("error", res.data.message)
        }
    }).catch((err) => {
        toaster('error', err?.response?.data?.errors[0]);
    })
}
//save and submit api action
export const saveDetailAction = (data, cb) => (dispatch) => {
    dispatch({
        type: "LOADER_ON",
    });
    Axios({
        method: "post",
        mode: "no-cors",
        url: `${apiConstants.PROPOSEL_API_URL}api/v1/customer-portal/saveOrSubmit`,        
        headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('agentAuth'),
        },
        data: data
    })
        .then((res) => {
            dispatch({
                type: actionTypes.customerInfoSuccess,
                info: res?.data,
            });
            dispatch({
                type: "LOADER_OFF",
            });
            if (res) {
                cb(res?.data?.body, res)
            }
            if(res?.data?.status === 'OK'){
                setTimeout(
                   ()=>{
                    toaster('success', res?.data?.message);
                   },
                    1000
                );
            }
            else{
                toaster('error', res?.data?.message);
            }
        })
        .catch((error) => {
            dispatch({
                type: "LOADER_OFF",
            });
            toaster('error', error?.response?.data?.errors[0]);
        });
};

//upload doc action
export const uploadAction = (headerData, fileData, cb) => (dispatch) => {
    dispatch({
      type: "LOADER_ON",
    });
    Axios
      .post(
        `${apiConstants.PROPOSEL_API_URL}/api/v1/proposal/document/addInfo/uploadFile?documentCd=${headerData.documentCd}&docCategoryCd=${headerData.docCategoryCd}&docCategoryTypeCd=${headerData.docCategoryTypeCd}&documentSide=${headerData.documentSide}&documentNumber=${headerData.documentNumber}&partyType=${headerData.partyType}&proposalNo=${headerData.proposalNo}&policyNo=${headerData.policyNo}&serviceDocListId=${headerData.id}&uwId=${headerData.uwId}`,
        fileData,
        {
          headers: {
            accept: "*/*",
            Authorization: localStorage.getItem('agentAuth'),
          },
        }
      )
  
      .then((res) => {
        dispatch({
          type: "LOADER_OFF",
        });
        if (res.data.status === 'OK' || res.data.status === 'ACCEPTED') {
          cb(res.data);
          toaster("success", res.data.message);
        }
        else {
          toaster("error", res.data.message);
        }
      })
      .catch((error) => {
        dispatch({
          type: "LOADER_OFF",
        });
        toaster('error', error?.response?.data?.errors[0]);
    });
  };

  export const deleteDocAddInfo = (payload, cb) => (dispatch) => {
    dispatch({
      type: "LOADER_ON",
    });
    Axios
      .delete(`${apiConstants.PROPOSEL_API_URL}/api/v1/proposal/document/addInfo/delete?file=${payload.url}&proposalNo=${payload.proposalNo}&uwId=${payload.uwId}`, {
      headers: {
        "Authorization": localStorage.getItem('agentAuth'),
      },
    })
      .then((res) => { 
        dispatch({
          type: "LOADER_OFF",
        });
        if (res.data && res.data.message.indexOf('removing request submitted successfully') > -1) {
          toaster("success", res.data.message)
          if (cb) {
            cb();
          }
        }
        
      })
      .catch((error) => {
        dispatch({
          type: "LOADER_OFF",
        });
        toaster('error', error?.response?.data?.errors[0]);
    });
  };

  export const tpaRetrigger = (data, cb) => (dispatch) => {
    dispatch({
        type: "LOADER_ON",
    });
    Axios({
        method: "post",
        mode: "no-cors",
        url: `${apiConstants.PROPOSEL_API_URL}api/v1/customer-portal/triggerTpa?id=${data.id}&proposalNumber=${data.proposalNumber}`,
        headers: {
            "Content-Type": "application/json",
            // "agentCode": localStorage.getItem('agentCode'),
            "Authorization": localStorage.getItem('agentAuth')
        },
        data
    })
        .then((res) => {
            dispatch({
                type: "LOADER_OFF",
            });
            if(res.data.status='OK'){
                toaster('success', res?.data?.message)
                    cb(res.data.body)
                }
                else{
                    toaster('error', res?.data?.message)
                }
        })
        .catch((error) => {
            toaster("error", error.message);
            dispatch({
                type: "LOADER_OFF",
            });
        });
};
