import React, { useState } from "react";
import DropDown from "../components/DropDown/DropDown";
import userManualPdf from "../Assets/PDFs/UserManual.pdf";
import logout from '../Assets/images/logout.svg'

const UserProfileWeb = ({ DefaultUserIcon, HierarchyIcon, UserManualIcon,toggleHierarchy, logoutUser }) => {
  const [showProfileDd, setShowProfileDd] = useState(false);
  return (
    <div role="userProfileWeb_role" data-testid="userProfileWeb_testID">
      <DropDown
        isIcon={true}
        icon={DefaultUserIcon}
        cssClass="user-dd"
        iconCss="user-icon"
        dropDownHeader={localStorage.getItem('agentName')}
        toggleValue={showProfileDd}
        isHeaderBold={true}
        onToggle={() => setShowProfileDd(!showProfileDd)}
      >
        <p
          className="user-dd-p"
          onClick={() => window.open(userManualPdf, "_blank")}
        >
          <img src={UserManualIcon} alt="manual" className="user-dd-icons" />
          <span>USER MANUAL</span>
        </p>
        <p className="user-dd-p" onClick={() => {
          setShowProfileDd(false)
          toggleHierarchy(true)}
          } >
          <img src={HierarchyIcon} alt="hierarchy" className="user-dd-icons" />
          <span>HIERARCHY </span>
        </p>
        <p className="user-dd-p" 
        onClick={() => logoutUser()} >
          <img src={logout} alt="log out" className="user-dd-icons" />
          <span>Logout </span>
        </p>
      </DropDown>
    </div>
  );
};

export default UserProfileWeb;
