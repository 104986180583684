import actionTypes from "../constants/actionTypes";
const initialState = {
  agentDetails: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.fetchAgentDetails:
      return {
        ...state,
        agentDetails: action.payload
      };
    case actionTypes.clearAgentDetails:
      return {
        ...state,
        agentDetails: {}
      };
    default:
      return state;
  }
}
