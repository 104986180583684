import React, { Component } from "react";
import OtpInput from "react-otp-input";

export class OTPInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      isError: false,
      minutes: 1,
      seconds: 0,
    };
  }
  handleChange = (otp) => {
    this.state.isError && this.setState({ isError: false });
    this.setState({ otp });
    this.props.getOTP(otp);
  };
  componentDidMount() {
    if (this.props.triggerTimer || true) {
      this.timmer();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.triggerTimer !== nextProps.triggerTimer &&
      nextProps.triggerTimer
    ) {
      this.timmer();
    }
  }
  9;

  onResend = () => {
    this.setState(
      {
        minutes: 1,
        seconds: 0,
      },
      () => {
        this.timmer();
        if (this.props.resendFunc) {
          this.setState({
            otp: "",
          });
          this.props.resendFunc();
        }
      }
    );
  };

  timmer = () => {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  };

  componentDidUpdate() {
    if (this.props.otp !== this.state.otp) {
      this.setState({
        otp: this.props.otp,
      });
    }
  }
  keyDown = (e) => {
    if (e.key === "Enter" && this.state.otp.length === 6) {
      this.props.otpKeyDownFunc();
    }
  };

  render() {
    const { isError } = this.state;
    return (
      <div className="otp-input-fields">
        <div className="label-wrapper">
          <label className="otp-input-label">Enter OTP</label>
          {this.state.minutes === 0 && this.state.seconds === 0 ? (
            <div className="resend-wrapper" onClick={() => this.onResend()}>
              {/* <img src={resend} alt="resend" className="resend-img" />{" "} */}
              <span>Resend OTP</span>
            </div>
          ) : (
            <div className="resend-time-wrapper">
              {/* <img src={clock} alt="clock" className="resend-img" />{" "} */}
              <span> You can resend OTP in
                {" " + this.state.minutes}:
                {this.state.seconds < 10
                  ? `0${this.state.seconds}`
                  : this.state.seconds}
              </span>
            </div>
          )}
        </div>
        <div className="otp-input-wrapper" onKeyDown={(e) => this.keyDown(e)}>
          <OtpInput
            value={this.state.otp}
            onChange={this.handleChange}
            numInputs={6}
            inputStyle="otp-field"
            hasErrored={isError}
            errorStyle={isError && "error"}
            isInputNum={true}
            shouldAutoFocus={true}
          />
        </div>
      </div>
    );
  }
}

export default OTPInput;
