import actionTypes from "../constants/actionTypes";
const urlParams = new URLSearchParams(window.location.search);
const hasssoid = urlParams.has("ssoid");
const ssoid = urlParams.get("ssoid");  
 if(hasssoid && ssoid) {
  localStorage.setItem('agentAuth','');
  localStorage.setItem('agentCode','');
 }  
const initialState = {
  customerList: [],
  tabList: [],
  filterOptionsList: {},
  totalItems: 0,
  totalPages: 0,
  agentCode:'',
  agentLoggedIn:hasssoid && ssoid?"":localStorage.getItem('agentAuth'),
  exceptionList: [],
  exceptionListLoader: false,
  fetchCustomerListLoading: false,
  isListEmpty: false
};
const updateItem = (data, state) => {
  state.customerList.map(item => {
    if (item.proposalId === data.proposalId) {
      item.proposalBookmarked = data.bookmarked
    }
    return item
  })
  return state
}
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.fetchCustomerList:
      return {
        ...state,
        customerList: action.isNewPage ? action.payload : state.customerList.concat(action.payload),
        totalItems: action.totalItems,
        totalPages: action.totalPages,
        isListEmpty: !action.totalItems && !action.totalPages && !action.payload.length
      };
    case actionTypes.fetchTabList:
      return {
        ...state,
        tabList: action.payload,
      };
    case actionTypes.bookmarkedApplication:
      const tempState1 = updateItem(action.payload, state)
      return {
        ...tempState1
      };
    case actionTypes.unBookmarkedApplication:
      const tempState2 = updateItem(action.payload, state)
      return {
        ...tempState2
      };
    case actionTypes.fetchFilterOptionsType:
      return {
        ...state,
        filterOptionsList: action.payload,
      };
    case actionTypes.agentLogin:
      return {
        ...state,
        agentLoggedIn: action.payload,
      };
    case actionTypes.exceptionList:
      return {
        ...state,
        exceptionList: action.payload
      }
    case actionTypes.exceptionListLoader: 
      return {
        ...state,
        exceptionListLoader: action.payload,
      }
      case actionTypes.fetchCutomerListLoader:
        return {
          ...state,
          fetchCustomerListLoading: action.payload,
        }
    default:
      return state;
  }
}
