import React from "react";
import { isMobile } from "../utils/utils";
import PropTypes from "prop-types";

const Menu = ({ tabList, selectedTab, changeTab }) => {
  const getTabWidth =()=>{
        return window.innerWidth/tabList.length;
  }
    return(
      <ul
      data-testid="menu_component"
      className="menu-container">
      {tabList.map((item, index) => {
        const ref = React.createRef();
        const handleClick = () =>
          ref.current.scrollIntoView({
            behavior: "smooth",
            inline: "nearest",
          });
        
        return (
          <li
            data-testid="list-item"
            key={item.reqKey}
            ref={ref}
            style={{width: isMobile() ? getTabWidth() : "100%"}}
            className={`menu-item ${
              item.reqKey === selectedTab.reqKey && "active"
              }`}
            onClick={() => {
              changeTab(item);
              // handleClick();
            }}
          >{item.name}<span>({item.count})</span>
          </li>
        );
      })}
      <div className="active-div"></div>
    </ul>
  );
}
Menu.propTypes={
  tabList: PropTypes.array, 
  selectedTab: PropTypes.object,
  changeTab: PropTypes.func
}
  


export default Menu;
