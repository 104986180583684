let apiConstants = {};
if(process.env.REACT_APP_API_ENV === 'AWS'){
  apiConstants = {
    DASHBOARD_API_URL: "https://uat-api-tracker.bhartiaxa.com/public/",
    PROPOSEL_API_URL: "https://uat-api-proposal.bhartiaxa.com/public/",
    BAXA_API_URL: "https://uat-api-integeration.bhartiaxa.com/public/",
    M_CUSTOMER_URL:'https://uat-onboarding.bhartiaxa.com/mcustomer',
    AUTH_API_URL: 'https://uat-api-auth.bhartiaxa.com/public/',

  }
}
else if(process.env.REACT_APP_API_ENV === 'UAT'){
  apiConstants = {
    DASHBOARD_API_URL: "https://dev-api-tracker.bhartiaxa.com/public/",
    PROPOSEL_API_URL: "https://dev-api-proposal.bhartiaxa.com/public/",
    BAXA_API_URL: "https://dev-api-integeration.bhartiaxa.com/public/",
    M_CUSTOMER_URL:'https://dev-onboarding.bhartiaxa.com/mcustomer',
    AUTH_API_URL: 'https://dev-api-auth.bhartiaxa.com/public/',
    
  }
}
else if(process.env.REACT_APP_API_ENV === 'PROD'){
  // apiConstants = {
  //   DASHBOARD_API_URL: "https://zgd8doeosk.execute-api.ap-south-1.amazonaws.com/public/",
  //   PROPOSEL_API_URL: "https://b0pyrqwsui.execute-api.ap-south-1.amazonaws.com/public/",
  //   BAXA_API_URL: "https://f55bm57ole.execute-api.ap-south-1.amazonaws.com/public/",
  //   M_CUSTOMER_URL:'https://onboarding.bhartiaxa.com/mcustomer',
  //   AUTH_API_URL: 'https://sidlce25m2.execute-api.ap-south-1.amazonaws.com/public/'
  // }
  apiConstants = {
    DASHBOARD_API_URL: "https://api-tracker.bhartiaxa.com/public/",
    PROPOSEL_API_URL: "https://api-proposal.bhartiaxa.com/public/",
    BAXA_API_URL: "https://api-integration.bhartiaxa.com/public/",
    M_CUSTOMER_URL:'https://onboarding.bhartiaxa.com/mcustomer',
    AUTH_API_URL: 'https://api-auth.bhartiaxa.com/public/',
  }
}
else {
  apiConstants={
   DASHBOARD_API_URL: "https://dev-api-tracker.bhartiaxa.com/public/",
    PROPOSEL_API_URL: "https://dev-api-proposal.bhartiaxa.com/public/",
    BAXA_API_URL: "https://dev-api-integeration.bhartiaxa.com/public/",
    M_CUSTOMER_URL:'https://dev-onboarding.bhartiaxa.com/mcustomer',
    AUTH_API_URL: 'https://dev-api-auth.bhartiaxa.com/public/',
  }
}
export default apiConstants;

