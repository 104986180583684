import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import TpaContainer from './containers/TpaContainer';
import { Provider } from "react-redux";
import store from "./Appstore/store";
import { ToastContainer } from 'react-toastify';
const urlParams = new URLSearchParams(window.location.search);
const uwId = urlParams.get("uwId");
const et = urlParams.get("et");

console.log('urlParams.get("proposalNumber")', uwId);
if (window.self === window.top) {
  if (uwId) {
    ReactDOM.render(
      <Provider store={store}>
        <ToastContainer
          position="bottom-right"
          className="toastContainer"
          autoClose={3000}
          hideProgressBar={true}
        />
        <TpaContainer proposalNo_consent={uwId}
          et={et} />
      </Provider>,
      document.getElementById("tpa-container")
    );
  } else {
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      document.getElementById('root')
    );
  }
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
