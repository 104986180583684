import React from "react";
import logo from "../Assets/logo/logo_2x.svg";
import searchImg from "../Assets/images/search.svg";
import { isMobile } from "../utils/utils";
import UserProfileWeb from "./UserProfileWeb";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchBar: false,
    };
  }
  toggleSearch = () => {
    this.setState({
      showSearchBar: !this.state.showSearchBar,
    });
  };
  render() {
    const {
      DefaultUserIcon,
      HierarchyIcon,
      UserManualIcon,
      handleUserProfileDisplay,
    } = this.props;
    return (
      <div data-testid="header_component" className="row header-row">
        <div className=" row container">
          <div className="col-xs-3 col-3 col-sm-3 col-md-3 col-lg-3 logo-img-wrapper">
            <img data-testid="image" className="logo-image" src={logo} alt="logo"></img>
          </div>
          <div className="col-xs-7 col-7 col-sm-7 col-md-7 col-lg-7 text-center title"><h3 className="my-0">Proposal Tracker</h3></div>
          <div className="col-xs-2 col-2 col-sm-2 col-lg-2">
            <div className="user-menu-dropDown">
              {isMobile() ? (
                <img
                  src={DefaultUserIcon}
                  alt="user-icon"
                  onClick={() => handleUserProfileDisplay()}
                  className="header-user-img"
                />
              ) : (
                <UserProfileWeb
                  DefaultUserIcon={DefaultUserIcon}
                  HierarchyIcon={HierarchyIcon}
                  UserManualIcon={UserManualIcon}
                  toggleHierarchy={this.props.toggleHierarchy}
                  logoutUser = {this.props.logoutUser}
                />
              )}
            </div>
            {!this.state.showSearchBar && (
              <img
                className="searchIcon"
                alt="Search"
                src={searchImg}
                onClick={this.toggleSearch}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
