import React from "react";
import closeImg from "../../Assets/images/close.svg";
import { isMobile } from "../../utils/utils.js";
import {showCalendarClass} from "../../actions/dashBoardActions";
import {connect} from 'react-redux';

class MobilePopUp extends React.Component {
  closeModal = () => {
    this.props.onClose && this.props.onClose();
  };
  componentDidMount = () => {
    this.props.showCalendarClass(true);
  }
  componentWillUnmount = () => {
    this.props.showCalendarClass(false);
  }
  render() {
    const {
      cssClass,
      children,
      heading,
      showStatus,
      showOnDesktop,
      details,
      addAutoUnderWritingCss,
      showExceptionStatus,
      popUpBottom,
      removeHeader,
      popupHead
    } = this.props;
    const status =
      details && details.journeyStatus && details.journeyStatus.split("_");
    console.log("details", details);
    return (
      <div role="mobilePopup_testRole" data-testid="mobilePopup_testId"
        className={`mainPopUpWrap ${popUpBottom ? "popUpBottomCss" : ""} ${cssClass} ${
          !isMobile() && showOnDesktop ? "showOnDesktop" : ""
        }`}
      >
        <span className={`backgroundOverlay`} onClick={this.closeModal} />
        <div className="mobilePopUpWrap ">
          {removeHeader ? null : <div className="pop-top-sec">
            {this.props.popupHead ? <div  data-testid="mobilePopup_cancelBtn" className="popup-header">{popupHead}</div>: ''}
            <span className="close-btn" onClick={this.closeModal}>
              <img alt="Close" src={closeImg} />
            </span>
            {
              heading && 
              <div className="heading">{heading}</div>
            }
            {showExceptionStatus && (
              <div className="showstatusWrap">
                <div className="left-col">
                  <p>
                    <span>Plan:</span> {details && details.plan}
                  </p>
                  <p className="exc-pn">
                    <span>Proposal Number:</span>{" "}
                    {details && details.proposalNumber}
                  </p>
                </div>
              </div>
            )}
            {showStatus && (
              <div className="showstatusWrap">
                <ul>
                  <li>
                    <p>Sum Assured</p>
                    <span>{details.sumAssured ? details.sumAssured : "-"}</span>
                  </li>  
                  <li>
                    <p>Premium</p>
                    <span>{details.premium ? details.premium : "-"}</span>
                  </li>  
                  <li>
                    <p>Customer ID</p>
                    <span>{details.customerId ? details.customerId : "-"}</span>
                  </li>  
                  <li>
                    <p>Lead ID</p>
                    <span>{details.leadId ? details.leadId : "-"}</span>
                  </li>                  
                </ul>
                {/* <div className="left-col">
                  <p>
                    <span>Plan:</span> {details && details.planName}
                  </p>
                  <p>
                    <span>Proposal Number:</span>{" "}
                    {details && details.proposalNo}
                  </p>
                  <p>
                    <span>Policy Number:</span>{" "}
                    {details && details.policyNumber}
                  </p>
                </div>
                <div className="right-col">
                  <button
                    className="btn payment-btn"
                    data-tooltip={status.join(" ").toLowerCase()}
                  >
                    <span className="indicText">Status: </span>
                    <span className="statusText">
                      {status.join(" ").toLowerCase()}
                    </span>
                  </button>
                </div> */}
              </div>
            )}
          </div>}
        {/* {
            (showExceptionStatus || showStatus) &&
                <p className={`${addAutoUnderWritingCss}`}>
                <span>Auto Underwriting Decision:</span>{" "}
                {details && details.overallUWDecisionCd}
              </p>
            } */}

          <div className="pop-btm-sec">{children}</div>
        </div>
      </div>
    );
  }
}

const actions = {
  showCalendarClass
}

export default connect(null, actions)(MobilePopUp);
