const actionTypes = {
  fetchCustomerList: "FETCH_CUSTOMER_LIST",
  fetchCutomerListLoader: "FETCH_CUSTOMER_LIST_LOADER",
  fetchTabList: "FETCH_TAB_LIST",
  fetchFilterOptionsType: "FETCH_FILTER_OPTIONS_TYPE",
  fetchAppStatus: "FETCH_APP_STATUS",
  clearAppStatus: "CLEAR_APP_STATUS",
  unBookmarkedApplication: "UNBOOKMARKED_APPLICATION",
  bookmarkedApplication: "BOOKMARKED_APPLICATION",
  fetchAgentDetails: "FETCH_AGENT_DETAILS",
  fetchPaymentDetails: "FETCH_PAYMENT_DETAILS",
  clearPaymentStatus: "CLEAR_PAYMENT_DETAILS",
  clearAgentDetails: "CLEAR_AGENT_DETAILS",
  agentLogin: "AGENT_LOGIN",
  exceptionList: "EXCEPTION_LIST",
  exceptionListLoader: "EXCEPTION_LIST_LOADER",
  copyInstaVerifyLink: "COPY_INSTA_VERIFY_LINK",
  copyEmandateLink: "COPY_EMANDATE_LINK",
  copyOnlinePaymentLink: "COPY_ONLINE_PAYMENT_LINK",
  agentLogout: "AGENT_LOGOUT",
  customerInfoSuccess: "CUSTOMER_INFO_SUCCESS",
  customerInfoFailed: "CUSTOMER__INFO_FAILED",
  saveInfoSuccess: "SAVE_INFO_SUCCESS",
  saveInfoFailed: "SAVE_INFO_FAILED",
};
export default actionTypes;
