import React from "react";
import { connect } from "react-redux";
import {
  fetchCustomerList,
  fetchTabList,
  fetchFilterOptions,
  createBookMark,
  fetchExceptionList,
  agentLogout,
} from "../actions/dashBoardActions";
import Header from "./Header";
import CardContainer from "./CardContainer";
import Menu from "./Menu";
import SortAndFilterMobile from "./SortAndFilter/MobileComponent/SortAndFilterMobile";
import { isMobile, agentCode, toaster } from "../utils/utils";
import { ToastContainer } from "react-toastify";
import UserProfileMobile from "./UserProfileMobile";
import Hierarchy from "./Hierarchy/Hierarchy.js";
import "react-toastify/dist/ReactToastify.css";
import DefaultUserIcon from "../Assets/images/more.png";
import UserManualIcon from "../Assets/images/instructions.svg";
import HierarchyIcon from "../Assets/images/order.svg";
import LoginComponent from './LoginComponent';
import TpaContainer from "./TpaContainer";
//temp fix applied for logout once security will be enabled, this code will be removed
const urlParams = new URLSearchParams(window.location.search);
const isLogout = urlParams.get("logout") ? urlParams.get("logout") : '';
if (isLogout) {
  localStorage.setItem('agentAuth', '');
  localStorage.setItem('agentCode', '');
  window.location.href = window.location.href.split('?')[0];
}
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserProfile: false,
      showHierarchy: false,
      selectedTab: {},
      bookmarkChanging: false,
      reqObject: {
        group: null,
        order: "DESC",
        page: 0,
        query: "",
        size: 8,
        sort: "LastUpdated",
        status: "",
        startTimestamp: null,
        endTimestamp: null,
      },
      loading: true,
      openTpa: false,
      tpaData: {}
    };
  }
  
  componentDidMount() {
    
    const urlParams = new URLSearchParams(window.location.search);
    const hasssoid = urlParams.has("ssoid");
    const ssoid = urlParams.get("ssoid");
    if ((!hasssoid || !ssoid ) && (localStorage.getItem("agentAuth"))) {
      this.props.fetchTabList(agentCode, (resp) => {
        this.setState({
          selectedTab: resp[0],
          reqObject: {
            ...this.state.reqObject,
            status: resp[0].reqKey,
          },
        });
        this.props.fetchCustomerList(agentCode, this.state.reqObject, () => {
          // this.setState({
          //   loading: false,
          // });
        });
        this.props.fetchFilterOptions(agentCode, this.state.reqObject.status);
      });
    }
    if (localStorage.proposalNumberTpa) {
      this.setState({
        openTpa: true
      })
    }
  }
  componentWillReceiveProps(nextProps) {
    const expiryDate = localStorage.getItem('expiry');
    if (expiryDate) {
      const val1 = new Date(parseInt(expiryDate))
      const val2 = new Date()
      if ((val1.getDate() === val2.getDate()) && (val1.getHours() === val2.getHours() && val2.getMonth()===val1.getMonth())) {
        localStorage.clear();
        window.location.reload();
      }
    }

    if (this.props.agentLoggedIn !== nextProps.agentLoggedIn && (localStorage.getItem("agentAuth"))) {
      // this.setState({
      //   loading: true,
      // });
      this.props.fetchTabList(agentCode, (resp) => {
        this.setState({
          selectedTab: resp[0],
          reqObject: {
            ...this.state.reqObject,
            status: resp[0].reqKey,
          },
        });
        this.props.fetchCustomerList(agentCode, this.state.reqObject, () => {
          // this.setState({
          //   loading: false,
          // });
        });
        this.props.fetchFilterOptions(agentCode, this.state.reqObject.status);
      });
    }
  }
  changeTab = (tabObject) => {
    this.setState(
      {
        selectedTab: tabObject,
        reqObject: {
          ...this.state.reqObject,
          status: tabObject.reqKey,
          sort: "LastUpdated",
          group: null,
          page: 0,
          startTimestamp: null,
          endTimestamp: null,
          query: "",
        },
      },
      () => {
        this.props.fetchCustomerList(agentCode, this.state.reqObject, () => {
          window.scrollTo(0, 0);
          document.getElementById("scrollableDiv").scrollTo(0, 0);
          // this.setState({ loading: false });
        });
        this.props.fetchFilterOptions(agentCode, this.state.reqObject.status);
      }
    );
  };
  handleUserProfileDisplay = () => {
    this.setState({
      showUserProfile: !this.state.showUserProfile,
      showHierarchy: false,
    });
  };
  handleHierarchyDisplay = (val) => {
    this.setState({
      showHierarchy: val ? val : !this.state.showHierarchy,
      showUserProfile: false,
    });
  };
  applySort = (selectedSort) => {
    this.setState(
      {
        reqObject: {
          ...this.state.reqObject,
          sort: selectedSort,
          page: 0,
        },
        loading: true,
      },
      () => {
        this.props.fetchCustomerList(agentCode, this.state.reqObject, () => {
          window.scrollTo(0, 0);
          document.getElementById("scrollableDiv").scrollTo(0, 0);
          // this.setState({ loading: false });
        });
      }
    );
  };
  applySearch = (searchString) => {
    this.setState(
      {
        reqObject: {
          ...this.state.reqObject,
          query: searchString,
          page: 0,
        },
        loading: true,
      },
      () => {
        this.props.fetchCustomerList(agentCode, this.state.reqObject, () => {
          window.scrollTo(0, 0);
          document.getElementById("scrollableDiv").scrollTo(0, 0);
          // this.setState({ loading: false });
        });
      }
    );
  };
  applyFilter = (selectedFilter) => {
    this.setState(
      {
        reqObject: {
          ...this.state.reqObject,
          group: selectedFilter,
          page: 0,
          startTimestamp: null,
          endTimestamp: null,
        },
        loading: true,
      },
      () => {
        this.props.fetchCustomerList(agentCode, this.state.reqObject, () => {
          window.scrollTo(0, 0);
          document.getElementById("scrollableDiv").scrollTo(0, 0);
          // this.setState({ loading: false });
        });
      }
    );
  };

  applyDateRange = () => {
    this.props.fetchCustomerList(agentCode, this.state.reqObject, () => {
      window.scrollTo(0, 0);
      document.getElementById("scrollableDiv").scrollTo(0, 0);
      // this.setState({ loading: false });
    });
  };

  openTPAComponent = (data) => {
    this.setState({
      ...this.state,
      openTpa: true,
      tpaData: data
    })
  }
  backFromTPA = () => {
    this.setState({
      ...this.state,
      openTpa: false
    })
    this.props.fetchCustomerList(agentCode, this.state.reqObject, () => {
      // this.setState({
      //   loading: false,
      // });
    });
    localStorage.removeItem('proposalNumberTpa');
    localStorage.removeItem('policyNumberTpa');
    localStorage.removeItem('tpaId');
  }

  setDateReq = (dateKey, date) => {
    this.setState({
      reqObject: {
        ...this.state.reqObject,
        page: 0,
        [dateKey]: date,
        group: null,
      },
    })
  }

  createBookMark = (proposalId, bookmarked, name) => {
    this.setState({ bookmarkChanging: true });
    this.props.createBookMark(
      agentCode,
      { proposalId: proposalId, bookmarked: !bookmarked },
      (response) => {
        if (
          response &&
          response.data &&
          response.data.message == "Bookmark Success."
        ) {
          toaster("success", "Mark Favourite", name);
        } else if (
          response &&
          response.data &&
          response.data.message == "Bookmark Removed."
        ) {
          toaster("success", "Unmark Favourite", name);
        } else {
          toaster("error", "Something went wrong");
        }
        this.setState({ bookmarkChanging: false });
      }
    );
  };
  fetchMoreData = () => {
    let page = this.state.reqObject.page + 1;
    if (this.props.customerList.length < this.props.totalItems) {
      this.setState(
        {
          reqObject: {
            ...this.state.reqObject,
            page: page,
            loading: true,
          },
        },
        () => {
          this.props.fetchCustomerList(agentCode, this.state.reqObject, () => {
            // this.setState({ loading: false });
          });
        }
      );
    }
  };
  logoutUser = () => {
    this.props.agentLogout();
  }
  render() {
    
    if (!this.props.agentLoggedIn) {
      return (
        <>
          <ToastContainer
            position="bottom-right"
            className="toastContainer login-toast"
            autoClose={3000}
            hideProgressBar={true}
          />
          <LoginComponent />
        </>
      )
    }
    else {
      const { filterOptionsList } = this.props;
      const { showUserProfile, reqObject, showHierarchy, openTpa } = this.state;
      return (
        <>
          {!openTpa && <>
            <ToastContainer
              position="bottom-right"
              className="toastContainer"
              autoClose={3000}
              hideProgressBar={true}
            />
            {showUserProfile && !showHierarchy && (
              <UserProfileMobile
                userName={localStorage.getItem('agentName')}
                userImg={DefaultUserIcon}
                UserManualIcon={UserManualIcon}
                HierarchyIcon={HierarchyIcon}
                onBack={this.handleUserProfileDisplay}
                toggleHierarchy={this.handleHierarchyDisplay}
                logoutUser={this.logoutUser}
              />
            )}
            {isMobile() && showHierarchy && (
              <Hierarchy toggleHierarchy={this.handleHierarchyDisplay} />
            )}
            {(!showHierarchy ? true : isMobile() ? false : true) && (
              <div className="container-fluid dashboard-container">
                <Header
                  DefaultUserIcon={DefaultUserIcon}
                  UserManualIcon={UserManualIcon}
                  HierarchyIcon={HierarchyIcon}
                  handleUserProfileDisplay={this.handleUserProfileDisplay}
                  applySearch={this.applySearch}
                  toggleHierarchy={this.handleHierarchyDisplay}
                  logoutUser={this.logoutUser}
                />
                <div className="row dashboard-body">
                  {!isMobile() && showHierarchy && (
                    <Hierarchy toggleHierarchy={this.handleHierarchyDisplay} />
                  )}
                  {(!isMobile() ? !showHierarchy : true) && (
                    <Menu
                      tabList={this.props.tabList}
                      selectedTab={this.state.selectedTab}
                      changeTab={this.changeTab}
                    />
                  )}
                  {(!isMobile() ? !showHierarchy : true) && (
                    <CardContainer
                      customerList={this.props.customerList}
                      filterOptionsList={filterOptionsList}
                      sortValue={reqObject.sort}
                      applySort={this.applySort}
                      applyFilter={this.applyFilter}
                      applySearch={this.applySearch}
                      filterValue={reqObject.group}
                      createBookMark={this.createBookMark}
                      bookmarkChanging={this.state.bookmarkChanging}
                      fetchMoreData={this.fetchMoreData}
                      totalItems={this.props.totalItems}
                      loading={this.state.loading}
                      applyDateRange={this.applyDateRange}
                      startTimestamp={reqObject.startTimestamp}
                      endTimestamp={reqObject.endTimestamp}
                      searchQuery={reqObject.query}
                      fetchExceptionList={this.props.fetchExceptionList}
                      exceptionList={this.props.exceptionList}
                      exceptionListLoader={this.props.exceptionListLoader}
                      setDateReq={this.setDateReq}
                      fetchCustomerListLoading={this.props.fetchCustomerListLoading}
                      isListEmpty={this.props.isListEmpty}
                      activePage={this.state.reqObject.page}
                      openTPA={this.openTPAComponent}
                    />
                  )}

                  {isMobile() && (
                    <SortAndFilterMobile
                      filterOptionsList={filterOptionsList}
                      sortValue={reqObject.sort}
                      applySort={this.applySort}
                      applyFilter={this.applyFilter}
                      filterValue={reqObject.group}
                      applyDateRange={this.applyDateRange}
                      startTimestamp={reqObject.startTimestamp}
                      endTimestamp={reqObject.endTimestamp}
                      setDateReq={this.setDateReq}
                    />
                  )}
                </div>
              </div>
            )}
          </>}
          {openTpa &&
            <>
              <ToastContainer
                position="bottom-right"
                className="toastContainer"
                autoClose={3000}
                hideProgressBar={true}
              />
              <TpaContainer
                closeTpa={this.backFromTPA}
                tpaData={this.state.tpaData} />
            </>
          }
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    customerList: state.dashBoardData.customerList,
    tabList: state.dashBoardData.tabList,
    filterOptionsList: state.dashBoardData.filterOptionsList,
    totalPages: state.dashBoardData.totalPages,
    totalItems: state.dashBoardData.totalItems,
    agentLoggedIn: state.dashBoardData.agentLoggedIn,
    exceptionList: state.dashBoardData.exceptionList,
    exceptionListLoader: state.dashBoardData.exceptionListLoader,
    fetchCustomerListLoading: state.dashBoardData.fetchCustomerListLoading,
    isListEmpty: state.dashBoardData.isListEmpty,
  };
};

export default connect(mapStateToProps, {
  fetchCustomerList,
  fetchTabList,
  fetchFilterOptions,
  createBookMark,
  fetchExceptionList,
  agentLogout,
})(Dashboard);
