import React, { Component } from "react";

class TpaFooter extends Component {


    render() {
        return (
            <div className="tpafooter container">
                {!this.props.consent && <button className="btn backBtn" onClick={this.props.closeTpa}>Back</button>}
                <button className="btn activeBtn" disabled={this.props.disabled} onClick={this.props.completeForm}>Complete Form</button>
            </div>
        );
    }
}

export default TpaFooter;
