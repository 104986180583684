import axios from "axios";
import apiConstants from "../constants/apiConstants";
import actionTypes from "../constants/actionTypes";
import { toaster } from "../utils/utils";

export const fetchStatus = (type, uid, data, cb) => (dispatch) => {
  axios.get(`${apiConstants.DASHBOARD_API_URL}api/v1/tracker/fetchTrackerStatusFlow?proposalNo=${uid}`, {
    headers: {
      "Content-Type": "application/json",
      agentCode: localStorage.getItem('agentCode'),
      "Authorization":localStorage.getItem('agentAuth')
    },
  })
    .then((resp) => {
      dispatch({
        type: actionTypes.fetchAppStatus,
        payload: resp.data.body,
      });
      if(cb) {
        cb(resp.data.body)
      }
    })
    .catch((err) => { 
      if(err.status=='401'){
        dispatch({
          type: actionTypes.agentLogin,
          payload: false,
        });
      }
    });
};

export const clearStatus = () => (dispatch) => {
  dispatch({
    type: actionTypes.clearAppStatus
  });
};

export const resendLink = (linkTo, proposalNo) => async (dispatch) => {
  
  // export const resendLink = (linkTo, data) => async (dispatch) => {
  const payload = {
    "message": null,
    "proposalNumber": proposalNo,
  }
  if(linkTo === "insta"){
    axios.post(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/insta2-notification/${proposalNo}`, {}, {
      headers: {
        "Content-Type": "application/json",
        // agentCode: localStorage.getItem('agentCode'),
        "Authorization":localStorage.getItem('agentAuth')
      },
    })
    .then((resp) => {
      if (
        resp &&
        resp.status === 200
      ) {
        toaster("success", resp.data.message);
      } else {
        toaster("error", resp.data.message);
      }
    })
  }
  else{
    axios.post(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/send/${linkTo}Link`, payload, {
      headers: {
        "Content-Type": "application/json",
        // agentCode: localStorage.getItem('agentCode'),
        "Authorization":localStorage.getItem('agentAuth')
      },
    })
    .then((resp) => {
      if (
        resp &&
        resp.status === 200
      ) {
        toaster("success", resp.data.message);
      } else {
        toaster("error", resp.data.message);
      }
    })
  }
};

export const resendFCLink = (proposalNo, subChannelName) => async (dispatch) => {
  const payload = {
    "message": null,
    "proposalNumber": proposalNo, 
  }  

  axios.post(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/send/bankConsentLink`, payload, {
    headers: {
      "Content-Type": "application/json",
      "Authorization":localStorage.getItem('agentAuth')
    },
  })
  .then((resp) => {
    if (
      resp &&
      resp.status === 200
    ) {
      toaster("success", resp.data.message);
    } else {
      toaster("error", resp.data.message);
    }
  })
};

export const fetchPaymentLinks = (proposalNo) => (dispatch) => {
  axios.get(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/payment/pendingPaymentLink/${proposalNo}`, {
    headers: {
      "Content-Type": "application/json",
      agentCode: localStorage.getItem('agentCode'),
      "Authorization":localStorage.getItem('agentAuth')
    },
  })
    .then((resp) => {
      dispatch({
        type: actionTypes.fetchPaymentDetails,
        payload: resp.data.body,
      });
    })
    .catch((err) => { 
      if(err.status=='401'){
        dispatch({
          type: actionTypes.clearPaymentStatus,
          payload: false,
        });
      }
    });
};

export const resendSpecificLink = (data, encryptProposalNo, cb) => async (dispatch) => {
  const payload = {
    "currencyType": data.currencyName,
    "customerEmailId": data.customerEmailId,
    "customerMobileNo": data.customerMobileNo,
    "onlineLinkTxnId": data.id,
    "paymentMethod": data.paymentMethod,
    "proposalId": data.proposalId,
    "proposalNumber": encryptProposalNo,
    "quoteNumber": null,
    "txAmount": data.txAmount,
    "emandate":data.emandate,
    "onlyEmandate":data.onlyEmandate
  }
  axios.post(`${apiConstants.PROPOSEL_API_URL}api/v1/newbilldesk/sendOnlinePaymentLink`, payload, {
    headers: {
      "Content-Type": "application/json",
      //  agentCode: localStorage.getItem('agentCode'),
      "Authorization":localStorage.getItem('agentAuth')
    },
  })
  .then((resp) => {
    if (
      resp &&
      resp.status === 200
    ) {
      toaster("success", resp.data.message);
    } if(cb && resp){
      cb(resp)
    }
    
    else {
      toaster("error", resp.data.message);
    }
  })
};

export const getCopyLink = (link, pn) => async (dispatch) => {
  
  axios.get(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/copy/link/${link}/${pn}`, {
    headers: {
      "Content-Type": "application/json",
      "Authorization":localStorage.getItem('agentAuth')
    }
  }).then((res) => {
      let x = document.createElement("INPUT");
      x.setAttribute("type", "text");
      x.setAttribute("value", res.data.body.url);
      x.setAttribute("id", "div1");
      document.body.appendChild(x);
      let a = document.getElementById("div1");
      a.select();
      document.execCommand("copy");
      a.style.display = "none";
      document.getElementById("div1").remove();
      toaster("success", "Link Copied!");
  }).catch(err => console.log(`err`, err))
}

export const callCopyLink = (link,pn) => async dispatch => {
  try {
    const {data} = await axios.get(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/copy/link/${link}/${pn}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization":localStorage.getItem('agentAuth')
      }
    })
    if (link === "INSTA_VERIFY_LINK") {
      dispatch({
        type: actionTypes.copyInstaVerifyLink,
        payload: data.body.url
      })
    }
    if (link === "E_MANDATE_LINK") {
      dispatch({
        type: actionTypes.copyEmandateLink,
        payload: data.body.url
      })
    }
    if (link === "ONLINE_PAYMENT_LINK") {
      dispatch({
        type: actionTypes.copyOnlinePaymentLink,
        payload: data.body.url
      })
    }
    console.log(`data`, data)
  } catch(err) {
    console.log(`err`, err)
  }
}

export const triggerInsta2Notification = (proposalNo) => async (dispatch) => {

  axios.post(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/insta2-notification/${proposalNo}`, '',{
    headers: {
      "Content-Type": "application/json",
      "Authorization":localStorage.getItem('agentAuth')
    },
  })
      .then((resp) => {
        if (
            resp &&
            resp.status === 200
        ) {
          toaster("success", resp.data.message);
        } else {
          toaster("error", resp.data.message);
        }
      })
};

export const resendConsentLink= (proposalNo, subChannelName) => async (dispatch) => {
  const payload = {
    "message": null,
    "proposalNumber": proposalNo, 
  }  

  axios.post(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/send/customerConsentLink`, payload, {
    headers: {
      "Content-Type": "application/json",
      "Authorization":localStorage.getItem('agentAuth')
    },
  })
  .then((resp) => {
    if (
      resp &&
      resp.status === 200
    ) {
      toaster("success", resp.data.message);
    } else {
      toaster("error", resp.data.message);
    }
  })
};

export const docSubmitAction = (docPayload, cb) => (dispatch) => {
  dispatch({
      type: "LOADER_ON",
  });
  axios.post(`${apiConstants.PROPOSEL_API_URL}api/v1/customer-portal/additionalInfo/complete`, 
  docPayload,
  {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization":localStorage.getItem('agentAuth')
    }, 
  })
      .then((res) => {
          dispatch({
              type: "LOADER_OFF",
          });
          if (res.data.status==='OK') {
              toaster('success', res.data.message)
              cb(res.data);
          }
          else {
              toaster('error', res.data.message)
              // cb(res.data); // need to remobved
          }
      })
      .catch((error) => {
          dispatch({
              type: "LOADER_OFF",
          });
      });
};