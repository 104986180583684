import React, { Component } from "react";
import logo from "../Assets/logo/logo_2x.svg";

class TpaHeader extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div className="tpa-header">
                <div className="row-header">
                    <div className="container">
                        <div className="logo-img-wrapper">
                            <img className="logo-image" src={logo} alt="logo" />
                        </div>
                        <div className="proposalNumberHeader">
                            <p>Proposal Number</p>
                            <span>{this.props.proposalNumber || localStorage.getItem('proposalNumberTpa')}</span>
                            <p style={{marginTop:"5px"}}>Plan Name </p> <span>{this.props.data && this.props.data.planName}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TpaHeader;
