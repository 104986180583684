import React from "react";
import { connect } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import AppCard from "./AppCard/AppCard";
import AppTracker from "./AppTracker/AppTracker";
import MobilePopUp from "./MobilePopUp/MobilePopUp";
import SortAndFilterWeb from "./SortAndFilter/WebComponent/SortAndFilterWeb";
import { isMobile,isNativeApp } from "../utils/utils";
import SearchBox from "./SearchBox/SearchBox";
import SortAndFilterMobile from "./SortAndFilter/MobileComponent/SortAndFilterMobile";
import loadingGif from "../Assets/images/loaderGif.gif"

class CardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      infoToShow: null,
      showStatus: false,
      activeApptrackerPID: "",
      subChannelName: "",
      showExceptionPopUp: false,
      showHighlightover:false,// appcard
      showAgentStatus:'', // newgen agent view
      exceptionDetails: {
        name: "",
        plan: "",
        proposalNumber: "",
      },
      month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  }

  toggleInfo = (details) => {
    this.setState({
      showDetails: !this.state.showDetails,
      infoToShow: details,
    });
  };

  closeInfo = () => {
    this.setState({
      showDetails: false,
      infoToShow: null,
      activeApptrackerPID: ""
    });
  };

  toggleStatusModal = (details, pid, subChannelName,status) => {
    this.setState({
      showStatus: !this.state.showStatus,
      infoToShow: details,
      activeApptrackerPID: pid,
      subChannelName,
      showAgentStatus:status==="NEWGEN"?true:false,
    });
  };

  openTpaComponent = (e,data,item) => {
   /*const dataState = {...this.state}
   this.setState({
     ...dataState,
     customerData: data
   })*/
   e.stopPropagation()
   localStorage.setItem('proposalNumberTpa',data.proposalNo)
   localStorage.setItem('policyNumberTpa',data.policyNumber)
   localStorage.setItem('tpaId',item.id)
    this.props.openTPA(item);
  };


  showException = (proposalNumber, name, plan) => {
    console.log("sfsfdds");
    this.props.fetchExceptionList(proposalNumber);
    this.setState({
      showExceptionPopUp: true,
      exceptionDetails: {
        name,
        plan,
        proposalNumber,
      },
    });
  };

  showDetailsOnMobile = () => {
    const { showDetails, infoToShow } = this.state;
    if (showDetails && infoToShow) {
      return (
        <MobilePopUp
          heading={infoToShow.customerName}
          onClose={this.closeInfo}
          cssClass={"infoPopUp"}
        >
          <p>
            <span>Sum Assured:</span>
            <span className="currency-icon">&#8377;</span>
            {infoToShow.sumAssured}
          </p>
          <p>
            <span>Premium:</span>
            <span className="currency-icon">&#8377;</span>
            {infoToShow.premium}
          </p>
          <p>
            <span>Customer ID:</span>
            {infoToShow.customerId}
          </p>
          <p>
            <span>Lead ID:</span>
            {infoToShow.leadId}
          </p>
        </MobilePopUp>
      );
    }
  };
  showHighlightFunc = () =>{
     this.setState({
      showHighlightover:true
      })
  }

  renderCards = () => {
    return this.props.fetchCustomerListLoading && !this.props.customerList.length  ? 
    // <h4 className="textCenter">Loading...</h4> : 
    <div className="mainLoadingText">
    <h6 className="loadingTextCenter">
    <img className="loading_gif" src={loadingGif} alt="Loading..."/>
    </h6>
  </div>
    :
    this.props.isListEmpty ?
    <h4 className="textCenter">No data available</h4> : 
    this.props.customerList.map((item, index) => 
    <AppCard
    key={item.proposalId}
    data={item}
    index={index}
    showInfo={this.toggleInfo}
    showStatus={this.toggleStatusModal}
    createBookMark={this.props.createBookMark}
    bookmarkChanging={this.props.bookmarkChanging}
    showException={(pn, name, plan) =>
      this.showException(pn, name, plan)
    }
    openTpaProp = {this.openTpaComponent}
    showHighlight={this.state.showHighlightover}
    showHighlightFunc={this.showHighlightFunc}
  />)
    
    // if (this.props.customerList && this.props.customerList.length > 0) {
    //   return this.props.customerList.map((item, index) => {
    //     return (
    //       <AppCard
    //         key={item.proposalId}
    //         data={item}
    //         index={index}
    //         showInfo={this.toggleInfo}
    //         showStatus={this.toggleStatusModal}
    //         createBookMark={this.props.createBookMark}
    //         bookmarkChanging={this.props.bookmarkChanging}
    //         showException={(pn, name, plan) =>
    //           this.showException(pn, name, plan)
    //         }
    //       />
    //     );
    //   });
    // } else {
    //   return (
    //     <div className="textCenter">
    //       {this.props.fetchCustomerListLoading ? (
    //         <h4 className="textCenter">Loading...</h4>
    //       ) : (
    //         <h4 className="textCenter">No data available</h4>
    //       )}
    //     </div>
    //   );
    // }
  };

  renderFilterValues = () => {
    const { startTimestamp, endTimestamp, applyFilter } = this.props;
    const { month } = this.state;
    return (
      <span>
        <label className="filter-label">Filter by:</label>{" "}
        {new Date(startTimestamp)?.getDate()}{" "}
        {month[new Date(startTimestamp)?.getMonth()]} to{" "}
        {new Date(endTimestamp)?.getDate()}{" "}
        {month[new Date(endTimestamp)?.getMonth()]}{" "}
        <span className="clear-btn" onClick={() => applyFilter("all")}>
          Clear
        </span>
      </span>
    );
  };
  render() {
    const {
      sortValue,
      applySort,
      filterOptionsList,
      applyFilter,
      filterValue,
      applyDateRange,
      startTimestamp,
      endTimestamp,
      searchQuery,
    } = this.props;
    return (
      <div className="card-container ax-card-wrap">
        <div className="searchFilterMainWrap">
          {/* {!isMobile() && startTimestamp && endTimestamp && (
            <div className="left-wrap-filter-info">
              {this.renderFilterValues()}
            </div>
          )} */}
          <div className="right-wrap">
            {!this.props.isListEmpty && (
              <SearchBox
                showIcon={true}
                isVisible={true}
                applySearch={this.props.applySearch}
                searchQuery={searchQuery}
              />
            )}

            {!isMobile() && (
              <SortAndFilterMobile
                sortValue={sortValue}
                applySort={applySort}
                filterOptionsList={filterOptionsList}
                applyFilter={applyFilter}
                filterValue={filterValue}
                applyDateRange={applyDateRange}
                startTimestamp={startTimestamp}
                endTimestamp={endTimestamp}
                setDateReq={this.props.setDateReq}
              />
            )}
          </div>
          {startTimestamp && endTimestamp && (
            <div className="left-wrap-filter-info">
              {this.renderFilterValues()}
            </div>
          )}
        </div>
        <div
          id="scrollableDiv"
          className={`cards-wrap   
          ${
            startTimestamp && endTimestamp && "flter-applied"
          }`}
        >
           <div className={` ${this.state.showHighlightover && isNativeApp() && "overlay-screen"}`}>
           <div className={` ${this.state.showHighlightover && isNativeApp() && "loader-img"}`}></div>
           </div>
          <InfiniteScroll
            dataLength={this.props.customerList.length}
            next={this.props.fetchMoreData}
            hasMore={this.props.customerList.length < this.props.totalItems}
            loader={<h4 className="textCenter pageload">Loading...</h4>}
            scrollableTarget={isMobile() ? document : "scrollableDiv"}
          >
            {this.renderCards()}
          </InfiniteScroll>
        </div>
        {this.showDetailsOnMobile()}
        {this.state.showStatus && (
          <AppTracker
          toggleStatusModal={this.toggleStatusModal}
          data={this.state.infoToShow}
          activeApptrackerPID={this.state.activeApptrackerPID}
          subChannelName={this.state.subChannelName}
          openTpaProp = {this.openTpaComponent}
          showAgentStatus={this.state.showAgentStatus} //agent view
          />
        )}
        {this.state.showExceptionPopUp && (
          <MobilePopUp
            onClose={() => this.setState({ showExceptionPopUp: false })}
            heading={this.state.exceptionDetails.name}
            showExceptionStatus={true}
            details={this.state.exceptionDetails}
            addAutoUnderWritingCss="exception-AUW"
          >
            {this.props.exceptionListLoader ? (
              <div className="exception-loader-wrapper">
                <span>Loading..</span>
              </div>
            ) : this.props.exceptionList === "Exceptions not found" ? (
              <div className="exception-loader-wrapper">
                <span>{this.props.exceptionList}</span>
              </div>
            ) : (
              <ul className="exception-message-wrapper">
                {this.props.exceptionList.map((item,i) => {
                  return (
                    <li key={item.exceptionMessage} className="exception-message">
                      {item.exceptionMessage}
                    </li>
                  );
                })}
              </ul>
            )}
          </MobilePopUp>
        )}
      </div>
    );
  }
}

export default CardContainer

