import React from "react";
import closeImg from "../../Assets/images/close.svg";
import { isMobile } from "../../utils/utils.js";
import { showCalendarClass } from "../../actions/dashBoardActions";
import { connect } from "react-redux";

class MobileAgentPopUp extends React.Component {
  closeModal = () => {
    this.props.onClose && this.props.onClose();
  };
  componentDidMount = () => {
    this.props.showCalendarClass(true);
  };
  componentWillUnmount = () => {
    this.props.showCalendarClass(false);
  };
  render() {
    const {
      cssClass,
      children,
      heading,
      showStatus,
      showOnDesktop,
      details,
      addAutoUnderWritingCss,
      showExceptionStatus,
      popUpBottom,
      removeHeader,
      popupHead,
      customerDetail,
    } = this.props;
    const status =
      details && details.journeyStatus && details.journeyStatus.split("_");

    const renderNumber = (num) => {
      let value = num
        ?.toString()
        .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
      return value;
    };

    return (
      <div
        role="mobilePopup_testRole"
        data-testid="mobilePopup_testId"
        className={`mainPopUpWrap ${
          popUpBottom ? "popUpBottomCss" : ""
        } ${cssClass} ${!isMobile() && showOnDesktop ? "showOnDesktop" : ""}`}
      >
        <span className={`backgroundOverlay`} onClick={this.closeModal} />
        <div className="mobilePopUpWrap ">
          {removeHeader ? null : (
            <div className="pop-top-sec">
              {this.props.popupHead ? (
                <div
                  data-testid="mobilePopup_cancelBtn"
                  className="popup-header"
                >
                  {popupHead}
                </div>
              ) : (
                ""
              )}
              <span className="close-btn" onClick={this.closeModal}>
                <img alt="Close" src={closeImg} />
              </span>
              {heading && <div className="heading">{heading}</div>}
              {showExceptionStatus && (
                <div className="showstatusWrap">
                  <div className="left-col">
                    <p>
                      <span>Plan:</span>{" "}
                      {customerDetail && customerDetail?.proposalBasicDetails?.planName}
                    </p>
                    <p className="exc-pn">
                      <span>Proposal Number:</span>{" "}
                      {customerDetail && customerDetail?.proposalBasicDetails?.proposalNumber}
                    </p>
                  </div>
                </div>
              )}
              {showStatus && (
                <div className="showstatusWrap">
                  <ul>
                    <li>
                      <p>Premium</p>
                      <span>
                        <>&#8377; </>
                        {customerDetail?.proposalBasicDetails?.premium
                          ? renderNumber(customerDetail?.proposalBasicDetails?.premium)
                          : "-"}
                      </span>
                    </li>
                    <li>
                      <p>Sum Assured</p>
                      <span>
                        <>&#8377; </>
                        {customerDetail?.proposalBasicDetails?.sumAssured
                          ? renderNumber(customerDetail?.proposalBasicDetails?.sumAssured)
                          : "-"}
                      </span>
                    </li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              )}
            </div>
          )}
          <div className="pop-btm-sec">{children}</div>
        </div>
      </div>
    );
  }
}

const actions = {
  showCalendarClass,
};

export default connect(null, actions)(MobileAgentPopUp);
