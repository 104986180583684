import React, { useState } from 'react'
import dowImgArw from "../../../Assets/images/dwn-arw.png";
import MedicalRequirement from './MedicalRequirement';
const Medical = ({accDetails, setDescAndShowMed}) => {
const [openAccordion, setOpenAccordion] = useState(null)

const tpaReq = accDetails?.tpaRequirementDetails;
const insured = accDetails?.tpaRequirementDetails?.insuredList?.length > 0
const owner = accDetails?.tpaRequirementDetails?.ownerList?.length > 0

const dataAcc = insured && owner ? [
      {
        id: 1,
        title: accDetails?.proposalBasicDetails?.proposerName || 'PROPOSER',
        data : accDetails?.tpaRequirementDetails?.ownerList
    },
    {
        id: 2,
        title: accDetails?.proposalBasicDetails?.insuredName || 'INSURER',
        data: accDetails?.tpaRequirementDetails?.insuredList
    },
    // {
    //     id: 2,
    //     title: accDetails?.proposalBasicDetails?.proposerName || 'PROPOSER',
    //     data : accDetails?.tpaRequirementDetails?.ownerList
    // },
]
: owner ? [
{
    id: 1,
    title: accDetails?.proposalBasicDetails?.proposerName || 'PROPOSER',
    data :accDetails?.tpaRequirementDetails?.ownerList
},
]:[
{
    id: 2,
    title: accDetails?.proposalBasicDetails?.insuredName || 'INSURER',
    data: accDetails?.tpaRequirementDetails?.insuredList
},

]
const renderElement = (title, data) => {
    return <MedicalRequirement
            accDetails={accDetails}
            tpaReq={accDetails?.tpaRequirementDetails}
            data={data}
            setDescAndShowMed={setDescAndShowMed}
            />
        }
const toggleAccordion = (id) => {
    setOpenAccordion(openAccordion === id ? null : id);
  };
  return (
    <div>
         <ul className="addNonMedAcc">
        {dataAcc.map((item) => {
          return (
            <li className="addNonMedAccList" key={item.id}>
              <div
                className="acc-non-block"
                onClick={() => toggleAccordion(item.id)}
              >
                <div>{item.title}</div>
                <div className="acc-active-icon ">
                  <img
                    className={openAccordion === item.id ? "upArrow" : ""}
                    src={dowImgArw}
                    alt="icon"
                  />
                </div>
              </div>
              {openAccordion === item.id ? (
                <div className="show consent-block">
                 { renderElement(item.title, item.data)}
                </div>
              ) : (
                ""
              )}
            </li>
          );
        })}
      </ul>
    </div>
  )
}

export default Medical