import React, { Component } from "react";
import calendar from "../Assets/images/calendar.svg";
import MobilePopUp from "../containers/MobilePopUp/MobilePopUp";
import Calendar from "./Calendar/Calendar";
import {
  formateDate,
  formateDateAndTime,
  isDateValid,
} from "../utils/utils";
export class DOBInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dobValue: "",
      labelShrink: false,
      showCalendar: false,
    };
  }
  componentDidMount() {
    if (this.props.dob) {
      this.setState({
        dobValue: this.props.dob,
        labelShrink: true,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.dob !== nextProps.dob) {
      this.setState({
        dobValue: nextProps.dob,
      });
    }
  }
  showDobValue = (dob) => {
    let val;
    if (!dob) {
      return null;
    }
    if (dob && isDateValid(dob)) {
      val = formateDate(new Date(dob));
    } else {
      val = formateDate(new Date(formateDateAndTime(dob)));
    }
    return val;
  };
  manuplateDob = (dob) => {
    let dobVal;
    if (!dob) {
      return null;
    }
    if (dob && isDateValid(dob)) {
      dobVal = new Date(dob);
    } else {
      dobVal = new Date(formateDateAndTime(dob));
    }
    return dobVal;
  };
  render() {
    const { label } = this.props;
    const { dobValue, labelShrink } = this.state;
    const disableFutureDt = (current) => {
     // return current.isBefore(today);
    };
    return (
      <>
        <div
          className={`dob-input-wrapper ${this.props.cssClass}`}
          onClick={() => this.setState({ showCalendar: true })}
        >
          <label className={`dob-labbel ${labelShrink ? "label-shrink" : ""}`}>
            {label ? label : "Date Of Birth"}
          </label>
          <div className="dob-field">{this.showDobValue(dobValue)}</div>
          <div className="dob-icon">
            <img src={calendar} alt="calendar" className="dob-icon-img" />
          </div>
        </div>
        {this.state.showCalendar && (
          <div className="calendar-outer-wrapper">
            <MobilePopUp hideCloseBtn={true}>
              <Calendar
                dob={this.manuplateDob(this.props.dob)}
                maxDate={new Date()}
                isValidDate={disableFutureDt}
                onCancel={() => {
                  this.setState({ showCalendar: false, labelShrink: false });
                  this.props.getDob("");
                }}
                onApply={(sd) => {
                  this.setState({ showCalendar: false });
                  this.setState({ dobValue: sd, labelShrink: true });
                  this.props.getDob(sd);
                }}
              />
            </MobilePopUp>
          </div>
        )}
      </>
    );
  }
}

export default DOBInputs;
