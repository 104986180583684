import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import questionMark from "../../../Assets/images/qstn.png";
import information from "../../../Assets/images/information.svg"
import greenCheck from "../../../Assets/images/green_check.svg";
import PopUp from "../../PopUp";
import { testDescription, medicalStatus } from "../data";
import { tpaRetrigger } from "../action";
import { convertToIST } from "../agentUtils";
import { customerPortalStatus } from "../../../actions/dashBoardActions";
const MedicalRequirement = ({ accDetails, tpaReq, data, setDescAndShowMed }) => {
  const popupRef = useRef(null);
  const [popupHeight, setPopupHeight] = useState("auto");
  const [openAccordion, setOpenAccordion] = useState(null);
  const [desciprtion, setDescription] = useState("");
  const [show, setShow] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (popupRef.current) {
      setPopupHeight(popupRef.current.scrollHeight);
    }
  }, [data]);

  const renderDate = (date) => {
    const istDate = convertToIST(date);
    return istDate;
  };
  const showTooltip = (description) => {
    console.log("description", description);
    setShow(!show);
    setDescription(description);
  };
  // const handleClick = (description) => {
  //     setSelectedDescription(description);
  //     setShowModal(true);
  // };

  const retriggerHandler = (status, id, regCode) => {
    const data = {
      id: id,
      proposalNumber: accDetails?.proposalBasicDetails?.proposalNumber,
    };
    if (regCode) {
      return;
    } else {
      dispatch(
        tpaRetrigger(data, () => {
          dispatch(
            customerPortalStatus(
              accDetails?.proposalBasicDetails?.proposalNumber,
              () => {}
            )
          );
        })
      );
    }
  };
  const sortByDate = (list) => {
    return [...list].sort((a, b) => new Date(a.date) - new Date(b.date));
  };
  return (
    <ul ref={popupRef} className="card-body med-body">
      {sortByDate(data)?.map((item, index) => {
        return (
          <li className="rvsd_dwnld_outr med-card">
            {/* <div className="rvsd_dwnld mB-10" >
                            {item?.tpaStatus && <span>{`${medicalStatus[item?.tpaStatus]}`}</span>}
                            {!item?.tpaStatus && !item?.tpaRegistrationCode && <span>Yet to start</span>}
                            {!item?.tpaStatus && item?.tpaRegistrationCode &&  <span>Pending medical appointment</span>}
                            {(item?.tpaStatus === "MDRU" || item?.tpaStatus === "MDRA")&& <span className='status-span'><img src={greenCheck}/></span>}
                        </div> */}
            <div className="rvsd_dwnld med-card-first">
              <div className="med-card-info">
                {item?.tpaStatus && (
                  <span>{`${medicalStatus[item?.tpaStatus]}`}</span>
                )}
                {!item?.tpaStatus && !item?.tpaRegistrationCode && (
                  <span>Yet to start</span>
                )}
                {!item?.tpaStatus && item?.tpaRegistrationCode && (
                  <span>Pending medical appointment</span>
                )}
                {(item?.tpaStatus === "MDRU" || item?.tpaStatus === "MDRA") && (
                  <span className="status-span">
                    <img src={greenCheck} alt={"greentick"} />
                  </span>
                )}
              </div>

              <div className="time-stamp">{renderDate(item?.date)}</div>
            </div>
            <div className="med-test">
              <div className="rvsd_dwnld">
                Medical Test Category
                <span className="ml-1"> - &nbsp; </span>
                {/* <span className='ml-1'> - </span> */}
                {/* <span className='ml-1'>{item.tpaTestCode}</span> */}
              </div>
              <div className="test-category">

              {item?.tpaTestCode?.map((testCode, index) => {
                const tpaDescription =
                  item?.description && item?.description[index]
                    ? item?.description[index]
                    : "";
                return (
                  <div className="test-wrapper" key={testCode}>
                    <div
                      className="test-code"
                      //   onClick={() => testClickHandler(testCode)}
                    >
                      {/* <span class="doc-dot"></span> */}
                      <span>{testCode}</span>

                    </div>
                    <div className="rvsd_blk-tooltip">
                      <span className="tooltipbx">
                        <img
                          src={information}
                          onClick={() => {
                            showTooltip(tpaDescription);
                            setDescAndShowMed(true, tpaDescription)

                          }}
                          width="14"
                          height="14"
                          alt=""
                        />
                        {/* <span className="tooltiptext">MER, Midstream urine analysis- MSU, Fasting lipid profile, TMT,Hba1c,HIV,Complete blood count with peripheral smear, Liver function test-SGOT,SGPT,GGT, Alkaline phosphate, Total bilirubin with breakup of Direct and Indirect. Renal function test including Blood urea nitrogen and Serum creatinine.
                                        </span> */}
                        {/* <span className="tooltiptext" onClick={showTooltip}>{testDescription[item.tpaTestCode]?.[0]?.description}</span> */}
                        {/* {show && (
                          <PopUp
                            onClose={() => showTooltip()}
                            heading={""}
                            // showStatus={props.showStatus}
                            addCss={"toolText-popup"}
                            medPopoup={popupHeight}
                            showOnDesktop={true}
                          >
                            {desciprtion}
                          </PopUp>
                        )} */}
                      </span>
                    </div>
                  </div>
                );
              })}
              </div>
            </div>

            <div className="rvsd_dwnld med-blk-btn">
              <div className="mt-1">Click here to</div>
              {
                <button
                  className={`med-btn mL-15 ${
                    item?.tpaRegistrationCode && "disable-tpa"
                  } `}
                  onClick={() => {
                    retriggerHandler(
                      item?.tpaStatus,
                      item?.tpaId,
                      item?.tpaRegistrationCode
                    );
                  }}
                >
                  Retrigger
                </button>
              }
              {/* {<span className='line'>|</span>}
                            {<span className="med-btn" onClick={() => {}}>Book Medicals</span>} */}
            </div>

            {/* <div className='rvsd_dwnld time-stamp'>{renderDate(item?.date)}</div> */}
          </li>
        );
      })}
    </ul>
  );
};

export default MedicalRequirement;
