import React, { useState } from 'react'
// import Input from '@/component/Input'
// import Accordion2 from '../../component/Accordion/Accordion2'
import { consentDataList } from '../data'

const ConsentLayout = ({ accDetails, data, title, label, consentData }) => {
    const [openAccordion, setOpenAccordion] = useState(null)


    const dataAcc = accDetails?.proposalBasicDetails?.policyFor === 'OTHER' ? [
        {
            id: 1,
            title: 'Insured'
        },
        {
            id: 2,
            title: 'Proposer'
        },
    ]
        : [
            {
                id: 1,
                title: 'Insured'
            }
        ]

    const changeHandler = () => { }
    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id)
    }
    const renderListItem = (ele) => {
        if (ele in consentDataList) {
            return consentDataList[ele]
        }
        else {
            return ele
        }

    }
    // health questionnaire 
    const renderElement = (title) => {
        let detail = title === 'Insured' ? consentData && Object.entries(consentData.insuredQuestionDetails).slice(0,6) :
        consentData && Object.entries(consentData.proposerQuestionDetails).slice(0,6)
        const renderData = <div className='consent-ques-blk'>{
            detail && detail.map((item, id) => {
                return (<div className='consent-ques-ans' key={id}>
                    <div className='consent-ques'>
                        {id + 1}{'. '}{item[0]}
                    </div>
                    <div className='consent-ans-blk'>
                        <div className='consent-ans'>
                            <span>Old details</span>
                            <input
                                type='text'
                                name={item[1].oldDetails}
                                value={item[1].oldDetails}
                                // changeHandler={changeHandler}
                                readOnly={true}
                            />
                        </div>
                        <div className='consent-ans'>
                            <span>New details</span>
                            <input
                                type='text'
                                name={item[1].revisedDetails}
                                value={item[1].revisedDetails}
                                // changeHandler={changeHandler}
                                readOnly={true}

                            />
                        </div>
                    </div>
                </div>)
            })
        }</div>
        return renderData

    }
    return (
        <div className='form-container'>
            {title !== 'Health Questionnaire Details' ?
                <table className='form-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th><span>Old details</span></th>
                            <th><span>Revised Details</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            consentData && consentData.map((item, idx) => {
                                return (
                                    <tr
                                        key={idx}
                                    >
                                        <td>
                                            {/* {item[0]} */}
                                            <span>{renderListItem(item[0])}</span>
                                        </td>
                                        <td>
                                            <input
                                                type='text'
                                                name={item[1].oldDetails}
                                                value={item[1].oldDetails}
                                                // changeHandler={changeHandler}
                                                readOnly={true}

                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='text'
                                                name={item[1].revisedDetails}
                                                value={item[1].revisedDetails}
                                                // changeHandler={changeHandler}
                                                readOnly={true}

                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                :
                <ul>
                    {dataAcc.map((item) => {
                        return (
                            <li className='addNonMedAccList' key={item.id} >
                                {/* <Accordion2
                                    item={item}
                                    openAccordion={openAccordion}
                                    toggleAccordion={toggleAccordion}
                                /> */}
                                <div className='acc-non-block' onClick={() => toggleAccordion(item.id)}>
            <div>{item.title}</div>
            {/* {isInsurerDocPresent &&  */}
            <div className='acc-active-icon '>
                {/* <Image
                    className={openAccordion === item.id ? 'upArrow' : ''}
                    src={dwnArrow}
                    alt='icon'
                /> */}
            </div>
            {/* } */}
        </div>
                                {openAccordion === item.id ?
                                    <div className='show'>
                                        {
                                            renderElement(item.title)
                                        }
                                    </div>
                                    : ''
                                }
                            </li>
                        )
                    })}
                </ul>
            }
        </div>
    )
}

export default ConsentLayout
