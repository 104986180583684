import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toaster } from "../../utils/utils";
import {
  downloadAction,
  uploadAction,
  deleteDocAddInfo,
  saveDetailAction,
} from "../../containers/AgentView/action";
import UploadDocModal from "../../containers/UploadDocModal";
import { customerPortalStatus } from "../../actions/dashBoardActions";
import questionMark from "../../Assets/images/qstn.png";
import previewImg from "../../Assets/images/preview.png";
import deleteImg from "../../Assets/images/delete.png";
import uplImg from "../../Assets/images/upload_btn.png";
import greentick from "../../Assets/images/green_check.svg";
import dowImg from "../../Assets/images/download_icon.svg";
import PopUp from "../../containers/PopUp";
import DeletePopUpPage from "../../containers/DeletePopUpPage";
const CounterOffer = ({ accordionDetails }) => {
  const dispatch = useDispatch();
  // const [showConsent, setShowConsent]= useState(false)
  // const [declineCounter, setDeclineCounter] = useState(false);
  // const [selectedImage , setselectedImage] = useState(null);
  // const [reasonOne, setReasonOne] = useState(false);
  // const [reasonTwo, setReasonTwo] = useState(false);
  // const [inputValue, setInputValue] = useState("");
  // const [declineReason, setDeclineReason] = useState("");
  // const [revisedAction, serevisedActiont] = useState("");
  // const [showUploadModal, setShowUploadModal] = useState(false);
  // const [uploadTrue, setUploadTrue] = useState(false);
  // const [hidereconsiderBtn, sethidereconsiderBtn] = useState(false);

  const [showConsent, setShowConsent] = useState(false);
  const [counterUpload, setCounterUpload] = useState(false);
  const [revisedUpload, setRevisedUpload] = useState(false);
  const [cisUpload, setCISUpload] = useState(false);
  const [showLabel, showSetLabel] = useState(false);

  const [selectedImage, setselectedImage] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [declineCounter, setDeclineCounter] = useState(false);
  const [reasonOne, setReasonOne] = useState(false);
  const [reasonTwo, setReasonTwo] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [otp, setOtp] = useState("");
  const [showThankyou, setShowThankyou] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);

  const [uploadTrue, setUploadTrue] = useState(false);
  const [hidereconsiderBtn, sethidereconsiderBtn] = useState(false);

  const [overlay, setOverlay] = useState(false);
  const [refId, setRefId] = useState();
  const [revisedAction, serevisedActiont] = useState("");
  const [preview, setPreview] = useState({ url: "", data: null });
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const revisedDetail = accordionDetails.filter(
    (item) => item.status === "REVISED_OFFER"
  );
  const customerDetail = useSelector((state) => state.customerDetailReducer);
  const counterOfferRequirement = useSelector(
    (state) => state.customerDetailReducer?.counterOfferRequirement
  );
  const proposalBasicDetails = useSelector(
    (state) => state.customerDetailReducer?.proposalBasicDetails
  );

  const documentList = useSelector(
    (state) => state.customerDetailReducer?.policyDocuments
  );
  // const reconsiderDoc =
  // proposalBasicDetails?.policyFor === "SELF"
  //     ? customerDetail?.additionalInfoDocs?.primaryInsuredDocumentDetail
  //         ?.ServiceDocumentList
  //     : customerDetail?.additionalInfoDocs?.proposerDocumentDetail
  //         ?.ServiceDocumentList;

  const reconsiderDoc =
    counterOfferRequirement?.counterOfferDetails?.serviceDocumentList ||
    counterOfferRequirement?.serviceDocumentList;
  const finalReconsiderDoc = counterOfferRequirement?.serviceDocumentList
  const filterItem = reconsiderDoc?.filter((item) => {
    if (
      item.docCategoryCd === "CORECON" &&
      item.docCategoryTypeCd === "CORECON" &&
      item.documentCd === "CORECON" &&
      item.url !== ""
    ) {
      return item;
    }
  });

  const filterCounterDoc = reconsiderDoc?.filter((item) => {
    if (
      item.docCategoryCd === "COCL" &&
      item.docCategoryTypeCd === "CO" &&
      item.documentCd === "COC" &&
      item.url !== ""
    ) {
      return item;
    }
  });
  

  const filterRevisedDoc = reconsiderDoc?.filter((item) => {
    if (
      item.docCategoryCd === "RBI" &&
      item.docCategoryTypeCd === "RBI" &&
      item.documentCd === "RBI" &&
      item.url !== ""
    ) {
      return item;
    }
  });

  const filterCisDoc = reconsiderDoc?.filter((item) => {
    if (
      item.docCategoryCd === "CIS" &&
      item.docCategoryTypeCd === "CIS" &&
      item.documentCd === "CIS" &&
      item.url !== ""
    ) {
      return item;
    }
  });

  const filterFinalItem = finalReconsiderDoc?.filter((item) => {
    if (
      item.docCategoryCd === "CORECON" &&
      item.docCategoryTypeCd === "CORECON" &&
      item.documentCd === "CORECON" &&
      item.url !== ""
    ) {
      return item;
    }
  });
  const imageStyle = {
    marginRight: "8px",
    width: "20px",
    height: "20px",
  };
  const greenImageStyle = {
    marginRight: "8px",
    width: "20px",
    height: "16px",
  };

  useEffect(() => {
    if (
      counterOfferRequirement?.counterOfferConsentAction ===
        "RECONSIDER_COUNTER_OFFER" &&
      counterOfferRequirement?.counterOfferConsentSubmitted !== true &&
      (!filterItem || (filterItem && filterItem[0] === undefined))
    ) {
      setShowUploadModal(true);
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     declineReason ||
  //     (!declineReason &&
  //       inputValue &&
  //       inputValue.counterReason !==
  //         "Request for reconsidering the counter offer")
  //   ) {
  //     setShowConsent(true)
  //     // sendOtp();
  //     // setOverlay(true);
  //     // sethidereconsiderBtn(false);
  //     // setShowSubmitBtn(false);
  //   }
  //   if (
  //     inputValue.counterReason === "Request for reconsidering the counter offer"
  //   ) {
  //     setShowUploadModal(true);
  //     sethidereconsiderBtn(false);
  //   }
  // }, [declineReason, inputValue]);

  const changeHandler = (name, value) => {
    if (counterOfferRequirement.counterOfferConsentAction) {
      return;
    }
    setInputValue({
      [name]: value,
    });
    if (
      value === "Accept the revised offer" ||
      value === "Adjust the Sum Assured to match Existing Premium"
    ) {
      setReasonOne("");
      setReasonTwo("");
      setDeclineReason("");
    }
    // else setShowOtp(false);

    if (value === "Decline the revised offer") setDeclineCounter(true);
    else setDeclineCounter(false);

    if (value === "Accept the revised offer") {
      serevisedActiont("ACCEPTED");
    } else if (value === "Adjust the Sum Assured to match Existing Premium") {
      serevisedActiont("ADJUST_SUM_ASSURED");
    } else if (value === "Decline the revised offer") {
      serevisedActiont("REJECTED");
    } else if (value === "Request for reconsidering the counter offer") {
      serevisedActiont("RECONSIDER_COUNTER_OFFER");
      // serevisedActiont('ACCEPTED')
    }
    setShowConsent(true);
  };
  const downloadHandler = (fileName) => {
    // let proposalNo = localStorage.getItem("proposalNo");
    let proposalNo = proposalBasicDetails?.proposalNumber;
    const serviceDocumentList =
      counterOfferRequirement?.counterOfferDetails?.serviceDocumentList;
    const revisedBIUrl = serviceDocumentList
      ?.filter((data) => data.documentCd === "RBI")
      .map((data) => data?.url);

    const counterOfferUrl = serviceDocumentList
      ?.filter((data) => data.documentCd === "CO")
      .map((data) => data?.url);

    let file = documentList[fileName];
    if (fileName === "REVISED_OFFER_DOC") {
      // file = counterOfferUrl || documentList[fileName];
      file = documentList[fileName]
    } else if (fileName === "REVISED_BI_DOC") {
      // file = revisedBIUrl || documentList[fileName];
      file = documentList[fileName]
    }
    else if (fileName === "REVISED_CIS_TAG_NAME") {
      // file = revisedBIUrl || documentList[fileName];
      file = documentList[fileName]
    }

    if (
      file === "" ||
      file === " " ||
      file === undefined ||
      (file && file[0] === undefined)
    ) {
      toaster("error", "File not exist.");
    } else {
      dispatch(downloadAction(proposalNo, file));
    }
  };

  const uploadDocHandler = (fileValue) => { 
    let file = fileValue;
    let fileSize = fileValue.size;
    let formData = new FormData();
    formData.append("file", file);
    let headerData;
    if (fileSize <= 10485760) {
      headerData = {
        documentCd: counterUpload ? "COC" : revisedUpload ? "RBI" : cisUpload ? "CIS" :"CORECON",
        docCategoryCd: counterUpload
          ? "COCL"
          : revisedUpload
          ? "RBI"
          : cisUpload ? "CIS": "CORECON",
        docCategoryTypeCd: counterUpload
          ? "CO"
          : revisedUpload
          ? "RBI"
          : cisUpload ? "CIS": "CORECON",
        documentType: "CO",
        partyType:
          proposalBasicDetails?.policyFor === "SELF" ? "INSURER" : "OWNER",
        id: 1,
        documentSide: "FRONT_SIDE",
        policyNo: proposalBasicDetails?.policyNumber,
        documentNumber: 1,
        proposalNo: proposalBasicDetails?.proposalNumber,
        uwId: 49786,
      };
      dispatch(
        uploadAction(headerData, formData, (res) => {
          if (res.status === "ACCEPTED") {
            setShowUploadModal(false);
            setCounterUpload(false);
            setRevisedUpload(false);
            setCISUpload(false);
            // setShowThankyou(true)
            setShowSubmitBtn(true);
            setUploadTrue(true);
            sethidereconsiderBtn(true);
            dispatch(
              customerPortalStatus(
                proposalBasicDetails?.proposalNumber,
                (res) => {}
              )
            );
          }
        })
      );
    }
  };

  const viewDocHandler = (url) => {
    if (!url) {
      toaster("error", "File not present");
      return;
    }
    dispatch(
      downloadAction(
        proposalBasicDetails?.proposalNumber,
        url,
        "preview",
        (res) => {
          if (res.indexOf("blob") > -1) {
            setPreview({ url: res, data: "" });
          }
        }
      )
    );
  };
  const deleteDocHandler = (data) => {
    let proposalNo = proposalBasicDetails?.proposalNumber;
    // let uwId = customerDetail?.additionalInfoDocs.uwId;
    let uwId = 0;
    let item = data;
    if (!item) {
      setShowDeletePopup(false);
      toaster("error", "Flie is not present");
      return;
    }
    let payload = JSON.parse(JSON?.stringify(item));
    payload["uwId"] = uwId;
    payload["proposalNo"] = proposalNo;

    dispatch(
      deleteDocAddInfo(payload, () => {
        setShowDeletePopup(false);
        setUploadTrue(false);
        setShowUploadModal(false);
        dispatch(customerPortalStatus(proposalNo, (res) => {}));
      })
    );
  };

  const closeHandler = () => {
    setPreview("");
  };
  const openDeletePopUp = () => {
    setShowDeletePopup(true);
  };
  const reconsiderSubmitBtn = () => {
    // setShowOtp(true)
    // sendOtp();
    // setOverlay(true)
  };

  const closeConsentHandler = () => {
    setShowConsent(!showConsent);
  };
  const consentSubmitHandler = () => {
    const data = {
      proposalNumber: proposalBasicDetails?.proposalNumber,
      policyNumber: proposalBasicDetails?.policyNumber,
      otp: null,
      counterOfferConsent: {
        proposalNumber: proposalBasicDetails?.proposalNumber,
        consentAction: revisedAction,
        consentType: "REVISED_OFFER",
        consentBy: "AGENT", //consent by AGENT or CUSTOMER
        rejectionReason: null,
      },
      dataChangeConsent: null,
      addInfoConsent: null,
      requestType: "SAVE",
    };
    dispatch(
      saveDetailAction(data, () => {
        setShowConsent(false);
        if (
          inputValue.counterReason ===
          "Request for reconsidering the counter offer"
        ) {
          setShowUploadModal(true);
        }
      })
    );
  };

  const showToolText = (value) => {
    setselectedImage(value);
  };

  // const closeConsentHandler = ()=>{
  //   setShowConsent(!showConsent)
  // }
  // const consentSubmitHandler = ()=>{
  //   setShowConsent(false)
  // }

  // console.log(
  //   "test",
  //   filterCounterDoc?.[0]?.url?.includes("agent"),
  //   filterRevisedDoc,
  //   filterRevisedDoc?.[0]?.url
  // );
  return (
    <div className="consent-blk consent-tag-blk">
      {/* <div className="rvsd_dwnld_outr"> */}
        <div className="consent-tag">
          Please download the below documents to check your counter offer{" "}
        </div>
        <ul className="conset-doc">
        <li className="progressWrapper">
          <span>
          
              Counter Offer Letter
          
          </span>
          {/* {counterOfferRequirement?.counterOfferConsentAction  && !filterCounterDoc?.[0].url?.includes('agent') && counterOfferRequirement?.counterOfferConsentSubmitted !== true &&  */}
          <div className="action-blk">
            {counterOfferRequirement?.counterOfferActionBy === "AGENT" &&
              counterOfferRequirement?.counterOfferConsentAction &&
              !filterCounterDoc?.[0]?.url?.includes("agent") &&
              counterOfferRequirement?.counterOfferConsentSubmitted !==
                true && (
                <a
                  className="upl-img-link"
                  onClick={() => {
                    setShowUploadModal(true);
                    setCounterUpload(true);
                    showSetLabel('Counter Offer Letter')
                  }}
                >
                  <img src={uplImg} alt="uplImg" style={imageStyle} />
                </a>
              )}

            {
              filterCounterDoc?.[0]?.url?.includes("agent") && (
                <img src={greentick} alt="greenTick" style={greenImageStyle} />
              )}
            <a
              className="upl-img-link"
              onClick={() => downloadHandler("REVISED_OFFER_DOC")}
            >
              <img src={dowImg} alt="dowImg" style={imageStyle} />
            </a>
          </div>
          {/* } */}
        </li>
        <li className="progressWrapper">
            <span
              // onClick={() => downloadHandler("REVISED_BI_DOC")}
            >
              Revised Benefit Illustration
            </span>
        
          {/* {counterOfferRequirement?.counterOfferConsentAction &&  !filterRevisedDoc?.[0].url?.includes('agent') && counterOfferRequirement?.counterOfferConsentSubmitted !== true && */}
          <div className="action-blk">
            {
            counterOfferRequirement?.counterOfferActionBy === "AGENT" &&
              counterOfferRequirement?.counterOfferConsentAction &&
              !filterRevisedDoc?.[0]?.url?.includes("agent") &&
              counterOfferRequirement?.counterOfferConsentSubmitted !==
                true &&
                 (
                <a
                  className="upl-img-link"
                  onClick={() => {
                    setShowUploadModal(true);
                    setRevisedUpload(true);
                    showSetLabel('Revised Benefit Illustration')
                  }}
                >
                  <img src={uplImg} alt="uplImg" style={imageStyle} />
                </a>
              )}

            {
              filterRevisedDoc?.[0]?.url?.includes("agent") && (
                <img src={greentick} alt="greenTick" style={greenImageStyle} />
              )}

            <a
              className="upl-img-link"
              onClick={() => downloadHandler("REVISED_BI_DOC")}
            >
              <img src={dowImg} alt="dowImg" style={imageStyle} />
            </a>
          </div>
          {/* } */}
        </li>
        <li className="progressWrapper">
            <span
              // onClick={() => downloadHandler("REVISED_BI_DOC")}
            >
             Revised Customer Information Sheet
            </span>
          {/* {counterOfferRequirement?.counterOfferConsentAction &&  !filterRevisedDoc?.[0].url?.includes('agent') && counterOfferRequirement?.counterOfferConsentSubmitted !== true && */}
          <div className="action-blk">
            {counterOfferRequirement?.counterOfferActionBy === "AGENT" &&
              counterOfferRequirement?.counterOfferConsentAction &&
              !filterCisDoc?.[0]?.url?.includes("agent") &&
              counterOfferRequirement?.counterOfferConsentSubmitted !==
                true && (
                <a
                  className="upl-img-link"
                  onClick={() => {
                    setShowUploadModal(true);
                    setCISUpload(true);
                    showSetLabel('Revised Customer Information Sheet')
                  }}
                >
                  <img src={uplImg} alt="uplImg" style={imageStyle} />
                </a>
              )}

            {
              filterCisDoc?.[0]?.url?.includes("agent") && (
                <img src={greentick} alt="greenTick" style={greenImageStyle} />
              )}

            <a
              className="upl-img-link"
              onClick={() => downloadHandler("REVISED_CIS_TAG_NAME")}
            >
              <img src={dowImg} alt="dowImg" style={imageStyle} />
            </a>
          </div>
          {/* } */}
        </li>
        </ul>
      {/* </div> */}
      {/* Reconsider doc */}
      {/* {counterOfferRequirement?.counterOfferConsentSubmitted === true &&
        revisedDetail &&
        revisedDetail[0].actual_status === "COMPLETED" &&
        counterOfferRequirement?.counterOfferConsentAction ===
          "RECONSIDER_COUNTER_OFFER" && (
          <div className="rvsd_dwnld_outr d-flex">
            <div className="rvsd_dwnld mr-2">Reconsidering document</div>
            <a
              className="view-img-link"
              onClick={() => viewDocHandler(filterItem?.[0]?.url)}
            >
              <img src={previewImg} alt="uplImg" style={imageStyle} />
            </a>
          </div>
        )} */}

      {((counterOfferRequirement?.counterOfferConsentAction ===
        "RECONSIDER_COUNTER_OFFER" &&
        counterOfferRequirement?.counterOfferActionBy === "AGENT" &&
        revisedDetail) || (filterFinalItem?.[0]?.url || filterItem?.[0]?.url )) && (
          <div className="rvsd_dwnld_outr d-flex">
            <div className="rvsd_dwnld mr-2">Reconsidering document</div>

            <div className="recon-btn">
              {(!filterItem?.[0]?.url || !filterFinalItem?.[0]?.url) && counterOfferRequirement?.counterOfferActionBy === "AGENT" && (
                <a
                  className="upl-img-link"
                  onClick={() => setShowUploadModal(true)}
                >
                  <img src={uplImg} alt="uplImg" style={imageStyle} />
                </a>
              )}
              {(filterFinalItem?.[0]?.url || filterItem?.[0]?.url) && (
                  <img
                    src={greentick}
                    alt="greenTick"
                    style={greenImageStyle}
                  />
                //   <div className="rvsd_dwnld_outr d-flex">
                //  <div className="rvsd_dwnld mr-2">Reconsidering document</div>
                //   <img
                //     src={greentick}
                //     alt="greenTick"
                //     style={greenImageStyle}
                //   />
                // </div>
                )}
            </div>
          </div>
        )}
         {/* {filterFinalItem?.[0]?.url &&
                filterFinalItem?.[0].url?.includes("agent") && (
                  <div className="rvsd_dwnld_outr d-flex">
                 <div className="rvsd_dwnld mr-2">Reconsidering document</div>
                 <div className="recon-btn">
                  <img
                    src={greentick}
                    alt="greenTick"
                    style={greenImageStyle}
                  />
                </div>
                </div>
                )} */}

      {/* {counterOfferRequirement?.counterOfferConsentAction ===
        "RECONSIDER_COUNTER_OFFER" && (filterItem?.[0]?.url &&
          filterItem?.[0].url?.includes('agent') &&
           counterOfferRequirement?.counterOfferActionBy === "AGENT" &&
          revisedDetail &&
          revisedDetail[0].actual_status === "IN-PROGRESS") && (

        <div className="rvsd_dwnld_outr d-flex">
          <div className="rvsd_dwnld mr-2">Reconsidering document</div>
           
            <div className="recon-btn">
            <img src={greentick} alt="greenTick" style={greenImageStyle} /> 
            </div>
          
        </div>
      )} */}

      {/* View Reconsider doc   */}
      {preview && preview.url && (
        <PopUp heading={preview?.data?.indexValue} onClose={closeHandler}>
          <div className="img-thumbnail-view">
            {preview?.url?.indexOf(".pdf") < 0 ? (
              <img src={preview?.url} alt="img" height="300" width="300" />
            ) : (
              <button
                className="open-pdf-btn"
                onClick={() => {
                  //const downloadFile = (blob, fileName) => {
                  // const blob = new Blob(
                  //     this.state.previewImg,
                  //     {
                  //         type: "application/pdf",
                  //     }
                  // );
                  if (
                    typeof window !== "undefined" &&
                    typeof document !== "undefined"
                  ) {
                    const fileName = "myDocument.pdf";
                    const link = document?.createElement("a");
                    // create a blobURI pointing to our Blob
                    link.href = preview.url; //URL.createObjectURL(previewList[currentImgIndex]?.src);
                    link.download = fileName;
                    // some browser needs the anchor to be in the doc
                    if (document?.body) {
                      document.body.append(link);
                    } else {
                      console.error("document.body is not available");
                    }
                    link.click();
                    link.remove();
                    // in case the Blob uses a lot of memory
                    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                    // };
                    // window.open(
                    //   window.URL.createObjectURL(new Blob(previewList[currentImgIndex]?.src, {type: "pdf"})),
                    //  // window.URL.createObjectURL(previewList[currentImgIndex]?.src),
                    //   "_blank"
                    // )
                  }
                }}
              >
                {/* <img src={plaholderPdf} width="200" alt="pdf placeholder" /> */}
              </button>
            )}
          </div>
        </PopUp>
      )}

      {/* Delete Reconsider doc  */}
      {showDeletePopup && (
        <DeletePopUpPage
          onClose={() => setShowDeletePopup(false)}
          deleteHandler={() => deleteDocHandler(filterItem?.[0])}
        />
      )}
 <div className="card-body">
      {/* Options */}
      {counterOfferRequirement?.counterOfferConsentSubmitted !== true &&
      (counterOfferRequirement?.counterOfferCount === 0 ||
        counterOfferRequirement?.counterOfferCount === null) ? (
        <>
          <div className="mb-2 rvsd-conatiner">
            <div className="rvsd_blk">
              <div className="list">
                <input
                  type="radio"
                  value="Accept the revised offer"
                  name="counterReason"
                  onChange={(e) => changeHandler(e.target.name, e.target.value)}
                  checked={
                    inputValue.counterReason === "Accept the revised offer" ||
                    counterOfferRequirement?.counterOfferConsentAction ===
                      "ACCEPTED"
                  }
                />
              </div>
              <div
                className={`label ${
                  counterOfferRequirement?.counterOfferConsentAction &&
                  counterOfferRequirement?.counterOfferConsentAction !==
                    "ACCEPTED" &&
                  "gray-label"
                }`}
              >
                Accept the counter offer
              </div>
              <div className="tooltipbx">
                <img
                  onClick={() => {
                    showToolText(1);
                  }}
                  src={questionMark}
                  width="14"
                  height="14"
                  alt=""
                />{" "}
              </div>
            </div>
            {/* <div className="rvsd_blk-tooltip">
              <span className="tooltipbx">
                <Image src={questionMark} width="14" height="14" alt="" />
                <span className="tooltiptext">
                  Check the counter offer letter and revised benefit
                  illustration for your revised offer and if you are ok then
                  please select this option to provide consent via OTP
                </span>
              </span>
            </div> */}
            {selectedImage === 1 && (
              <PopUp
                onClose={() => showToolText(null)}
                heading={""}
                // showStatus={props.showStatus}
                addCss={"toolText-popup"}
                showOnDesktop={true}
              >
                Check the counter offer letter and revised benefit illustration
                for your revised offer and if you are ok then please select this
                option to provide consent via OTP
              </PopUp>
            )}
          </div>
          <div className="mb-2 rvsd-conatiner">
            <div className="rvsd_blk">
              <div className="list">
                <input
                  type="radio"
                  value="Adjust the Sum Assured to match Existing Premium"
                  name="counterReason"
                  onChange={(e) => changeHandler(e.target.name, e.target.value)}
                  checked={
                    inputValue.counterReason ===
                      "Adjust the Sum Assured to match Existing Premium" ||
                    counterOfferRequirement?.counterOfferConsentAction ===
                      "ADJUST_SUM_ASSURED"
                  }
                />
              </div>
              <div
                className={`label ${
                  counterOfferRequirement?.counterOfferConsentAction &&
                  counterOfferRequirement?.counterOfferConsentAction !==
                    "ADJUST_SUM_ASSURED" &&
                  "gray-label"
                }`}
              >
                Adjust the Sum Assured to match Existing Premium
              </div>
              <div className="tooltipbx">
                <img
                  onClick={() => showToolText(2)}
                  src={questionMark}
                  width="14"
                  height="14"
                  alt=""
                />
              </div>
            </div>
            {/* <div className="rvsd_blk-tooltip">
              <span className="tooltipbx">
                <Image src={questionMark} width="14" height="14" alt="" />
                <span className="tooltiptext">
                  Select this option and provide OTP consent if you want to
                  adjust your insurance cover and keep the premium same as what
                  you have already paid.
                </span>
              </span>
            </div> */}
            {selectedImage === 2 && (
              <PopUp
                onClose={() => showToolText(null)}
                heading={""}
                // showStatus={props.showStatus}
                addCss={"toolText-popup"}
                showOnDesktop={true}
              >
                Select this option and provide OTP consent if you want to adjust
                your insurance cover and keep the premium same as what you have
                already paid.
              </PopUp>
            )}
          </div>
          <div className="mb-2 rvsd-conatiner">
            <div className="rvsd_blk">
              <div className="list display_flex">
                <input
                  type="radio"
                  value="Decline the revised offer"
                  name="counterReason"
                  onChange={(e) => changeHandler(e.target.name, e.target.value)}
                  checked={
                    inputValue.counterReason === "Decline the revised offer" ||
                    counterOfferRequirement?.counterOfferConsentAction ===
                      "REJECTED"
                  }
                />
              </div>
              <div
                className={`label ${
                  counterOfferRequirement?.counterOfferConsentAction &&
                  counterOfferRequirement?.counterOfferConsentAction !==
                    "REJECTED" &&
                  "gray-label"
                }`}
              >
                Decline the counter offer
              </div>
              <div className="tooltipbx">
                <img
                  onClick={() => showToolText(3)}
                  src={questionMark}
                  width="14"
                  height="14"
                  alt=""
                />
              </div>
            </div>
            {/* <div className="rvsd_blk-tooltip">
              <span className="tooltipbx">
                <Image src={questionMark} width="14" height="14" alt="" />
                <span className="tooltiptext">
                  If you are not ok with the revised offer as either you are no
                  more interested to buy this plan or you want to request for a
                  reconsideration (proof required for the reason for
                  reconsideration). OTP consent is required
                </span>
              </span>
            </div> */}
            {selectedImage === 3 && (
              <PopUp
                onClose={() => showToolText(null)}
                heading={""}
                // showStatus={props.showStatus}
                addCss={"toolText-popup"}
                showOnDesktop={true}
              >
                Select this option if you do not want to accept the counter
                offer.
              </PopUp>
            )}
          </div>
          <div className="mb-2 rvsd-conatiner">
            <div className="rvsd_blk">
              <div className="list display_flex">
                <input
                  type="radio"
                  value="Request for reconsidering the counter offer"
                  name="counterReason"
                  onChange={(e) => changeHandler(e.target.name, e.target.value)}
                  checked={
                    inputValue.counterReason ===
                      "Request for reconsidering the counter offer" ||
                    counterOfferRequirement?.counterOfferConsentAction ===
                      "RECONSIDER_COUNTER_OFFER"
                  }
                />
              </div>
              <div
                className={`label ${
                  counterOfferRequirement?.counterOfferConsentAction &&
                  counterOfferRequirement?.counterOfferConsentAction !==
                    "RECONSIDER_COUNTER_OFFER" &&
                  "gray-label"
                }`}
              >
                Request for reconsidering the counter offer
              </div>
              <div className="tooltipbx">
                <img
                  src={questionMark}
                  onClick={() => showToolText(4)}
                  width="14"
                  height="14"
                  alt=""
                />
              </div>
            </div>
            {/* <div className="rvsd_blk-tooltip">
              <span className="tooltipbx">
                <Image src={questionMark} width="14" height="14" alt="" />
                <span className="tooltiptext">
                  Request for reconsidering the counter offer
                </span>
              </span>
            </div> */}
            {selectedImage === 4 && (
              <PopUp
                onClose={() => showToolText(null)}
                heading={""}
                // showStatus={props.showStatus}
                addCss={"toolText-popup"}
                showOnDesktop={true}
              >
                Select this option if you do not want to accept the counter
                offer and want to submit for re-evaluation.
              </PopUp>
            )}
          </div>
        </>
      ) : counterOfferRequirement?.counterOfferCount === 1 &&
        // (counterOfferRequirement?.counterOfferConsentType === "ADJUST_SUM_ASSURED" ||  //commenting this code acc to new req
        //   counterOfferRequirement?.counterOfferConsentType === "RECONSIDER_COUNTER_OFFER") && //commenting this code acc to new req
        revisedDetail[0].actual_status === "IN-PROGRESS" ? (
        <div>
          <div className="mb-2 rvsd-conatiner">
            <div className="rvsd_blk">
              <div className="list">
                <input
                  type="radio"
                  value="Accept the revised offer"
                  name="counterReason"
                  onChange={(e) => changeHandler(e.target.name, e.target.value)}
                  checked={
                    inputValue.counterReason === "Accept the revised offer" ||
                    counterOfferRequirement?.counterOfferConsentAction ===
                      "ACCEPTED"
                  }
                />
              </div>
              <div
                className={`label ${
                  counterOfferRequirement?.counterOfferConsentAction &&
                  counterOfferRequirement?.counterOfferConsentAction !==
                    "ACCEPTED" &&
                  "gray-label"
                }`}
              >
                Accept the counter offer
              </div>
              <div className="tooltipbx">
                <img
                  onClick={() => {
                    showToolText(1);
                  }}
                  src={questionMark}
                  width="14"
                  height="14"
                  alt=""
                />{" "}
              </div>
            </div>
            {/* <div className="rvsd_blk-tooltip">
              <span className="tooltipbx">
                <Image src={questionMark} width="14" height="14" alt="" />
                <span className="tooltiptext">
                  Check the counter offer letter and revised benefit
                  illustration for your revised offer and if you are ok then
                  please select this option to provide consent via OTP
                </span>
              </span>
            </div> */}
            {selectedImage === 1 && (
              <PopUp
                onClose={() => showToolText(null)}
                heading={""}
                // showStatus={props.showStatus}
                addCss={"toolText-popup"}
                showOnDesktop={true}
              >
                Check the counter offer letter and revised benefit illustration
                for your revised offer and if you are ok then please select this
                option to provide consent via OTP
              </PopUp>
            )}
          </div>
          <div className="mb-2 rvsd-conatiner">
            <div className="rvsd_blk">
              <div className="list display_flex">
                <input
                  type="radio"
                  value="Decline the revised offer"
                  name="counterReason"
                  onChange={(e) => changeHandler(e.target.name, e.target.value)}
                  checked={
                    inputValue.counterReason === "Decline the revised offer" ||
                    counterOfferRequirement?.counterOfferConsentAction ===
                      "REJECTED"
                  }
                />
              </div>
              <div
                className={`label ${
                  counterOfferRequirement?.counterOfferConsentAction &&
                  counterOfferRequirement?.counterOfferConsentAction !==
                    "REJECTED" &&
                  "gray-label"
                }`}
              >
                Decline the counter offer
              </div>
              <div className="tooltipbx">
                <img
                  onClick={() => showToolText(2)}
                  src={questionMark}
                  width="14"
                  height="14"
                  alt=""
                />
              </div>
            </div>
            {/* <div className="rvsd_blk-tooltip">
              <span className="tooltipbx">
                <Image src={questionMark} width="14" height="14" alt="" />
                <span className="tooltiptext">
                  If you are not ok with the revised offer as either you are no
                  more interested to buy this plan or you want to request for a
                  reconsideration (proof required for the reason for
                  reconsideration). OTP consent is required
                </span>
              </span>
            </div> */}
            {selectedImage === 2 && (
              <PopUp
                onClose={() => showToolText(null)}
                heading={""}
                // showStatus={props.showStatus}
                addCss={"toolText-popup"}
                showOnDesktop={true}
              >
                Select this option if you do not want to accept the counter
                offer.
              </PopUp>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {showUploadModal && (
        <UploadDocModal
          heading={`Upload`}
          // subheading={'uploadModalHeading'}
          onClose={() => {
            setShowUploadModal(false);
            setCounterUpload(false);
            setRevisedUpload(false);
          }}
          label={showLabel}
          uploadDocHandler={uploadDocHandler}
          proposalNo={proposalBasicDetails?.proposalNumber}
        />
      )}

      {showConsent &&
        inputValue.counterReason !==
          "Request for reconsidering the counter offer" && (
          <PopUp
            onClose={() => {
              closeConsentHandler();
              setInputValue("");
            }}
            addCss={"counter-consent"}
          >
            <div className="counter-consent">
              <div className="counter-text">
                Once you select a option, it cannot be changed. Please ensure
                your choice is final before proceeding.
                <br />
                <br />
                You are required to upload below documents
                <div className="ul-container">
                  <ul className="ul-item">
                    <li>Counter Offer Letter.</li>
                    <li>Revised Benefit Illustration. </li>
                    <li>Revised Customer Information Sheet. </li>
                  </ul>
                </div>
                <br />
                Documents cannot be previewed or deleted after upload. Make sure
                to review your documents carefully before uploading.
              </div>
              <div className="d-flex counter-btns">
                <div
                  className="btn"
                  onClick={() => {
                    setShowConsent(false);
                    setInputValue("");
                  }}
                >
                  CANCEL
                </div>
                <div className="btn" onClick={consentSubmitHandler}>
                  OK
                </div>
              </div>
            </div>
          </PopUp>
        )}
      {showConsent &&
        inputValue.counterReason ===
          "Request for reconsidering the counter offer" && (
          <PopUp
            onClose={() => {
              closeConsentHandler();
              setInputValue("");
            }}
            addCss={"counter-consent"}
          >
            <div className="counter-consent">
              <div className="counter-text">
                Once you select a option, it cannot be changed. Please ensure
                your choice is final before proceeding.
                <br />
                <br />
                You are required to upload below documents
                <div className="ul-container">
                  <ul className="ul-item">
                    <li>Counter Offer Letter.</li>
                    <li>Revised Benefit Illustration. </li>
                    <li>Revised Customer Information Sheet. </li>
                    <li>Document for Reconsidering Counter Offer.</li>
                  </ul>
                </div>
                <br />
                Documents cannot be previewed or deleted after upload. Make sure
                to review your documents carefully before uploading.
              </div>
              <div className="d-flex counter-btns">
                <div
                  className="btn"
                  onClick={() => {
                    setShowConsent(false);
                    setInputValue("");
                  }}
                >
                  CANCEL
                </div>
                <div className="btn" onClick={consentSubmitHandler}>
                  OK
                </div>
              </div>
            </div>
          </PopUp>
        )}
    </div>
    </div>
  );
};

export default CounterOffer;
