// applicationData

export const applicationData = [
    {
        id: 1,
        heading: 'Quote Generated',
        title:'QUOTE',
        subHeading: 'Completed: 26 JUNE, 2023 | 09:06 PM',
        completed: false,
        dummyContent: 'Content here',
        contentStatus: false,
        content: [
            {
                id: 1,
                heading: '',
                subHeading: '',
                img: '',
                dummyContent: '',
                contentStatus: false,
                subContent: []
            },
        ]
    },
    {
        id: 2,
        heading: 'Form Filling',
        title:'FORM_FILLING',
        subHeading: 'Yet to start',
        completed: false,
        dummyContent: '',
        contentStatus: true,
        content: [
            {
                id: 1,
                heading: 'Proposal Form',
                title:'PROPOSAL',
                subHeading: 'Yet to start',
                dummyContent: '',
                contentStatus: true,
                subContent: [
                    {
                        id: 1,
                        title: 'Basic detail Section',
                        subTitle: 'Personal_Details'
                    },
                    {
                        id: 2,
                        title: 'Insured detail Section',
                        subTitle: 'Insured_Proposer_Details'
                    },
                    {
                        id: 3,
                        title: 'Nominee detail Section',
                        subTitle: 'Nominee_Details'
                    },
                    {
                        id: 4,
                        title: 'Health Questionnaires section',
                        subTitle: 'Health_Details'
                    },
                ]
            },
            {
                id: 2,
                heading: 'Insta Verify',
                title:'INSTA_VERIFY_STATUS',
                subHeading: 'Yet to start',
                dummyContent: 'Dummy Content',
                contentStatus: false,
                subContent: []
            },
            {
                id: 3,
                heading: 'Customer Consent',
                title:'CUSTOMER_CONSENT_STATUS',
                subHeading: 'Yet to start',
                dummyContent: '3',
                contentStatus: false,
                subContent: []
            },
            {
                id: 4,
                heading: 'Payment',
                title:'PAYMENT',
                subHeading: 'Yet to start',
                dummyContent: '3',
                contentStatus: false,
                subContent: []
            },
            {
                id: 5,
                heading: 'Document Upload',
                title:'DOC_UPLOAD',
                subHeading: 'Yet to start',
                dummyContent: '3',
                contentStatus: false,
                subContent: []
            },
            {
                id: 6,
                heading: 'Proposal Submission',
                title:'PROPOSAL_SUBMISSION',
                subHeading: 'Yet to start',
                dummyContent: '3',
                contentStatus: false,
                subContent: []
            },
        ]
    },
    {
        id: 3,
        heading: 'Medical Requirement',
        title:'MEDICAL_REQUIREMENT',
        subHeading: 'Yet to start',
        completed: false,
        dummyContent: 'Dummy Content',
        contentStatus: false,
        content: [
            {
                id: 1,
                heading: '',
                subHeading: '',
                img: '',
                dummyContent: '',
                contentStatus: false,
                subContent: []
            },
        ]
    },
    {
        id: 4,
        heading: 'Additional Non-Medical Requirements',
        title:'ADDITIONAL_NON_MEDICAL_REQUIREMENTS',
        subHeading: 'Yet to start',
        completed: false,
        dummyContent: 'Dummy Content',
        contentStatus: true,
        content: [
            {
                id: 1,
                heading: '',
                subHeading: '',
                img: '',
                dummyContent: '',
                contentStatus: false,
                subContent: []
            },
        ]
    },
    {
        id: 5,
        heading: 'Consent For Change In The Application Details',
        title:'QUALITY_CHECK',
        subHeading: 'Yet to start',
        completed: false,
        dummyContent: 'Dummy Content',
        contentStatus: false,
        content: [
            {
                id: 1,
                heading: '',
                subHeading: '',
                img: '',
                dummyContent: '',
                contentStatus: false,
                subContent: []
            },
        ]
    },
    {
        id: 6,
        heading: 'Revised Offer',
        title:'REVISED_OFFER',
        subHeading: 'Yet to start',
        completed: false,
        dummyContent: 'Dummy Content',
        contentStatus: false,
        content: [
            {
                id: 1,
                heading: '',
                subHeading: '',
                img: '',
                dummyContent: '',
                contentStatus: false,
                subContent: []
            },
        ]
    },
    
    {
        id: 7,
        heading: 'Payment Required',
        title: 'PAYMENT_REQUIREMENT',
        subHeading: 'Yet to start',
        completed: false,
        dummyContent: 'Dummy Content',
        contentStatus: false,
        content: [
            {
                id: 1,
                heading: '',
                subHeading: '',
                img: '',
                dummyContent: '',
                contentStatus: false,
                subContent: []
            },
        ]
    },
    {
        id: 8,
        heading: 'Quality Check',
        title:'QUALITY_CHECK',
        subHeading: 'Yet to start',
        completed: false,
        dummyContent: 'Dummy Content',
        contentStatus: false,
        content: [
            {
                id: 1,
                heading: '',
                subHeading: '',
                img: '',
                dummyContent: '',
                contentStatus: false,
                subContent: []
            },
        ]
    },
   
    {
        id: 9,
        heading: 'Policy Decision',
        title:'POLICY_STATUS',
        subHeading: 'Yet to start',
        completed: false,
        dummyContent: 'Dummy Content',
        contentStatus: false,
        content: [
            {
                id: 1,
                heading: '',
                subHeading: '',
                img: '',
                dummyContent: '',
                contentStatus: false,
                subContent: []
            },
        ]
    },
    // {
    //     id: 9,
    //     heading: 'Medical Risk Verification',
    //     title:'MEDICAL_RISK_VERIFICATION',
    //     subHeading: 'Yet to start',
    //     completed: false,
    //     dummyContent: 'Dummy Content',
    //     contentStatus: false,
    //     content: [
    //         {
    //             id: 1,
    //             heading: '',
    //             subHeading: '',
    //             img: '',
    //             dummyContent: '',
    //             contentStatus: false,
    //             subContent: []
    //         },
    //     ]
    // },
    // {
    //     id: 10,
    //     heading: 'Financial and Medical Risk Verification',
    //     title:'FINANCIAL_AND_MEDICAL_RISK_VERIFICATION',
    //     subHeading: 'Yet to start',
    //     completed: false,
    //     dummyContent: 'Dummy Content',
    //     contentStatus: false,
    //     content: [
    //         {
    //             id: 1,
    //             heading: '',
    //             subHeading: '',
    //             img: '',
    //             dummyContent: '',
    //             contentStatus: false,
    //             subContent: []
    //         },
    //     ]
    // },
]

// status api data

export const statusApi ={
    "proposalBasicDetails": {
  "proposalId": 5054611,
  "customerId": null,
  "quoteId": 5054613,
  "quoteRefNumber": "70000765328",
  "customerName": "Pratik Choudhary",
  "firstName": "Pratik",
  "middleName": "",
  "lastName": "Choudhary",
  "planName": "Bharti AXA Life Elite Advantage",
  "sumAssured": 234627,
  "proposalNumber": "3205508312",
  "premium": 8233.07,
  "journeyStatus": "UNDERWRITING_SUBMITTED",
  "agentCode": null,
  "emailId": "pratik.desai1.ext@bhartiaxa.com",
  "mobileNo": "7053872246",
  "dateOfBirth": "12-12-1991",
  "channelName": "115",
  "subChannelName": "115",
  "sourceName": "MSell",
  "policyFor": "SELF",
  "quotationRefNum": "70000765328",
  "policyNumber": "508-8920615",
  "overallUWDecisionCd": null,
  "uniqueProposalId": "bVhYQVY1VGQ5KzZtT1lUOWNiYXNkdz09",
  "workFlowStage": null,
  "pasaCase": false,
  "premiumPaymentTerm": 7,
  "policyTerm": 12,
  "issuanceType": "NEWGEN",
  "customerConsentRequired": false,
  "agentConsentRequired": false,
  "instaRequired": true,
  "proposerName": "Test",
  "insuredName": "Pratik",
  "caseType": "NON_FINCARE",
  "nonFinCareCase": null,
  "jointLife": "NO",
  "premiumPaymentMode": "QUARTERLY",
  "requiredDocuments": {
    "list": [
      {
        "name": "OWNER",
        "personName": "Pratik Choudhary",
        "documentList": [
          {
            "mandatoryDoc": "1",
            "category": "Age Proof",
            "indexValue": "Age Proof",
            "documents": {
              "Age Proof": [
                "Pan Card"
              ]
            }
          },
          {
            "mandatoryDoc": "1",
            "category": "Income proof",
            "indexValue": "Financials-Others",
            "documents": {
              "Income proof": [
                "Recent Salary Slips of last 3 months",
                "Form 16 of last 2 years",
                "ITRs of last 3 assessment years"
              ]
            }
          }
        ]
      }
    ]
  },
  "documentDetails": {
    "OWNER": {
      "Age Proof": [
        {
          "type": "Pan Card",
          "docs": [
            {
              "url": "owner_pancard_ageproof_1715937724745_front_side.png",
              "side": "FRONT_SIDE",
              "indexValue": "Age Proof"
            }
          ],
          "indexValue": "Age Proof",
          "locked": false
        }
      ],
      "Income proof": [
        {
          "type": "ITRs of last 3 assessment years",
          "docs": [
            {
              "url": "owner_itrsoflast3assessmentyears_incomeproof_1715937731700_front_side.png",
              "side": "FRONT_SIDE",
              "indexValue": "Financials-Others"
            }
          ],
          "indexValue": "Financials-Others",
          "locked": false
        }
      ]
    }
  },
  "annuityDetails": {
    "annuityVariant": "",
    "pptDefermentOption": "",
    "saveTheDate": "",
    "sourceOfPremium": ""
  },
  "consentDetail": {
    "whatsAppOpt": false,
    "whatsAppCommunicationAgree": false,
    "mobileCommunicationAgree": true,
    "ecdfFormAgree": false,
    "policyDocumentOnEmailAgree": false,
    "digiLockerAgree": false,
    "proposalNumber": null,
    "proposalFormLink": null,
    "cdfFormLink": null,
    "policyDocumentLink": null,
    "accepted": null,
    "rejectedReason": null,
    "fincareConsentLink": null,
    "customerConsentLink": null,
    "agentConsentLink": null,
    "agentConsent": false,
    "tpaConsentLink": null,
    "refNumber": null,
    "verificationDate": null,
    "otp": null,
    "verified": null,
    "otpTimeStamp": null,
    "insta2ConsentSent": true,
    "insta2MessageSent": true,
    "customerConsentRequired": false,
    "agentConsentRequired": false,
    "customerConsentRejectedReason": null,
    "customerConsentRejected": false,
    "ckycConsent": false,
    "ckycConsentLink": null
  },
  "instaDetails": null
    },
    "paymentReceipts": [
  "payment_reciept_U1230002081629.pdf",
  "payment_reciept_U1230002147309.pdf"
    ],
    "paymentDetails": [
  {
    "txReferenceNo": "U1230002081629",
    "proposalNumber": "3205508312",
    "txAmount": 8233.07,
    "counterOfferPayment": false,
    "paymentReceipt": "payment_reciept_U1230002081629.pdf"
  },
  {
    "txReferenceNo": "U1230002147309",
    "proposalNumber": "3205508312",
    "txAmount": 22068.93,
    "counterOfferPayment": true,
    "paymentReceipt": "payment_reciept_U1230002147309.pdf"
  }
    ],
    "additionalInfoDocs": {
  "uwId": 145244,
  "proposerDocumentDetail": null,
  "primaryInsuredDocumentDetail": {
    "guid": null,
    "id": null,
    "quesList": [
      {
        "id": 10,
        "documentCdValue": "Chest Pain Questionnaire",
        "documentCd": "CPQ",
        "documentCategoryCd": "MQ",
        "documentCategoryTypeCd": "QUES",
        "partyType": "INSURER",
        "documentSide": null,
        "url": "",
        "questionnaireFileName": "Chest_Pain_Questionnaire.pdf"
      }
    ],
    "quesDataList": [
      {
        "id": 10,
        "data": []
      }
    ],
    "medicalScheduling": false,
    "ServiceDocumentList": [
      {
        "id": 1,
        "proposalNumber": "3205508312",
        "partyType": "INSURER",
        "docCategoryTypeCd": "DOC",
        "docCategoryCd": "PD",
        "documentCd": "PIC",
        "docCategoryTypeCdValue": null,
        "docCategoryCdValue": null,
        "documentCdValue": "Recent Colour Photograph",
        "mandatoryDoc": null,
        "indexValue": "Documents",
        "url": "",
        "side": null,
        "documentNumber": null,
        "isAutoGeneratedFl": null,
        "documentMovedStatus": false,
        "documentMovedToNewgenStatus": false,
        "questionnaire": false
      },
      {
        "id": 2,
        "proposalNumber": "3205508312",
        "partyType": "INSURER",
        "docCategoryTypeCd": "DOC",
        "docCategoryCd": "PD",
        "documentCd": "PIC",
        "docCategoryTypeCdValue": null,
        "docCategoryCdValue": null,
        "documentCdValue": "Recent Colour Photograph",
        "mandatoryDoc": null,
        "indexValue": "Documents",
        "url": "",
        "side": null,
        "documentNumber": null,
        "isAutoGeneratedFl": null,
        "documentMovedStatus": false,
        "documentMovedToNewgenStatus": false,
        "questionnaire": false
      }
    ]
  },
  "jointLifeDocumentDetail": null
    },
    "additionalQuestionnaireListDTO": {
  "proposerAdditionalQuestionnaireDocs": [],
  "insuredAdditionalQuestionnaireDocs": [],
  "jointAdditionalQuestionnaireDocs": []
    },
    "counterOfferRequirement": {
  "counterOfferDetails": {
    "id": null,
    "smoker": "NO",
    "scenario": "ALL",
    "loadingType": "EXTRA_LOADING",
    "riderDetails": [
      {
        "mode": "",
        "status": "",
        "initialSA": "",
        "partyRole": "",
        "revisedSA": null,
        "riderName": "",
        "adjustedSA": "",
        "monthlyCoi": "",
        "modelPremium": null,
        "premiumAmount": null,
        "revisedPremium": null,
        "specialLoading": "",
        "ocupationLoading": "",
        "residentialLoading": "",
        "premiumAmountWithGst": null
      }
    ],
    "basePlanDetails": null,
    "shortfallPremium": 22068.93,
    "totalPermiumPaid": 8233.07,
    "counterOfferReason": "",
    "totalPremiumPayable": 30302
  },
  "counterOfferPaymentReceipts": [
    "payment_reciept_U1230002147309.pdf"
  ],
  "counterOfferPaymentCompleted": true,
  "counterOfferConsentType": "ACCEPTED",
  "counterOfferConsentAction": "true",
  "counterOfferCount": 3
    },
    "paymentRequirement": {
  "shortFallAmount": [
    {
      "statusCode": "CD",
      "paymentStatus": "pending",
      "amount": "100"
    },
    {
      "statusCode": "FT",
      "paymentStatus": "pending",
      "amount": "200"
    },
    {
      "statusCode": "DC",
      "paymentStatus": "pending",
      "amount": "200"
    },
    {
      "statusCode": "CO",
      "paymentStatus": "pending",
      "amount": "300"
    }
  ]
    },
    "dataChangeRequirment": {},
    "newgenStatusResponseDTOList": [
  {
    "id": 5054615,
    "status": "PROPOSAL",
    "subStatus": "Personal_Details",
    "actual_status": "COMPLETED",
    "updatedOn": "2024-05-17 09:20:00",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054629,
    "status": "DATA_CHANGE",
    "subStatus": "",
    "actual_status": "CREATED",
    "updatedOn": "2024-05-17 09:19:06",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054627,
    "status": "FINANCIAL_AND_MEDICAL_RISK_VERIFICATION",
    "subStatus": "RA",
    "actual_status": "IN-PROGRESS",
    "updatedOn": "2024-05-17 11:07:39",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054628,
    "status": "POLICY_STATUS",
    "subStatus": "NM",
    "actual_status": "CREATED",
    "updatedOn": "2024-05-17 12:25:49",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054614,
    "status": "QUOTE",
    "subStatus": "QUOTE",
    "actual_status": "COMPLETED",
    "updatedOn": "2024-05-17 09:19:06",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054620,
    "status": "MEDICAL_REQUIREMENT",
    "subStatus": "",
    "actual_status": "CREATED",
    "updatedOn": "2024-05-17 09:19:06",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054626,
    "status": "MEDICAL_RISK_VERIFICATION",
    "subStatus": "",
    "actual_status": "CREATED",
    "updatedOn": "2024-05-17 09:19:06",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054616,
    "status": "PROPOSAL",
    "subStatus": "Nominee_Details",
    "actual_status": "COMPLETED",
    "updatedOn": "2024-05-17 09:20:08",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054617,
    "status": "PROPOSAL",
    "subStatus": "Health_Details",
    "actual_status": "COMPLETED",
    "updatedOn": "2024-05-17 09:20:24",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054623,
    "status": "PAYMENT",
    "subStatus": "PAYMENT",
    "actual_status": "COMPLETED",
    "updatedOn": "2024-05-17 09:21:38",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": {
      "counterOfferPaymentCompleted": true,
      "paymentAmountCompleted": true,
      "paymentRenewal": true,
      "enachCompleted": true,
      "paymentOtpCompleted": true
    }
  },
  {
    "id": 5054618,
    "status": "PROPOSAL",
    "subStatus": "Document_Details",
    "actual_status": "COMPLETED",
    "updatedOn": "2024-05-17 09:22:20",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054621,
    "status": "ADDITIONAL_NON_MEDICAL_REQUIREMENTS",
    "subStatus": "NP",
    "actual_status": "IN-PROGRESS",
    "updatedOn": "2024-05-17 09:24:48",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054625,
    "status": "QUALITY_CHECK",
    "subStatus": "UD",
    "actual_status": "COMPLETED",
    "updatedOn": "2024-05-17 11:07:39",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054619,
    "status": "PROPOSAL_SUBMISSION",
    "subStatus": "PROPOSAL_SUBMISSION",
    "actual_status": "COMPLETED",
    "updatedOn": "2024-07-11 09:03:50",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054624,
    "status": "PAYMENT_REQUIREMENT",
    "subStatus": "SP",
    "actual_status": "COMPLETED",
    "updatedOn": "2024-07-10 11:40:44",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {},
    "paymentInfo": null
  },
  {
    "id": 5054622,
    "status": "REVISED_OFFER",
    "subStatus": "CO",
    "actual_status": "COMPLETED",
    "updatedOn": "2024-07-10 11:40:44",
    "createdOn": "2024-05-17 09:19:06",
    "additionalInfo": {
      "id": null,
      "smoker": "NO",
      "scenario": "ALL",
      "loadingType": "EXTRA_LOADING",
      "riderDetails": [
        {
          "mode": "",
          "status": "",
          "initialSA": "",
          "partyRole": "",
          "revisedSA": null,
          "riderName": "",
          "adjustedSA": "",
          "monthlyCoi": "",
          "modelPremium": null,
          "premiumAmount": null,
          "revisedPremium": null,
          "specialLoading": "",
          "ocupationLoading": "",
          "residentialLoading": "",
          "premiumAmountWithGst": null
        }
      ],
      "basePlanDetails": null,
      "shortfallPremium": 22068.93,
      "totalPermiumPaid": 8233.07,
      "counterOfferReason": "",
      "totalPremiumPayable": 30302
    },
    "paymentInfo": {
      "counterOfferPaymentCompleted": true,
      "paymentAmountCompleted": true,
      "paymentRenewal": true,
      "enachCompleted": true,
      "paymentOtpCompleted": true
    }
  }
    ],
    "policyDocuments": {
  "BI_TAG_NAME": "70000765328.pdf",
  "CDF_TAG_NAME": "3205508312CDF.pdf",
  "FNA_TAG_NAME": "3205508312FNA.pdf",
  "PDF_TAG_NAME": "3205508312_PF.pdf",
  "COVID_TAG_NAME_2": "3205508312InsuredCovid19.pdf",
  "NEW_ACR_TAG_NAME": "508-8920615_ACR.pdf",
  "omni_moved_status": "false",
  "NEW_COVID_TAG_NAME_2": "3205508312covid_19.pdf",
  "AGENT_CONFIDENTIAL_REPORT": "AgentConfidentialReport.pdf",
  "REVISED_OFFER_DOC": "COUNTER_OFFER_3205508312.pdf",
  "REVISED_BI_DOC": " ",
  "Decision Letter": "DECLINE LETTER.pdf"
    },
    "tpaRequirementDetails": {
            "ownerList": [
              {
                "tpaId": 5380283,
                "tpaLink": "https://uat.welnext.com/capt/C8yJHG",
                "tpaStatus": null,
                "tpaTestCode": [
                    "CAT9"
                ],
                "tpaRegistrationCode": "WX314195",
                "description": [
                    "FMR with RUA,FBS,LIPIDS,ECG"
                ],
                "date": "2024-08-29 11:42"
            },
                {
                    "tpaId": 5380241,
                    "tpaLink": "https://uat.welnext.com/capt/1zHVbk",
                    "tpaStatus": "MDRA",
                    "tpaTestCode": [
                        "CAT8"
                    ],
                    "tpaRegistrationCode": "WX314192",
                    "description": [
                        "FMR with RUA,ECG,RBS,HbA1c,TMT,ECHO,URIC ACID"
                    ],
                    "date": "2024-08-30 11:06"
                },
                {
                    "tpaId": 5380283,
                    "tpaLink": "https://uat.welnext.com/capt/C8yJHG",
                    "tpaStatus": null,
                    "tpaTestCode": [
                        "CAT9A"
                    ],
                    "tpaRegistrationCode": "WX314195",
                    "description": [
                        "FMR with RUA,FBS,LIPIDS,ECG"
                    ],
                    "date": "2024-08-30 11:12"
                }
            ],
            "insuredList": [
                {
                    "tpaId": 5380282,
                    "tpaLink": "https://uat.welnext.com/capt/KRp3K0",
                    "tpaStatus": null,
                    "tpaTestCode": [
                        "CAT1"
                    ],
                    "tpaRegistrationCode": "WX314198",
                    "description": [
                        "FMR with RUA"
                    ],
                    "date": "2024-08-30 11:14"
                }
            ]
        },
    "currentSectionTab": "ADDITIONAL_NON_MEDICAL_REQUIREMENTS"
  }
// sub status value

export const subStatusList = [
  {status: "MP",
    agentView: "Book your Medical Appointment",
    customerPortal: "Book your Medical Appointment"
  },
  {status: "NM",
    agentView: "Schedule Medical. Upload document",
    customerPortal: "Schedule Medical. Upload document"
  },
  {status: "MA",
    agentView: "Application under review",
    customerPortal: "Application under review"
  },
  {status: "MR",
    agentView: "Application under review",
    customerPortal: "Application under review"
  },
  {status: "TC",
    agentView: "Application under review",
    customerPortal: "Application under review"
  },
    {
        'status': 'AS',
        'agentView': 'Pending for application Submission',
        'customerPortal': 'Pending for application Submission'

    },
    {
        'status': 'UD',
        'agentView': 'Pending for system UW decision',
        'customerPortal': 'WIP'

    },
    {
        'status': 'MP',
        'agentView': 'Awaited medicals from Customer',
        'customerPortal': 'Awaited medicals from Customer'

    },
    {
        'status': 'NP',
        'agentView': 'Awaited Non-medical requirements from Customer',
        'customerPortal': 'Awaited Non-medical requirements from Customer'

    },
    {
        'status': 'NM',
        'agentView': 'Awaited medical and Non-Medical Req from Customer',
        'customerPortal': 'Awaited medical and Non-Medical Req from Customer'

    },
    {
        'status': 'DS',
        'agentView': 'Document awaited from Sales',
        'customerPortal': 'WIP'

    },
    {
        'status': 'AR',
        'agentView': 'Additional requirement called in Branch/NB',
        'customerPortal': 'Awaited for additional requirements to receive'

    },
    {
        'status': 'AI',
        'agentView': 'Add info called in UW ',
        'customerPortal': 'Awaited for additional requirements to receive'

    },
    {
        'status': 'CO',
        'agentView': 'Pending for Counter offer acceptance ',
        'customerPortal': 'Pending for Counter offer acceptance '

    },
    {
        'status': 'SP',
        'agentView': 'Pending for Short Premium payment',
        'customerPortal': 'Pending for Short Premium payment'

    },
    {
        'status': 'DB',
        'agentView': 'Pending for Data  quality checking with Branch',
        'customerPortal': 'WIP'

    },
    {
        'status': 'PA',
        'agentView': 'Pending for payment acknowledgement at Branch',
        'customerPortal': 'WIP'

    },
    {
        'status': 'DN',
        'agentView': 'Pending for Data quality checking with NB',
        'customerPortal': 'WIP'

    },
    {
        'status': 'DC',
        'agentView': 'Pending for Data corrections/ changes in NB',
        'customerPortal': 'WIP'

    },
    {
        'status': 'RI',
        'agentView': 'Pending for RCU investigation ',
        'customerPortal': 'WIP'

    },
    {
        'status': 'MA',
        'agentView': 'Pending for Medical Appointment',
        'customerPortal': 'Pending for Medical Appointment'

    },
    {
        'status': 'MR',
        'agentView': 'Pending for Medical Reports',
        'customerPortal': 'Pending for Medical Reports'

    },
    {
        'status': 'TC',
        'agentView': 'Pending for TPA clarification on medicals done',
        'customerPortal': 'Pending for TPA clarification on medicals done'

    },
    {
        'status': 'RA',
        'agentView': 'Pending for UW Risk Assesment ',
        'customerPortal': 'Pending for UW Risk Assesment '
    },
    {
        'status': 'CF',
        'agentView': 'Pending for compliance opinion ',
        'customerPortal': 'WIP'

    },
    {
        'status': 'VC',
        'agentView': 'Pending for PIVC clearance',
        'customerPortal': 'WIP'

    },
    {
        'status': 'CP',
        'agentView': 'Pending for Local Cheque Clearance',
        'customerPortal': 'Pending for Local Cheque Clearance'

    },
    {
        'status': 'OP',
        'agentView': 'Pending for Direct Debit/NEFT clearance/Fund clearance on FT',
        'customerPortal': 'WIP'

    },
    {
        'status': 'CD',
        'agentView': 'Pending for Fresh payment on Cheque Dishonour',
        'customerPortal': 'Pending for Fresh payment on Cheque Dishonour'

    },
    {
        'status': 'WI',
        'agentView': 'Pending for final issuance in Newgen',
        'customerPortal': 'WIP'

    },
    {
        'status': 'PM',
        'agentView': 'Policy moved to RLS for final isuance',
        'customerPortal': 'Policy Approved'

    },
    {
        'status': 'PI',
        'agentView': 'Policy issued ',
        'customerPortal': 'Policy Issued'

    },
    {
        'status': 'PD',
        'agentView': 'Policy Declined ',
        'customerPortal': 'Policy declined'

    },
    {
        'status': 'PP',
        'agentView': 'Policy Postponed ',
        'customerPortal': 'policy postponed'

    },
    {
        'status': 'PR',
        'agentView': 'Policy rejected ',
        'customerPortal': 'policy rejected'

    },
    {
        'status': 'PC',
        'agentView': 'Policy cancelled ',
        'customerPortal': 'Policy in WIP for dispatch'

    },
    {
        'status': 'PW',
        'agentView': 'Policy waiting for dispatch',
        'customerPortal': 'Policy in WIP for dispatch'

    },
    {
        'status': 'PT',
        'agentView': 'Policy Dispatched to Customer',
        'customerPortal': 'Policy Dispatched'

    },
]

// consent data

export const consentDataList = {
    pincode: "Pincode",
    pep: "PEP",
    anyHealthQuestionnaire: "Any Health Questionnaire",
    residentialCountry: "Residential Country",
    ageProof: "Age Proof",
    fundDetails: "Fund Details",
    title: "Title",
    name: "Name",
    dateOfBirth: "Date of birth",
    age: "Age",
    gender: "Gender",
    planOption: "Plan Option",
    baseSumAssured: "Base Sum Assured",
    basePremiumTerm: "Base Premium Term",
    basePolicyTerm: "Base Policy Term",
    baseModalPremium: "Base Modal Premium",
    payementFrequency: "Payment Frequency",
    premiumPaymentMode: "Premium Payment Mode",
    premiumPaymentMethod: "Premium Payment Method",
    existingBhartiAxaCustomer: "Existing Bharti Axa Customer",
    employeeDiscount: "Employee Discount",
    riderName: "Rider Name",
    riderSumAssured: "Rider Sum Assured",
    riderPolicyTerm: "Rider Policy Term",
    riderPremiumTerm: "Rider Premium Term",
    topUpPremium: "Top Up Premium",
    loadingPremium: "Loading Premium",
    payoutType: "Payout Type",
    payoutFrequency: "Payout Frequency",
  };

  export const consentForChangeData = [
    {
      id: 1,
      title: "Proposer Details",
      detail: [
        {
          id: 1,
          title: "Pincode",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 2,
          title: "PFP",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 3,
          title: "Any ealth Questionnaire",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 4,
          title: "Residential Country",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 5,
          title: "Age proof",
          key: "",
          oldDetail: "",
          reviseDetail: "",
        },
        {
          id: 6,
          title: "Fund details",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 7,
          title: "Title",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 8,
          title: "Name",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 9,
          title: "Date of Birth",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 10,
          title: "Age",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 11,
          title: "Gender",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
      ],
    },
    {
      id: 2,
      title: "Insured Details",
      detail: [
        {
          id: 1,
          title: "Pincode",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 2,
          title: "PFP",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 3,
          title: "Any ealth Questionnaire",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 4,
          title: "Residential Country",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 5,
          title: "Age proof",
          key: "",
          oldDetail: "",
          reviseDetail: "",
        },
        {
          id: 6,
          title: "Fund details",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 7,
          title: "Title",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 8,
          title: "Name",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 9,
          title: "Date of Birth",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 10,
          title: "Age",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 11,
          title: "Gender",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
      ],
    },
    {
      id: 3,
      title: "Plan Details",
      detail: [
        {
          id: 1,
          title: "Plan option",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 2,
          title: "Base Sum Assured",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 3,
          title: "Base Premium Term",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 4,
          title: "Base Policy Term",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 5,
          title: "Base Modal Premium",
          key: "",
          oldDetail: "",
          reviseDetail: "",
        },
        {
          id: 6,
          title: "Payement Frequency",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 7,
          title: "Premium Payment Mode",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 8,
          title: "Premium Payment Method",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 9,
          title: "Existing Bharti Axa Customer",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 10,
          title: "Employee Discount",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 11,
          title: "Rider Name",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 12,
          title: "Rider Sum Assured",
          key: "",
          oldDetail: "",
          reviseDetail: "",
        },
        {
          id: 13,
          title: "Rider Policy Term",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 14,
          title: "Rider Premium Term",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 15,
          title: "Top Up Premium",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 16,
          title: "Loading Premium",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 17,
          title: "Payout Type",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
        {
          id: 18,
          title: "Payout Frequency",
          key: "",
          oldDetail: "",
          revisedDetail: "",
        },
      ],
    },
    // {
    //   id: 4,
    //   title: "Health Questionnaire Details",
    //   detail: [],
    // },
  ];

  export const testDescription= {
    "CAT1":[{
    id:"CAT1",
    description:"FMR with RUA"
    }],
  
    "CAT2":[{
    id:"CAT2",
    description:"FMR with RUA,FBS,LIPIDS,ECG"
    }],
  
    "CAT 3 A":[{
    id:"CAT 3 A",
    description:"FMR with RUA, RBS, LIPIDS, ECG, HbA1c"
    }],
  
    "CAT3":[{
    id:"CAT3",
    description:"FMR with RUA,ECG,FBS,LIPIDS,HbA1c"
    }],
  
    "CAT4":[{
    id:"CAT4",
    description:"FMR with RUA, FBS,LIPIDS, HbA1c,TMT"
    }],
  
    "CAT5":[{
    id:"CAT5",
    description:"FMR with RUA,BLOOD PROFILE,ECG"
    }],
  
    "CAT 6 A":[{
    id:"CAT 6 A",
    description:"FMR with RUA, BLOOD PROFILE A, ECG, HbA1c"
    }],
  
    "CAT6":[{
    id:"CAT6",
    description:"FMR with RUA, ECG,BLOOD PROFILE,HbA1c"
    }],
  
    "CAT 7 A":[{
    id:"CAT 7 A",
    description:"FMR with RUA, BLOOD PROFILE A, TMT, HbA1c"
    }],
  
    "CAT7":[{
    id:"CAT7",
    description:"FMR with RUA, TMT, BLOOD PROFILE, HbA1c"
    }],
  
    "CAT 8_A":[{
    id:"CAT 8_A",
    description:"FMR with RUA, BLOOD PROFILE A, TMT, HbA1c , PSA"
    }],
  
    "CAT8":[{
    id:"CAT8",
    description:"FMR with RUA, TMT, BLOOD PROFILE, HbA1c, PSA (PSA- Male life)"
    }],
  
    "Medical 1":[{
    id:"Medical 1",
    description:"MER, Midstream urine analysis- MSU, Fasting lipid profile, ECG,Hba1c,HIV,Complete blood count with peripheral smear, Liver function test-SGOT,SGPT,GGT, Alkaline phosphate, Total bilirubin with breakup of Direct and Indirect. Renal function test including Blood urea nitrogen and Serum creatinine"
    }],
  
    "Medical 2":[{
    id:"Medical 2",
    description:"MER, Midstream urine analysis- MSU, Fasting lipid profile, TMT,Hba1c,HIV,Complete blood count with peripheral smear, Liver function test-SGOT,SGPT,GGT, Alkaline phosphate, Total bilirubin with breakup of Direct and Indirect. Renal function test including Blood urea nitrogen and Serum creatinine."
    }],
  
    "2D-ECHO":[{
    id:"2D-ECHO",
    description:"2D-ECHO"
    }],
  
    "ALK PHOS":[{
    id:"ALK PHOS",
    description:"ALKALINE PHOSPHATASE"
    }],
  
    "ANHBS":[{
    id:"ANHBS",
    description:"Anti-HBs / HBsAb"
    }],
  
    "ANHCV":[{
    id:"ANHCV",
    description:"ANTI HCV"
    }],
  
    "BCT":[{
    id:"BCT",
    description:"BLOOD COTININE TEST"
    }],
  
    "BLD":[{
    id:"BLD",
    description:"3 Blood Pressure reading at 10 mts interval"
    }],
  
    "BLP   ( BLOOD PROFILE)":[{
    id:"BLP   ( BLOOD PROFILE)",
    description:"CBC&ESR,FBS,s.cholestrol,HDL,triglycerides,GGT,AST,ALT,alkaline phosphatase,ALBUMIN,GLOBULIN,BILIRUBIN,UREA,creatinine,HIV,HBSAG)"
    }],
  
    "CAT 3 A":[{
    id:"CAT 3 A",
    description:"FMR with RUA, RBS, LIPIDS, ECG, HbA1c"
    }],
  
  
  
  }
  
  export const medicalStatus={
    "MDRU" : "Medicals done & reports uploaded",
    "MDRA" : "Medicals done & report awaited",
    "PMAP" : "Pending for medical pppointment",
    "APPTD" : "Appointment done awaited for medicals",
    "CRCL" : "Customer requested to call later",
    "CMNC" : "Complete medicals not done by customer",
    "DCNC": "Discrepancy as customer not contactable",
    "SDCN" : "Sales discrepancy as customer not cooperating",
    "APTC" : "Application cancelled",
    "RAPT" : "Re-appointment required as earlier appointment missed"
  }