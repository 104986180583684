import { combineReducers } from 'redux';
import dashBoardReducer from '../reducers/dashBoardReducer';
import TrackerReducer from '../reducers/trackerReducer';
import HierarchyReducer from '../reducers/HierarchyReducer'
import customerDetailReducer from '../reducers/customerApiReducer'
import loaderReducer from "../reducers/loaderReducer"

export default combineReducers({
  dashBoardData: dashBoardReducer,
  TrackerData: TrackerReducer,
  HierarchyData: HierarchyReducer,
  customerDetailReducer:customerDetailReducer,
  loaderReducer:loaderReducer
});
