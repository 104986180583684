import React from "react";
import closeImg from "../../Assets/images/close.svg";
import back from "../../Assets/images/back.svg"
import { isMobile } from "../../utils/utils.js";
import { showCalendarClass } from "../../actions/dashBoardActions";
import { connect } from 'react-redux';

class AddNonPopUp extends React.Component {
    closeModal = () => {
        this.props.onClose && this.props.onClose();
    };
    componentDidMount = () => {
        this.props.showCalendarClass(true);
    }
    componentWillUnmount = () => {
        this.props.showCalendarClass(false);
    }
    render() {
        const {
            cssClass,
            children,
            heading,
            showStatus,
            showOnDesktop,
            details,
            addAutoUnderWritingCss,
            showExceptionStatus,
            popUpBottom,
            removeHeader,
            popupHead,
            customerDetail,
            cssClassBtm
        } = this.props;
        const status =
            details && details.journeyStatus && details.journeyStatus.split("_");

        const renderNumber = (num) => {
            let value = num?.toString()
                .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
            return value
        }

        return (
            <div role="mobilePopup_testRole" data-testid="mobilePopup_testId"
                className={`mainPopUpWrap ${popUpBottom ? "popUpBottomCss" : ""} ${!isMobile() && showOnDesktop ? "showOnDesktop" : ""
                    }`}
            >
                <span className={`backgroundOverlay`} onClick={this.closeModal} />
                <div className={`mobilePopUpWrap ${cssClass} ${isMobile() &&  "mob-full-screen"}`}>
                    {removeHeader ? null : <div className="pop-top-sec">
                        {this.props.popupHead ? <div data-testid="mobilePopup_cancelBtn" className="popup-header">{popupHead}</div> : ''}
                        <span className="close-btn" onClick={this.closeModal}>
                            <img alt="Close" src={back} />
                        </span>
                        {
                            heading &&
                            <div className="heading">{heading}</div>
                        }
                    </div>}
                    <div className={`pop-btm-sec ${cssClassBtm}`}>{children}</div>
                </div>
            </div>
        );
    }
}

const actions = {
    showCalendarClass
}

export default connect(null, actions)(AddNonPopUp);


