import React from "react";
import { connect } from "react-redux";
import {
  agentLogin,
  sendLoginOtp,
  getLoginToken,
} from "../actions/dashBoardActions";
import { isMobile, agentCode, toaster } from "../utils/utils";
import logo from "../Assets/logo/logo_2x.svg";
import Input from "../components/Input";
import DOBInputs from "../components/DOBInputs";
import OTPInput from "../components/OTPInput";
class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      showOTP: false,
      agentCode: "",
      referenceNumber: "",
      isCallInProgress: false,
      showLoginInput: true,
      tokenExpired: false,
    };
  }
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const hasssoid = urlParams.has("ssoid");
    const ssoid = urlParams.get("ssoid");
    if (hasssoid && ssoid) {
      if (localStorage.getItem('agentAuth')) {
        localStorage.setItem('agentAuth', '');
        localStorage.setItem('agentCode', '');
        window.location.reload();
      }
      else {
        this.props.getLoginToken(ssoid, (resp) => {
          
        const source = resp.data.body && resp.data.body.user && resp.data.body.user.source;
        const proposalId=resp.data.body && resp.data.body.user && resp.data.body.user.uniqueProposalId;       
          if (resp && resp.data && resp.data.body) {
            if(source && proposalId && source==='D2C'){
              // window.location.href = apiConstants.M_CUSTOMER_URL + "?proposal=" + proposalId;
             }
             else{
            toaster("success", resp.data.message);
             }
          } else if (resp && resp.data) {
            toaster("error", resp.data.message);
            this.setState({ tokenExpired: true });
          }
        });
      }
    } else {
      this.setState({ tokenExpired: true });
    }
  }
  handleChange = (name, value) => {
    if (name === "agentCode") {
      value = value.toString().toUpperCase();
    }
    this.setState({
      [name]: value,
    });
  };
  enableOtp = () => {
    this.setState({
      isCallInProgress: true,
      otp: "",
    });
    this.props.sendLoginOtp({ code: this.state.agentCode }, (resp) => {
      if (resp && resp.data && resp.data.body) {
        toaster("success", resp.data.message);
        console.log("log in ", resp);
        this.setState({
          referenceNumber: resp.data.body.referenceNumber,
          showOTP: true,
          showLoginInput: false,
          isCallInProgress: false,
        });
      } else {
        toaster(
          "error",
          "This Agent code doesn't exist. Please ensure to login with the relevant Agent Code."
        );
        this.setState({
          isCallInProgress: false,
        });
      }
    });
  };
  sendOtp = () => {
    this.setState({
      isCallInProgress: true,
    });
    this.props.agentLogin(
      {
        code: this.state.agentCode,
        otp: this.state.otp,
        referenceCode: this.state.referenceNumber,
      },
      (resp) => {
        if (resp && resp.data && resp.data.body) {
          toaster("success", resp.data.message);
        } else if (resp && resp.data) {
          toaster("error", resp.data.message);
        } else {
          toaster("error", "Something went wrong");
        }
        this.setState({
          isCallInProgress: false,
        });
      }
    );
  };
  getOTP = (otp) => {
    this.setState({
      otp: otp,
    });
  };
  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const hasssoid = urlParams.has("ssoid");
    const ssoid = urlParams.get("ssoid");

    const tempRules = {
      editable: true,
      errorMsg: "Field is mandatory",
      mandatory: true,
      visible: true,
    };
    if (hasssoid && ssoid) {
      //sso id login
      if (this.state.tokenExpired) {
        return (
          <>
            <div className={"login_page agent_code"}>
              <div className="head_section">
                <a>
                  <img className="logo-image" src={logo} alt="logo" />
                </a>
                <h1>
                  Welcome to <span>mCustomer</span>{" "}
                </h1>
              </div>
              <div className="loginForm">
                <div className={"heading_text"}>
                  Your Session has been Expired. Please login again.
                </div>
              </div>
              {/* <div className="stepBtnWrap">
                {
                  this.state.showOTP ?
                    <div onClick={this.sendOtp} className={`a-c-submit-button ${(this.state.otp.length >= 6 && !this.state.isCallInProgress) ? 'active' : ''}`} >
                      VERIFY
                    </div>
                    :
                    <div onClick={this.enableOtp} className={`a-c-submit-button ${!this.state.isCallInProgress?'active':''}`} >
                      GET OTP
                    </div>
                }
              </div> */}
            </div>
          </>
        );
      } else {
        return ( <>
          <div className={"login_page agent_code"}>
            <div className="head_section">
              <a>
                <img className="logo-image" src={logo} alt="logo" />
              </a>
              <h1>
                Welcome to <span>mCustomer</span>{" "}
              </h1>
            </div>
            <div className="loginForm">
              <div className={"heading_text"}>
                    Please wait while we load the page.
              </div>
            </div>
            {/* <div className="stepBtnWrap">
              {
                this.state.showOTP ?
                  <div onClick={this.sendOtp} className={`a-c-submit-button ${(this.state.otp.length >= 6 && !this.state.isCallInProgress) ? 'active' : ''}`} >
                    VERIFY
                  </div>
                  :
                  <div onClick={this.enableOtp} className={`a-c-submit-button ${!this.state.isCallInProgress?'active':''}`} >
                    GET OTP
                  </div>
              }
            </div> */}
          </div>
        </>)        
      }
    } else {
      //normal otp login
      return (
        <>
          <div data-testid="otp_login" className={"login_page agent_code"}>
            <div className="head_section">
              <a>
                <img className="logo-image" src={logo} alt="logo" />
              </a>
              <h1>
                Welcome to <span>mCustomer</span>{" "}
              </h1>
            </div>
            <div className="loginForm">
              {this.state.showLoginInput ? (
                <div className={"heading_text"}>Login with </div>
              ) : (
                <div className={"heading_text otp-header"}>
                  <span
                    className="arrow"
                    onClick={() =>
                      this.setState({ showOTP: false, showLoginInput: true })
                    }
                  ></span>
                  Re-enter agent code
                </div>
              )}

              {this.state.showLoginInput && (
                <div className="form-group mob-full ">
                  <div className="desk-half2">
                    <Input
                      inputType="text"
                      placeholder=""
                      value={this.state.agentCode}
                      onChange={(e) =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      name="agentCode"
                      label="Agent Code"
                      editable={true}
                      isRequired={true}
                      rules={tempRules}
                      noDecimal={true}
                      numberLength={10}
                      noSpecialChar={true}
                      onKeyDownFunc={() => this.enableOtp()}
                    />
                    <span className="note_text">
                      You will receive an OTP on your registered mobile
                    </span>
                  </div>
                </div>
              )}
              {this.state.showOTP && (
                <div className="form-group mob-full ">
                  <div className="desk-half2">
                    <OTPInput
                      otp={this.state.otp}
                      resendFunc={() => this.enableOtp()}
                      triggerTimmer={this.state.showOTP}
                      getOTP={(otp) =>
                        this.setState({ otp: otp }, () => {
                          if (this.state.otp.length === 6) {
                            this.setState({
                              buttonActive: true,
                            });
                          } else {
                            this.setState({
                              buttonActive: false,
                            });
                          }
                        })
                      }
                      otpKeyDownFunc={this.sendOtp}
                    />
                  </div>
                  <span className="note_text">
                    We have sent an OTP on your registered mobile
                  </span>
                </div>
              )}
              {this.state.showOTP ? (
                <div
                data-testid="verify_div"
                  onClick={this.sendOtp}
                  className={`a-c-submit-button login-btn ${this.state.otp.length >= 6 && !this.state.isCallInProgress
                      ? "active"
                      : ""
                    }`}
                >
                  Verify <span className="forward_arrow"></span>
                </div>
              ) : (
                <div
                  data-testid="getotp_div"
                  onClick={this.enableOtp}
                  className={`a-c-submit-button login-btn ${!this.state.isCallInProgress ? "active" : ""
                    }`}
                >
                  Get OTP <span className="forward_arrow"></span>
                </div>
              )}
            </div>
            <div className="bkg1-wrapper">
              <div className="bkg1">
                <div className="family"></div>
              </div>
            </div>

            <div className="bkg2-wrapper">
              <div className="bkg2"></div>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(null, {
  getLoginToken,
  agentLogin,
  sendLoginOtp,
})(LoginComponent);
