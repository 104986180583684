import axios from "axios";
import apiConstants from "../constants/apiConstants";
import actionTypes from "../constants/actionTypes";
import { toaster } from "../utils/utils";

const urlParams = new URLSearchParams(window.location.search);
const sessionTimeout = urlParams.get("sessionTimeout");
// console.log('sessionTimeout', sessionTimeout, urlParams)

const handleLogin = (dispatch) => {
  console.log("logout checking", handleLogin)
  localStorage.clear();
  if (sessionTimeout === 'true') {
    localStorage.clear();
    // dispatch({
    //   type: actionTypes.agentLogin,
    //   payload: false,
    // });
  window.location.replace(`${apiConstants.TRACKER_URL}`)
  }
  dispatch({
    type: actionTypes.agentLogin,
    payload: false,
  });
  // window.location.reload();
};
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error?.response?.status) {
      if (localStorage.getItem('agentAuth')) {
        localStorage.setItem('agentAuth', '');
        localStorage.setItem('agentCode', '');
        window.location.reload();
      }
      // handleLogin();

    } else {
      return Promise.reject(error);
    }
  }
);


export const fetchCustomerList = (type, data, cb) => (dispatch) => {
  dispatch({
    type: actionTypes.fetchCutomerListLoader,
    payload: true
  })
  if (data.page === 0) {
    dispatch({
      type: actionTypes.fetchCustomerList,
      payload: [],
      totalItems: 0,
      totalPages: 0,
      isNewPage: true,
    })
  }

  axios
    .post(`${apiConstants.DASHBOARD_API_URL}api/v1/tracker/dashboard`, data, {
      headers: {
        "Content-Type": "application/json",
        agentCode: localStorage.getItem("agentCode"),
        Authorization: localStorage.getItem("agentAuth"),
      },
    })
    .then((resp) => {
      dispatch({
        type: actionTypes.fetchCutomerListLoader,
        payload: false
      })
      dispatch({
        type: actionTypes.fetchCustomerList,
        payload: resp.data.body.itemList,
        totalItems: resp.data.body.totalItems,
        totalPages: resp.data.body.totalPages,
        isNewPage: data.page === 0,
      });
      if (cb) {
        cb();
      }
    })
    .catch((err) => {
      // if(err?.response?.status !='500'){
      //   handleLogin(dispatch);
      //    }

      if (err?.response?.status == "401" || !err?.response?.status) {
        dispatch({
          type: actionTypes.agentLogin,
          payload: false,
        });
        localStorage.clear();
      }
    });
};
export const agentLogout = () => {
  return dispatch => {
    axios.post(`${apiConstants.AUTH_API_URL}api/v1/auth/logout`, {}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agentAuth")
      },
    })
      .then(response => {
        // console.log(console.log("fail logout", response))
        handleLogin(dispatch);
      })
      .catch(err => {
        console.log("fail logout", err);
        // handleLogin(dispatch);
      });
  }

};
export const fetchTabList = (type, cb) => (dispatch) => {

  axios
    .get(`${apiConstants.DASHBOARD_API_URL}api/v1/tracker/tabStatusCount`, {
      headers: {
        "Content-Type": "application/json",
        agentCode: localStorage.getItem("agentCode"),
        Authorization: localStorage.getItem("agentAuth")
      },
    })
    .then((resp) => {
      dispatch({
        type: actionTypes.fetchTabList,
        payload: resp.data.body,
      });
      if (cb) {
        cb(resp.data.body);
      }
    })
    .catch((err) => {
      console.log("err 401", err)
      // handleLogin(dispatch)
      if (err?.response?.status == "401") {
        dispatch({
          type: actionTypes.agentLogin,
          payload: false,
        });
        localStorage.clear();
      }
    });
};

export const fetchFilterOptions = (agentCode, tabName) => async (dispatch) => {
  let url = `${apiConstants.DASHBOARD_API_URL}api/v1/tracker/getFilterOptions?tabName=${tabName}`;
  try {
    const { data } = await axios.get(url, {
      headers: {
        agentCode: localStorage.getItem("agentCode"),
        Authorization: localStorage.getItem("agentAuth"),
      },
    });
    console.log("data", data);
    dispatch({
      type: actionTypes.fetchFilterOptionsType,
      payload: data.body,
    });
  } catch (err) {
    if (err.response.status == "401" || !err?.response?.status) {
      dispatch({
        type: actionTypes.agentLogin,
        payload: false,
      });
      localStorage.clear();
    }
    console.log("Error while fetching filter options =>", err);
  }
};
export const createBookMark = (type, data, cb) => (dispatch) => {
  axios
    .put(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/bookmark`, data, {
      headers: {
        "Content-Type": "application/json",
        agentCode: localStorage.getItem("agentCode"),
        Authorization: localStorage.getItem("agentAuth"),
      },
    })
    .then((resp) => {
      if (resp.data.message === "Bookmark Removed.") {
        dispatch({
          type: actionTypes.unBookmarkedApplication,
          payload: data,
        });
      } else {
        dispatch({
          type: actionTypes.bookmarkedApplication,
          payload: data,
        });
      }
      if (cb) {
        cb(resp);
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status == "401" || !err?.response?.status) {
        dispatch({
          type: actionTypes.agentLogin,
          payload: false,
        });
        localStorage.clear();
      }
      // if (cb) {
      //   cb();
      // }
    });
};
export const sendLoginOtp = (data, cb) => (dispatch) => {
  axios
    .post(`${apiConstants.AUTH_API_URL}api/v1/auth/login`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((resp) => {
      if (cb) {
        cb(resp);
      }
    })
    .catch((err) => {
      console.log(err);
      if (cb) {
        cb();
      }
    });
};
export const agentLogin = (data, cb) => (dispatch) => {
  axios
    .post(`${apiConstants.AUTH_API_URL}api/v1/auth/validate`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((resp) => {
      if (resp?.data?.body && resp?.data?.body?.accessToken) {
        localStorage.setItem(
          "agentAuth",
          resp.data.body.tokenType + " " + resp.data.body.accessToken
        );
        localStorage.setItem("agentCode", data.code);
        localStorage.setItem("encryptedCode", resp?.data?.body?.user?.encryptedCode);
        localStorage.setItem("expiry", resp.data.body.expirationDate)
        localStorage.setItem(
          "agentName",
          resp.data.body && resp.data.body.user && resp.data.body.user.name
        );
        localStorage.setItem(
          "role",
          resp?.data?.body?.user?.role
        );
        dispatch({
          type: actionTypes.agentLogin,
          payload: true,
          agentCode: data.code,
        });
      }
      if (cb) {
        cb(resp);
      }
    })
    .catch((err) => {
      console.log(err);
      if (cb) {
        cb();
      }
    });
};
export const getLoginToken = (ssoid, cb) => (dispatch) => {
  axios
    .get(`${apiConstants.AUTH_API_URL}api/v1/auth/tokenData/${ssoid}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((resp) => {
      if (resp.data.body) {
        localStorage.setItem(
          "agentAuth",
          resp.data.body.tokenType + " " + resp.data.body.accessToken
        );
        localStorage.setItem("agentCode", resp.data.body && resp.data.body.user && resp.data.body.user.code);
        localStorage.setItem(
          "agentName",
          resp.data.body && resp.data.body.user && resp.data.body.user.name
        );
        localStorage.setItem("encryptedCode", resp?.data?.body?.user?.encryptedCode);
        const urlParams = new URLSearchParams(window.location.search);
        const hasssoid = urlParams.has("ssoid");
        const ssoid = urlParams.get("ssoid");
        const source = resp.data.body && resp.data.body.user && resp.data.body.user.source;
        const proposalId = resp.data.body && resp.data.body.user && resp.data.body.user.uniqueProposalId;
        if (source && proposalId && source === 'D2C') {
          window.location.href = apiConstants.M_CUSTOMER_URL + "?proposal=" + proposalId;
        }
        else if (hasssoid && ssoid) {
          window.location.href = window.location.href.split('?')[0];
          dispatch({
            type: actionTypes.agentLogin,
            payload: true,
            agentCode: resp.data.body && resp.data.body.user && resp.data.body.user.code,
          });
        }

      }
      if (cb) {
        cb(resp);
      }
    })
    .catch((err) => {
      console.log(err);
      if (cb) {
        cb();
      }
    });
}

export const fetchExceptionList = (pn) => (dispatch) => {
  dispatch({
    type: actionTypes.exceptionListLoader,
    payload: true,
  });
  axios
    .get(
      `${apiConstants.PROPOSEL_API_URL}api/v1/proposal/exceptions/SUBMIT/${pn}`,
      {
        headers: {
          Authorization: localStorage.getItem("agentAuth"),
        },
      }
    )
    .then((resp) => {
      console.log("resp", resp);
      dispatch({
        type: actionTypes.exceptionListLoader,
        payload: false,
      });
      if (resp.data.message === "Exceptions not found") {
        dispatch({
          type: actionTypes.exceptionList,
          payload: resp.data.message,
        });
      } else {
        dispatch({
          type: actionTypes.exceptionList,
          payload: resp.data.body.exceptions,
        });
      }
    })
    .catch((err) => {
      console.log("err", err);
      dispatch({
        type: actionTypes.exceptionListLoader,
        payload: false,
      });
    });
};

export const showCalendarClass = (val) => (dispatch) => {
  if (val) {
    document.getElementsByTagName("body")[0].classList.add("fixed_scroll")
  } else {
    document.getElementsByTagName("body")[0].classList.remove("fixed_scroll")
    document.getElementsByTagName("body")[0].classList.remove("scroll-fixed")
  }
}



// status api

export const customerPortalStatus = (proposalNo, cb) => (dispatch) => {
  dispatch({
    type: "LOADER_ON",
  });
  axios
    .get(`${apiConstants.PROPOSEL_API_URL}api/v1/customer-portal/requirement?proposalNumber=${proposalNo}`, {
      headers: {
        "Content-Type": "application/json",
        // agentCode: localStorage.getItem("agentCode"),
        "Accept": "application/json",
        "Authorization": localStorage.getItem("agentAuth"),
      },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.customerInfoSuccess,
        info: res.data,
      });
      dispatch({
        type: "LOADER_OFF",
      });
      if (res.data.status === 'OK') {
        cb(res.data.body)
      }
    })
    .catch((error) => {
      dispatch({
        type: "LOADER_OFF",
      });
      toaster('error', error.message);
    });
};
