import React from "react";
import closeImg from "../../Assets/images/close.svg";
import { isMobile } from "../../utils/utils.js";

const PopUp = (props) => {
  const closeHandler = () => {
    props.onClose();
  };
  return (
    <div  style={{ height: props.medPopoup ? props.medPopoup : '100%' }} className={`overlay ${props.addCss}`}>
      <div className="vrtclcntr_bx">
        <div className="vrtclcntr_bxinr">
          <div className="dcmnt_upload_popupinr">
            <div onClick={closeHandler} className="close_popup">
              <img src={closeImg} alt="cross" />
            </div>
            {props.removeHeader ||  !props.heading ? null : (
              <div className="hdng_bx">
                <h2>{props.heading ? props.heading : null}</h2>
              </div>
            )}
            <div className="content-box">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
