import React, { useEffect, useRef } from "react";
import Play from "../../Assets/images/play.svg";

const DropDown = ({
  isIcon = false,
  icon,
  iconCss,
  isLabel = false,
  label,
  children,
  dropDownHeader,
  cssClass,
  onToggle,
  toggleValue,
  showRedDot = false,
  redDotTop = 0,
  redDotRight = 0,
  isHeaderBold = false,
}) => {
  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        toggleValue
      ) {
        onToggle();
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, toggleValue, onToggle]);
  return (
    <div role="dropDown_testRole" data-testid="dropDown_testId"
        ref={wrapperRef} className={`drop-down-wrapper-container ${cssClass}`}>
      <div className="label" onClick={() => onToggle()}>
        {isIcon && <img src={icon} alt="dd-icon" className={iconCss} />}
        {isLabel && <span>{label}</span>}
        {showRedDot && (
          <sup>
            <sup>
              <div
                className="sup-red-dot dd-red-dot"
                style={{ top: redDotTop, right: redDotRight }}
              ></div>
            </sup>
          </sup>
        )}
      </div>

      {toggleValue && (
        <>
          <img src={Play} alt="play" className="play" />
          <div className="drop-down-container">
            <div
              className={`drop-down-header ${isHeaderBold && "header-bold"}`}
            >
              {dropDownHeader}
            </div>
            <div className="dd-body">{children}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default DropDown;
