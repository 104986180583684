import axios from "axios";
import apiConstants from "../constants/apiConstants";
import actionTypes from "../constants/actionTypes";
import { toaster } from "../utils/utils";
//adding hardcoaded active agent id
let agentCode = localStorage.getItem('agentCode');
export const HierarchyActions = (code, cb) => (dispatch) => {
  agentCode = localStorage.getItem('agentCode');
  let encryptedCode = localStorage.getItem('encryptedCode');
  axios
    .post(`${apiConstants.BAXA_API_URL}hl/agentAllDetails/${encryptedCode}`,{},{
      headers: {
        "Content-Type": "application/json",
        "Authorization":localStorage.getItem('agentAuth')
      }
    })
    .then((resp) => {
      toaster("success", resp?.data?.message)
      dispatch({
        type: actionTypes.fetchAgentDetails,
        payload: resp.data.body,
      });
      if (cb) {
        cb(resp.data);
      }
    })
    .catch((err) => {
      if(err.status=='401'){
        dispatch({
          type: actionTypes.agentLogin,
          payload: false,
        });
      }
      dispatch({
        type: actionTypes.clearAgentDetails,
      });
      if (cb) {
        cb();
      }
    });
};
