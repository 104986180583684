import React, { Component } from "react";
import TpaHeader from "../components/TpaHeader";
import TpaFooter from "../components/TpaFooter";
import TpaTab from "../components/TpaTab";
// import MedicalTab from "../components/MedicalTab";
import axios from "axios";
import apiConstants from "../constants/apiConstants";
import { toaster } from "../utils/utils";

class TpaContainer extends Component {
    state = {
        selectedTab: 1,
        completeButton: true,
        docData: {},
        proposalNumber: '',
        consent: false,
        formLock: false
    };

    /* constructor(props) {
         super(props);
         this.state = {
             selectedTab: 1
         }
     }*/
    checkDisableComplete = (data) => {
        let isDisabled = false;
        data && Object.keys(data).length > 0 &&
            Object.keys(data) && Object.keys(data).forEach(
                (item, idx) => {
                    if (item === "primaryInsuredDocumentDetail" || item === "proposerDocumentDetail") {
                        data[item] && data[item].ServiceDocumentList.length > 0 && data[item].ServiceDocumentList.forEach(
                            (itemUp, index) => {
                                if (!itemUp.url) {
                                    isDisabled = true;
                                }
                            });
                    }
                })
        return isDisabled;

    }
    getItemList = () => {
        // const encode = (this.props.proposalNo_consent) ? `uwId=${(this.props.tpaData && this.props.tpaData.id) || (localStorage.getItem('tpaId')) || (this.props.proposalNo_consent)}&encoded=1` :`${(this.props.tpaData && this.props.tpaData.id) || (localStorage.getItem('tpaId')) || (this.props.proposalNo_consent)}`
        const encode = (this.props.proposalNo_consent) ? 0 : 1
        axios.get(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/get/addInfo/docList?uwId=${(this.props.tpaData && this.props.tpaData.id) || (localStorage.getItem('tpaId')) || (this.props.proposalNo_consent)}&encoded=${encode}
        `, {
            headers: {
                "Content-Type": "application/json",
                // "Authorization": localStorage.getItem('agentAuth')
            },
        }
        ).then((res) => {
            if (res && res.data) {
                const data = res.data && res.data.body;
                const isDisable = this.checkDisableComplete(data)
                this.setState({ proposalNumber: data.proposalNumber, docData: data, completeButton: isDisable });
                localStorage.setItem('policyNumberTpa', data.policyNumber);
                localStorage.setItem('proposalNumberTpa',data.proposalNumber);
            }
        }).catch((err) => {
            console.log("tpa api=========>", err)
        })
    }
    componentDidMount() {
        if (this.props.proposalNo_consent) {
            this.setState({ consent: true });
            localStorage.setItem('tpaId', this.props.proposalNo_consent)
            this.getItemList();
            // axios
            //     .get(
            //         `${apiConstants.PROPOSEL_API_URL}api/v1/proposal/bankConsent/${this.props.proposalNo_consent}`,
            //         {
            //             headers: {
            //                 ExpirationTime: this.props.et,
            //             },
            //         }
            //     )
            //     .then((resp) => {
            //         console.log("checking proposal response", resp)
            //         if (resp.data && resp.data.body) {
            //             const { proposalNumber } = resp.data.body
            //             localStorage.setItem("proposalNumberTpa", proposalNumber)
            //             this.setState({ proposalNumber: proposalNumber }, () => {
            //                 this.getItemList();
            //             })
            //         }
            //     })
            //     .catch((err) => {
            //         console.log(`err`, err);
            //     });
        } else {
            const { proposalNo } = this.props.tpaData;
            this.setState({ proposalNumber: proposalNo || localStorage.getItem("proposalNumberTpa") }, () => {
                this.getItemList();
            })
        }
    }

    completeFormCheck = (value) => {
        this.getItemList();
    }
    completeTpaForm = () => {
        const payload = {
            proposalNumber: localStorage.proposalNumberTpa,
            policyNumber: localStorage.policyNumberTpa,
            id: this.state.docData && this.state.docData.id
        }
        if (payload.proposalNumber || payload.policyNumber) {
            payload.completed = true
        }
        axios.post(`${apiConstants.PROPOSEL_API_URL}api/v1/proposal/addInfo/completeForm`, '', {
            headers: {
                ...payload,
                "Content-Type": "application/json",
                // "Authorization": localStorage.getItem('agentAuth')
            },
        }).then((res) => {
            if (res.data && res.data.body) {
                // if (this.props.proposalNo_consent) {
                this.setState({ formLock: true })
                // } else {
                // this.props.closeTpa();
                // }
                toaster("success", res.data.message)
            }
        }).catch((err) => {
            console.log("completed tpa api failed", err)
        })
    }

    render() {
        const { docData, formLock, completeButton, consent } = this.state;
        return (
            <div className="container tpa-container">
                {this.state.proposalNumber &&
                    <>
                        <TpaHeader proposalNumber={this.state.proposalNumber}
                            data={docData} />

                        <div className="tab-wrapper">
                            <div
                                className={`${this.state.selectedTab === 1 ? 'tab-view tab-active' : 'tab-view'}`}
                                onClick={() => this.setState({ selectedTab: 1 })}>
                                <span className="documents-icon"></span>Pending Documents
                            </div>
                            {/* <div className={`${this.state.selectedTab === 2 ? 'tab-view tab-active' : 'tab-view'}`}
                        onClick={() => this.setState({ selectedTab: 2 })}>
                        <span className="scheduling-icon"></span>Medical Scheduling
                    </div> */}
                        </div>
                        {/* <div className={`tpa-wrapper ${this.state.formLock ? 'formlock' : ''}`}> */}
                        <div className="tpa-wrapper">
                            {/* {this.state.selectedTab === 1 ? */}
                            <TpaTab
                                tpaData={this.props.tpaData}
                                completeFormCheck={this.completeFormCheck}
                                data={docData}
                                formLock={this.state.formLock} 
                                docTypeQuesOrDoc='ServiceDocumentList'/>
                            {/* :
                        <MedicalTab
                            tpaData={this.props.tpaData} />} */}
                        </div>
                        <TpaFooter consent={consent} closeTpa={this.props.closeTpa}
                            disabled={completeButton || formLock || (docData && docData.allDocumentUploaded)}
                            completeForm={this.completeTpaForm} />
                    </>
                }
            </div>
        )
    }
}

export default TpaContainer;

