import React, { Component } from "react";
import axios from "axios";
import apiConstants from "../../constants/apiConstants";
import { toaster } from "../../utils/utils";
import MobilePopUp from "../../containers/MobilePopUp/MobilePopUp";
import imageCompression from "browser-image-compression";
import placeholder from "../../Assets/images/placeholder.png";
import PopUp from "../../containers/PopUp";
import greentick from "../../Assets/images/green_check.svg";
import greyTick from "../../Assets/images/greyTick.svg";


const greenImageStyle = {
  marginRight: "8px",
  width: "20px",
  height: "16px",
};

class TpaTab extends Component {
  state = {
    selectedValue: "",
    showPopup: false,
    attachFile: false,
    docType: "",
    previewImg: "",
    deletePopup: false,
    enableSave: false,
    downloadType: false,
    showUpdatePopUp: false,
    setItem: "",
    setType: "",
  };

  // componentDidMount = () => {
  //     // this.props.completeFormCheck(true);

  // }

  delete = (e, data, type) => {
    e.stopPropagation();
    const doctype =
      type === "proposerDocumentDetail"
        ? "OWNER"
        : type === "primaryInsuredDocumentDetail"
        ? "INSURER"
        : "";
    this.setState({
      deletePopup: true,
      selectedValue: data,
      docType: doctype,
    });
  };

  setshowUpdatePopUp = (item, type) => {
    this.setState({
      setItem: item,
      setType: type,
      showUpdatePopUp: true,
    });
  };

  closeConsentHandler = () => {
    this.setState({
      showUpdatePopUp: false,
    });
  };
  consentSubmitHandler = () => {
    const { setItem, setType } = this.state;
    this.view(setItem, setType);
  };
  viewDownload = (e, item, type, val) => {
    e.stopPropagation();
    this.view(item, type, val);
  };
  view = (item, type, val) => {
    const doctype =
      type === "proposerDocumentDetail"
        ? "OWNER"
        : type === "primaryInsuredDocumentDetail"
        ? "INSURER"
        : "";
    if (item.url || val === "download") {
      let itemurl = val === "download" ? item.questionnaireFileName : item.url;
      // let itemurl = ( item.url)

      axios
        .get(
          `${
            apiConstants.PROPOSEL_API_URL
          }api/v1/proposal/document/download?file=${itemurl}&proposalNo=${
            this.props.data && this.props.data.proposalNumber
          }
            `,
          {
            headers: {
              Authorization: localStorage.getItem("agentAuth"),
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          if (res.data) {
            let download = val === "download" ? true : false;
            this.setState({
              previewImg: URL.createObjectURL(res.data),
              showPreviewPopup: true,
              selectedValue: item,
              docType: doctype,
              downloadType: download,
              showUpdatePopUp: false,
            });
          }
        })
        .catch((err) => {
          console.log("tpa api", err);
        });
    } else {
      this.setState({
        showPopup: true,
        selectedValue: item,
        docType: doctype,
        showUpdatePopUp: false,
      });
    }
  };
  onhandleChange = (e) => {
    if (
      e.target &&
      e.target.files.length > 0 &&
      (e.target.files[0].type === "application/pdf" ||
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png")
    ) {
      if (e.target.files[0].type === "application/pdf") {
        if (e.target.files[0].size / 1000 / 1000 > 10) {
          toaster("warn", "Selected file size cannot be greater than 10 mb.");
        } else {
          const data = { ...this.state.selectedValue };
          const pdfUrl = e.target.files[0];
          data.url = pdfUrl;
          this.setState({
            selectedValue: data,
            attachFile: true,
          });
        }
      } else {
        this.handleImageCompress(e.target.files);
        this.setState({
          ...this.state,
          attachFile: true,
        });
      }
    } else {
      toaster("warn", "Upload .pdf, .png  & .jpg only.");
    }
  };
  handleImageCompress = (v) => {
    // console.log("======>", v, this.state.selectedValue)
    var imageFile = v[0];
    // console.log("imageFile", imageFile);
    // console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    // let abc = this;
    imageCompression(imageFile, options)
      .then((compressedFile) => {
        // console.log(
        //   "compressedFile instanceof Blob",
        //   compressedFile instanceof Blob
        // ); // true
        // console.log(
        //     `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        // );
        // smaller than maxSizeMB
        // console.log("compressedFile", compressedFile);

        const data = { ...this.state.selectedValue };
        data.url = compressedFile;
        this.setState({
          selectedValue: data,
        });
        // console.log("-----------", data);
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  blobToFile(blob, name) {
    let x = new File([blob], name, { type: "image/jpg" });
    return x;
  }

  closeInfo = () => {
    this.setState({
      showPopup: false,
      showPreviewPopup: false,
      selectedValue: "",
      attachFile: false,
      enableSave: false,
      proofNumber: "",
      proofType: "",
      downloadType: false,
    });
  };
  upLoadData = () => {
    const data = { ...this.state.selectedValue };
    const docNumber = this.state.proofNumber
      ? this.state.proofType === "Aadhaar Card"
        ? parseInt(this.state.proofNumber.slice(8))
        : this.state.proofNumber
      : "";
    const image = this.blobToFile(data.url, data.url.name);
    let uploadData = new FormData();
    uploadData.append("file", image);
    let agentViewDoc = this.props.docTypeQuesOrDoc === "quesList";

    axios
      .post(
        `${
          apiConstants.PROPOSEL_API_URL
        }api/v1/proposal/document/addInfo/uploadFile?documentCd=${
          data.documentCd
        }&docCategoryCd=${
          agentViewDoc ? data.documentCategoryCd : data.docCategoryCd
        }&documentSide=FRONT_SIDE&docCategoryTypeCd=${
          agentViewDoc ? data.documentCategoryTypeCd : data.docCategoryTypeCd
        }&documentNumber=${docNumber}&partyType=${
          this.state.docType
        }&proposalNo=${
          this.props.data && this.props.data.proposalNumber
        }&policyNo=${localStorage.getItem(
          "policyNumberTpa"
        )}&serviceDocListId=${data.id}&uwId=${this.props.data.id}
        `,
        uploadData,
        {
          headers: {
            accept: "*/*",
            Authorization: localStorage.getItem("agentAuth"),
          },
        }
      )
      .then((res) => {
        if (res.data && res.data.body) {
          toaster("success", res.data.message);
          this.setState({
            showPopup: false,
            attachFile: false,
            proofNumber: "",
            proofType: "",
            enableSave: false,
          });
          this.props.completeFormCheck(true);
        } else if (res.data && res.data.message) {
          toaster("error", res.data.message);
        }
      })
      .catch((err) => {
        console.log("tpa api", err);
      });
  };

  deleteFile = () => {
    const newSelectedValue = { ...this.state.selectedValue };
    newSelectedValue.url = "";
    this.setState({
      selectedValue: newSelectedValue,
      attachFile: false,
    });
  };
  deleteUpload = () => {
    const data = { ...this.state.selectedValue };
    axios
      .delete(
        `${
          apiConstants.PROPOSEL_API_URL
        }api/v1/proposal/document/addInfo/delete?file=${data.url}&proposalNo=${
          this.props.data && this.props.data.proposalNumber
        }&uwId=${this.props.data && this.props.data.id}`,
        {
          headers: {
            Authorization: localStorage.getItem("agentAuth"),
          },
        }
      )
      .then((res) => {
        if (
          res.data &&
          res.data.message.indexOf("removing request submitted successfully") >
            -1
        ) {
          toaster("success", res.data.message);
          this.setState({ deletePopup: false });
          this.props.completeFormCheck(true);
        }
      })
      .catch((err) => {
        console.log("tpa api", err);
      });
  };
  proofNumberHandleChnage = (e, name, value, type) => {
    const checkLength = this.returnValidationForIdentityLength(type, value);
    if (value.length >= checkLength) {
      this.setState({
        enableSave: true,
      });
    } else {
      this.setState({
        enableSave: false,
      });
    }
    if (type === "Aadhaar Card" && value.length <= checkLength) {
      this.setState({
        proofNumber: this.maskedAadhar(value),
        proofType: type,
      });
    } else if (type !== "Aadhaar Card" && value.length <= checkLength) {
      this.setState({
        proofNumber: value.replace(/[^\w\s]/gi, ""),
        proofType: type,
      });
    } else {
      e.preventDefault();
      return;
    }
  };
  maskedAadhar = (num) => {
    let a;
    if (num.length <= 8) {
      a = num.replace(/[0-9]/g, "X");
    } else {
      a = "XXXXXXXX" + num.slice(8);
    }
    return a;
  };
  returnValidationForIdentityLength = (type, value) => {
    if (type === "Aadhaar Card") {
      return 12;
    } else if (type === "Passport") {
      return 8;
    } else if (type === "Voters Id") {
      return 10;
    } else if (type === "Driving License") {
      return 15;
    } else {
      return value.length;
    }
  };
  addharNumberValidation = (e, type) => {
    if (type === "Aadhaar Card" && (e.which < 48 || e.which > 57)) {
      e.preventDefault();
    } else {
      return;
    }
  };
   renderTick = (item, userType) => {
    const InsuredDCdata =
      this.props.customerDetail &&
      this.props.customerDetail?.additionalInfoDocs?.[userType]?.quesList
      ?.filter(
        (ele) => {  
          return ele.id === item.id}
      );

    const result =
      this.props.customerDetail &&
      this.props.customerDetail?.additionalInfoDocs?.[userType]?.quesDataList?.find(
        (obj) =>
          obj.id === InsuredDCdata?.[0]?.id &&
          // && obj.data.length > 0
          // (InsuredDCdata?.[0]?.url !== "" ||
          //   (InsuredDCdata?.[0]?.status !== "null" &&
          //   InsuredDCdata?.[0]?.status !== "" &&
          //   InsuredDCdata?.[0]?.status === "received") ||
            obj?.data?.length > 0
          // )
      );
      

    let resultId = result ? true : false;
    console.log('InsuredDCdata',InsuredDCdata,'resultId',resultId)

    return resultId;
  };

  render() {
    
    const { data, docTypeQuesOrDoc } = this.props;
    return (
      <>     
        <div className="details-box">
          <div className="details-header">
            {docTypeQuesOrDoc === "quesList"
              ? "Additional Questionnaire"
              : "Document List"}
          </div>
          <div className="details">
            {data &&
              Object.keys(data)?.length > 0 &&
              Object.keys(data) &&
              Object.keys(data).map((type, idx) => {
                return (
                  <div key={idx}>
                    {(type === "primaryInsuredDocumentDetail" ||
                      type === "proposerDocumentDetail") &&
                      data[type] && (
                        <>
                          {data[type] &&
                            data[type][docTypeQuesOrDoc]?.length > 0 && (
                              <div className="docType">
                                {type === "proposerDocumentDetail"
                                  ? `Proposer ${
                                      docTypeQuesOrDoc === "quesList"
                                        ? "Questionnaire"
                                        : "Document"
                                    }`
                                  : type === "primaryInsuredDocumentDetail"
                                  ? `Insured ${
                                      docTypeQuesOrDoc === "quesList"
                                        ? "Questionnaire"
                                        : "Document"
                                    }`
                                  : ""}
                              </div>
                            )}
                         {
                         data[type] &&
                         data[type][docTypeQuesOrDoc]?.length > 0 && 
                          <div className="category-row" key={idx}>
                            {(type === "primaryInsuredDocumentDetail" ||
                              type === "proposerDocumentDetail") &&
                              data[type] &&
                              data[type][docTypeQuesOrDoc]?.length > 0 &&
                              data[type][docTypeQuesOrDoc].map(
                                (item, index) => {
                                  // console.log('item',item)
                                  // if(item.uploadedBy === 'CUSTOMER'){
                                  //   return
                                  // }
                                const modifiedTitle = item?.documentCdValue?.replace(' of proposer', '');
                                  return (
                                    <div
                                      key={index}
                                      style={{ marginTop: "10px" }}
                                    >
                                      <div className="category-name">
                                        {item.indexValue}
                                      </div>
                                      <div
                                        className="type"
                                        key={index}
                                        // onClick={() =>
                                        //     this.view(item, type)
                                        // }
                                        // onClick={()=>this.setshowUpdatePopUp(item,type) }
                                      >
                                        {modifiedTitle} 
                                        {
                                            item.status ==='waivedOff'  && <span>{'( Waived Off )'}</span>
                                        }

                                        {!item.url && 
                                        item.status !=="waivedOff"  
                                        && item.status!=='received' 
                                        && !this.renderTick(item,type)
                                        && 
                                        (
                                          <button
                                            className="upload_icon"
                                            onClick={() =>
                                              this.setshowUpdatePopUp(
                                                item,
                                                type
                                              )
                                            }
                                          />
                                        )}

                                        {/* {
                                                                            item.url && this.props.hideSection &&
                                                                            <button 
                                                                            disabled={(data && data.allDocumentUploaded) || this.props.formLock} 
                                                                            className={`delete_btn ${(data && data.allDocumentUploaded) || this.props.formLock ? "imgDelete" : ""}`} 
                                                                            onClick={(e) => this.delete(e, item, type)} 
                                                                            />
                                                                            } */}
                                        {item.status ==="waivedOff" ?
                                        <div className="recon-btn right-icon">
                                        <img
                                          src={greyTick}
                                          alt="greyTick"
                                          style={greenImageStyle}
                                        />
                                      </div>
                                        :
                                        (item.url ||  item.status==='received' || this.renderTick(item,type)) && (
                                          <div className="recon-btn right-icon">
                                            <img
                                              src={greentick}
                                              alt="greenTick"
                                              style={greenImageStyle}
                                            />
                                          </div>
                                        )}
                                        {docTypeQuesOrDoc === "quesList" &&
                                          docTypeQuesOrDoc ===
                                            "ServiceDocumentList" && (
                                            // item?.uploadedBy==="AGENT" &&
                                            <button
                                              style={{ marginRight: "5px" }}
                                              className="download_icon"
                                              onClick={(e) => {
                                                this.viewDownload(
                                                  e,
                                                  item,
                                                  type,
                                                  "download"
                                                );
                                              }}
                                            />
                                          )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
              }
                        </>
                      )}
                  </div>
                );
              })
              
              }
          </div>
        </div>

        {this.state.showPopup && (
          <MobilePopUp
            hideCloseBtn={true}
            onClose={this.closeInfo}
            popupHead={
              this.state.selectedValue &&
              this.state.selectedValue.documentCdValue
            }
          >
            <div className="img-thumbnail-view">
              {this.state.selectedValue && this.state.selectedValue.url ? (
                <img
                  // src={
                  //   selectedDocs[currentSelectedDocIndex]
                  //     .backImg.type === "application/pdf"
                  //     ? placeholder
                  //     : URL.createObjectURL(
                  //         selectedDocs[
                  //           currentSelectedDocIndex
                  //         ]?.backImg
                  //       )
                  // }
                  src={
                    this.state.selectedValue &&
                    this.state.selectedValue.url &&
                    this.state.selectedValue.url.type === "application/pdf"
                      ? placeholder
                      : URL.createObjectURL(this.state.selectedValue.url)
                  }
                  alt="img"
                />
              ) : (
                ""
              )}
            </div>
            {this.state.selectedValue && !this.state.attachFile ? (
              <div className="attach-file">
                <div className="attach-file-btn">
                  <input
                    className="capture-img-input"
                    type="file"
                    accept="application/pdf,image/png,image/jpeg"
                    onChange={(e) => this.onhandleChange(e, "front")}
                    name="img"
                  />
                </div>
              </div>
            ) : (
              <div className="reattach-file">
                <div className="reattach-file-btn">
                  <input
                    className="capture-img-input"
                    type="file"
                    accept="application/pdf,image/png,image/jpeg"
                    onChange={(e) => this.onhandleChange(e, "front")}
                    name="img"
                  />
                </div>
                <span className="delete-img" onClick={this.deleteFile} />
              </div>
            )}
            {this.state.selectedValue &&
            (this.state.selectedValue.indexValue ===
              "Permanent Address Proof" ||
              this.state.selectedValue.indexValue ===
                "Correspondence Address Proof" ||
              this.state.selectedValue.indexValue === "Address Proof") ? (
              <div className="proofNumber_addr">
                <label>
                  Please Enter {this.state.selectedValue.indexValue} Number
                </label>
                <input
                  className="inputTpa"
                  type="text"
                  value={this.state.proofNumber}
                  onChange={(e) => {
                    this.proofNumberHandleChnage(
                      e,
                      e.target.name,
                      e.target.value,
                      this.state.selectedValue.documentCdValue
                    );
                  }}
                  onKeyPress={(e) =>
                    this.addharNumberValidation(
                      e,
                      this.state.selectedValue.documentCdValue
                    )
                  }
                />
              </div>
            ) : (
              ""
            )}

            <div className="dcmnt_upload_msg">
              Upload .Pdf, .Png & .Jpg only, Upto 10 MB
            </div>

            <div
              className={`upload-btn-wrapper ${
                this.state.selectedValue &&
                this.state.selectedValue.url &&
                this.state.attachFile &&
                (((this.state.selectedValue.indexValue ===
                  "Permanent Address Proof" ||
                  this.state.selectedValue.indexValue ===
                    "Correspondence Address Proof" ||
                  this.state.selectedValue.indexValue === "Address Proof") &&
                  this.state.enableSave) ||
                  (this.state.selectedValue.indexValue !==
                    "Permanent Address Proof" &&
                    this.state.selectedValue.indexValue !==
                      "Correspondence Address Proof" &&
                    this.state.selectedValue.indexValue !== "Address Proof"))
                  ? "active"
                  : "disabled"
              }`}
              onClick={this.upLoadData}
            >
              Upload
            </div>
          </MobilePopUp>
        )}
        {((this.state.showPreviewPopup &&
          this.state.selectedValue &&
          this.state.selectedValue.url) ||
          this.state.downloadType) && (
          <MobilePopUp
            hideCloseBtn={true}
            onClose={this.closeInfo}
            popupHead={
              this.state.selectedValue && this.state.selectedValue.docCategoryCd
            }
          >
            <div className="img-thumbnail-view">
              {this.state.selectedValue &&
              this.state.selectedValue.url &&
              this.state.selectedValue.url.indexOf(".pdf") < 0 &&
              !this.state.downloadType ? (
                <img src={this.state.previewImg} alt="img" />
              ) : (
                <button
                  className="open-pdf-btn"
                  onClick={() => {
                    //const downloadFile = (blob, fileName) => {
                    // const blob = new Blob(
                    //     this.state.previewImg,
                    //     {
                    //         type: "application/pdf",
                    //     }
                    // );
                    const fileName = this.state.selectedValue.url;
                    const link = document.createElement("a");
                    // create a blobURI pointing to our Blob
                    link.href = this.state.previewImg; //URL.createObjectURL(previewList[currentImgIndex]?.src);
                    link.download = fileName;
                    // some browser needs the anchor to be in the doc
                    document.body.append(link);
                    link.click();
                    link.remove();
                    // in case the Blob uses a lot of memory
                    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                    // };
                    // window.open(
                    //   window.URL.createObjectURL(new Blob(previewList[currentImgIndex]?.src, {type: "pdf"})),
                    //  // window.URL.createObjectURL(previewList[currentImgIndex]?.src),
                    //   "_blank"
                    // )
                  }}
                >
                  <img src={placeholder} alt="pdf placeholder" />
                </button>
              )}
            </div>
          </MobilePopUp>
        )}
        {this.state.deletePopup && (
          <div className="dlt-conf-overlay">
            <div className="delete-conf-wrapper">
              <label>Do you want to delete this document?</label>
              <div className="btn-wrapper">
                <button
                  onClick={() =>
                    this.setState({
                      selectedValue: "",
                      deletePopup: false,
                    })
                  }
                >
                  No
                </button>
                <button
                  className="yes"
                  onClick={() => {
                    this.deleteUpload();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.showUpdatePopUp && (
          <div className="card-body">
            <PopUp
              onClose={this.closeConsentHandler}
              addCss={"counter-consent"}
            >
              <div className="counter-consent">
                <div className="counter-text">
                  Once you select a option, it cannot be changed. Please ensure
                  your choice is final before proceeding.
                  <br />
                  <br />
                  Documents cannot be preview or delete after upload.Make sure
                  to review your documents carefully before uploading.
                </div>
                <div className="d-flex counter-btns">
                  <div className="btn" onClick={this.closeConsentHandler}>
                    CANCEL
                  </div>
                  <div className="btn" onClick={this.consentSubmitHandler}>
                    OK
                  </div>
                </div>
              </div>
            </PopUp>
          </div>
        )}
      </>
    );
  }
}

export default TpaTab;
