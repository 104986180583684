import React from "react";
import { connect } from "react-redux";
import BackIcon from "../../Assets/images/back.svg";
import { HierarchyActions } from "../../actions/HierarchyActions";
import { agentCode, toaster } from "../../utils/utils";

class Hierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.props.HierarchyActions(agentCode, (resp) => {
      this.setState({
        loading: false,
      });
      if (!resp) {
        toaster("error", "Something went wrong");
      }
    });
  }

  render() {
    const { agentDetails } = this.props;
    return (
      <div role="heirarchy_testRole" data-testid="heirarchy_testId" className="mainHierarchyWrap">
        <div className="user-header">
          <div className="u-p-mobile-name">
            <img
              src={BackIcon}
              alt="back"
              className="back-icon"
              onClick={() => this.props.toggleHierarchy()}
            />{" "}
            Hierarchy
          </div>
        </div>
        {this.state.loading ? (
          <div data-testid="hierarchy_stateLoad" className="loadingBoxWrap">Loading...</div>
        ) : (
          JSON.stringify(agentDetails) !== JSON.stringify({}) && (
            <div>
              <div className="heading-section-blue-bg">
                <div className="showstatusWrap">
                  <div className="left-col">
                    <h3>Agent Detail</h3>
                  </div>
                  <div className="right-col">
                    <button
                      className="btn payment-btn"
                      data-tooltip={agentDetails?.Status}
                    >
                      <span className="indicText">Status: </span>
                      <span className="statusText">
                        {agentDetails?.Status?.toLowerCase()}
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="nrmlBg-sec status-infoBox agent-sub">
                <p>
                  <span>Name :</span>
                  {agentDetails?.Agent_Name}
                </p>
                <p>
                  <span>Designation :</span>
                  {agentDetails?.Designation}
                </p>
              </div>
              <div className="grayBg-sec status-infoBox agent-sub">
                <div className="grayBg-sec-left width50">
                  <p>
                    <span>Agent Code :</span>
                    {agentDetails?.Agent_Code}
                  </p>
                  <p>
                    <span>Job Title :</span>
                    {agentDetails?.Job_Title}
                  </p>
                </div>
                <div className="grayBg-sec-right width50">
                  <p>
                    <span>Channel :</span>
                    {agentDetails?.Channel}
                  </p>
                  <p>
                    <span>Sub Channel :</span>
                    {agentDetails?.Sub_Channel}
                  </p>
                </div>
              </div>
              <div className="nrmlBg-sec status-infoBox agent-sub">
                <p>
                  <span>Emp Code :</span>
                  {agentDetails?.Emp_Code}
                </p>
                <p>
                  <span>Branch :</span>
                  {agentDetails?.BranchName}
                </p>
                <p>
                  <span>City :</span>
                  {agentDetails?.City_Code_RCMS}
                </p>
              </div>
              <div className="grayBg-sec status-infoBox agent-sub">
                <div className="grayBg-sec-left">
                  <p>
                    <span>Mobile No. :</span>
                    {agentDetails?.Tel3}
                  </p>
                </div>
                <div className="grayBg-sec-right panSpan">
                  <p>
                    <span>PAN :</span>
                    {agentDetails?.Panno}
                  </p>
                </div>
              </div>
              <div className="nrmlBg-sec status-infoBox agent-sub">
                <p>
                  <span>Email ID :</span>
                  {agentDetails?.EmailId}
                </p>
              </div>

              <div className="heading-section-blue-bg">
                <div className="showstatusWrap">
                  <div className="left-col">
                    <h3>Immediate Supervisor</h3>
                  </div>
                </div>
              </div>
              <div className="nrmlBg-sec status-infoBox">
                <p>
                  <span>Name :</span>
                  {agentDetails?.Supervisor_Name}
                </p>
                <p>
                  <span>Designation :</span>
                  {agentDetails?.Supervisor_Designation}
                </p>
              </div>
              <div className="grayBg-sec status-infoBox">
                <div className="grayBg-sec-left">
                  <p>
                    <span>Supervisor Code : </span>
                    {agentDetails?.Supervisor_Code}
                  </p>
                  <p>
                    <span>Job Title :</span>
                    {agentDetails?.Supervisor_Job_Title}
                  </p>
                </div>
              </div>
              <div className="nrmlBg-sec status-infoBox">
                <p>
                  <span>Mobile No. :</span>
                  {agentDetails?.Supervisor_Mobile_Number}
                </p>
                <p>
                  <span>Email ID :</span>
                  {agentDetails?.Supervisor_Email_ID}
                </p>
              </div>

              <div className="heading-section-blue-bg">
                <div className="showstatusWrap">
                  <div className="left-col">
                    <h3>Supervisor - 2</h3>
                  </div>
                </div>
              </div>
              <div className="nrmlBg-sec status-infoBox">
                <p>
                  <span>Name :</span>
                  {agentDetails?.Supervisor_2_Name}
                </p>
                <p>
                  <span>Designation :</span>
                  {agentDetails?.Supervisor_2_Designation}
                </p>
              </div>
              <div className="grayBg-sec status-infoBox">
                <div className="grayBg-sec-left">
                  <p>
                    <span>Supervisor Code : </span>
                    {agentDetails?.Supervisor_2_Code}
                  </p>
                  <p>
                    <span>Job Title :</span>
                    {agentDetails?.Supervisor_2_Job_Title}
                  </p>
                </div>
              </div>
              <div className="nrmlBg-sec status-infoBox">
                <p>
                  <span>Mobile No. :</span>
                  {agentDetails?.Supervisor_2_Mobile_Number}
                </p>
                <p>
                  <span>Email ID :</span>
                  {agentDetails?.Supervisor_2_Email_ID}
                </p>
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    agentDetails: state.HierarchyData?.agentDetails,
  };
};
export default connect(mapStateToProps, { HierarchyActions })(Hierarchy);
