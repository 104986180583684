import React, { Component } from "react";
import { convertNumberToWords } from "../utils/utils";
import PropTypes from "prop-types";

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationMsg: "",
      numberLength: 2000,
      amountText: "",
      showError: false,
      inputEditFalse: false,
      inputActive: false,
    };
  }

  isValidMonthYear = (dateString) => {
    if (!/^\d{1,2}\/\d{4}$/.test(dateString)) {
      return false;
    }
    var parts = dateString.split("/");
    var day = "01";
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[1], 10);

    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    return day > 0 && day <= monthLength[month - 1];
  };

  validation = (e, validationType) => {
    this.props.onBlur && this.props.onBlur(e);
    this.setState({
      inputActive: false,
    });
    if (
      this.props.monthYearType &&
      validationType === "monthYear" &&
      e.target.value
    ) {
      if (!this.isValidMonthYear(e.target.value)) {
        this.setState({
          showError: true,
        });
      }
    } else if (
      validationType === "number" &&
      e.target.value &&
      this.props.isApplyNumberLength
    ) {
      e.target.value.length < +this.props.numberLength &&
        this.setState({
          showError: true,
        });
      this.props.handleValidation && this.props.handleValidation(true);
    } else if (validationType === "email" && e.target.value) {
      let mailFormat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      !e.target.value.match(mailFormat) &&
        this.setState({
          showError: true,
        });
      this.props.handleValidation && this.props.handleValidation(true);
    } else if (validationType === "pincode" && e.target.value) {
      if (!(e.target.value.length === 6) || !/[0-9]/.test(e.target.value)) {
        this.setState({
          showError: true,
        });
        this.props.handleValidation && this.props.handleValidation(true);
      }
    } else if (validationType === "mobileNo" && e.target.value) {
      if (!(e.target.value.length === 10) || !/[0-9]/.test(e.target.value)) {
        this.setState({
          showError: true,
        });
        this.props.handleValidation && this.props.handleValidation(true);
      }
    } else if (validationType === "AADHARCARD" && e.target.value) {
      if (!(e.target.value.length === 12) || !/[0-9]/.test(e.target.value)) {
        this.setState({
          showError: true,
        });
        this.props.handleValidation && this.props.handleValidation(true);
      }
    } else if (validationType === "panNo" && e.target.value) {
      if (!(e.target.value.length === 10)) {
        this.setState({
          showError: true,
        });
        this.props.handleValidation && this.props.handleValidation(true);
      }
    } else if (validationType === "IFSC" && e.target.value) {
      if (
        !(
          e.target.value.length === 11 &&
          /([A-Z]){4}([0-9]){7}$/.test(e.target.value.toUpperCase())
        )
      ) {
        this.setState({
          showError: true,
        });
        this.props.handleValidation &&
          this.props.handleValidation(true, e.target.value);
      } else {
        this.props.handleValidation &&
          this.props.handleValidation(false, e.target.value);
      }
    } else if (validationType === "INTERACTION" && e.target.value) {
      if (!(e.target.value.length <= this.props.numberLength)) {
        this.setState({
          showError: true,
        });
        this.props.handleValidation &&
          this.props.handleValidation(true, e.target.value);
      } else {
        this.props.handleValidation &&
          this.props.handleValidation(false, e.target.value);
      }
    } else if (validationType === "proposerRelation" && e.target.value) {
      if (!/[A-Z-']$/.test(e.target.value.toUpperCase())) {
        this.setState({
          showError: true,
        });
        this.props.handleValidation && this.props.handleValidation(true);
      }
    } else if (validationType === "passport" && e.target.value) {
      console.log("test");
      if (
        !(e.target.value.length === 8) ||
        !/^([A-Z]){1}([1-9]){1}([0-9]){1}([0-9]){4}([1-9]){1}?$/.test(
          e.target.value
        )
      ) {
        this.setState({
          showError: true,
        });
        this.props.handleValidation && this.props.handleValidation(true);
      }
    } else if (validationType === "VIC" && e.target.value) {
      if (
        !(e.target.value.length === 10) ||
        !/^([A-Z]){3}([0-9]){7}?$/.test(e.target.value.toUpperCase())
      ) {
        this.setState({
          showError: true,
        });
        this.props.handleValidation && this.props.handleValidation(true);
      }
    } else if (validationType === "DL" && e.target.value) {
      if (
        !(e.target.value.length === 15) ||
        !/^([A-Z]){2}([0-9]){13}?$/.test(e.target.value.toUpperCase())
      ) {
        this.setState({
          showError: true,
        });
        this.props.handleValidation && this.props.handleValidation(true);
      }
    }
    if (this.props.showAmountText) {
      this.setState({
        amountText: convertNumberToWords(e.target.value),
      });
    }
    if (!e.target.value.length) {
      this.props.getLableShrinkValue && this.props.getLableShrinkValue(false);
    } else {
      this.props.getLableShrinkValue && this.props.getLableShrinkValue(true);
    }
  };
  initailFunction = () => {
    if (this.props.numberLength) {
      this.setState({
        numberLength: this.props.numberLength,
      });
    }
    if (this.props.validationMsg) {
      this.setState({
        validationMsg: this.props.validationMsg,
      });
    }
    if (this.props.showAmountText) {
      this.setState({
        amountText: convertNumberToWords(this.props.value),
      });
    }
    if (!this.props.editable) {
      this.setState({
        inputEditFalse: true,
      });
    }
    if (this.props.displayError) {
      this.setState({
        showError: true,
      });
    }
    if (
      this.props.showErrorInputs &&
      (this.props.rules ? this.props.rules.mandatory : true) &&
      this.props.isRequired !== false &&
      !this.props.value
    ) {
      this.setState({
        showError: true,
        validationMsg:
          this.props.validationMsg ||
          (this.props.rules ? this.props.rules.errorMsg : "Field is mandatory"),
      });
    }
  };
  componentDidMount = () => {
    this.initailFunction();
  };
  componentWillReceiveProps = (nextProps) => {
    let showError = false;
    if (this.props.validationMsg !== nextProps.validationMsg) {
      this.setState({
        validationMsg: nextProps.validationMsg,
      });
    }
    if (+this.props.numberLength !== +nextProps.numberLength) {
      this.setState({
        numberLength: +nextProps.numberLength,
      });
    }
    if (this.props.value !== nextProps.value) {
      this.setState({
        showError: false,
      });
    }
    if (this.props.displayError !== nextProps.displayError) {
      this.setState({
        showError: nextProps.displayError,
      });
    }
    if (nextProps.editable) {
      this.setState({
        inputEditFalse: false,
      });
    }
    if (nextProps.showErrorInputs) {
      if (
        nextProps.showErrorInputs &&
        (nextProps.rules ? nextProps.rules.mandatory : true) &&
        nextProps.isRequired !== false &&
        !nextProps.value
      ) {
        showError = true;
      }
    }
    this.setState({
      showError: showError,
      validationMsg:
        nextProps.validationMsg ||
        (nextProps.rules ? nextProps.rules.errorMsg : "Field is mandatory"),
    });
  };
  handleChange = (e) => {
    const {
      onChange,
      editable,
      validationType,
      isRequired,
      noSpecialChar,
      inputType,
      minNumber,
      maxNumber,
      noDecimal,
      onlyAlphabets,
      monthYearType,
    } = this.props;
    let notValid = false;
    if (monthYearType) {
      notValid = notValid
        ? notValid
        : e.target.value
        ? /^[a-zA-Z \b]+$/.test(e.target.value)
        : false;
      const format = /^[0-9 /\b]+$/;
      notValid = notValid
        ? notValid
        : e.target.value
        ? !format.test(e.target.value)
        : false;
    }
    if (noDecimal && inputType == "number" && this.props.numberLength < 15) {
      e.target.value = parseInt(e.target.value, 10);
    }
    if (maxNumber && inputType == "number") {
      notValid = Number(e.target.value) > Number(maxNumber);
    }
    if (e.target.value && minNumber && inputType == "number") {
      notValid = notValid
        ? notValid
        : Number(e.target.value) < Number(minNumber);
    }
    if (noSpecialChar) {
      const format = /^[0-9a-zA-Z \b]+$/;
      // console.log('************* isValid 0',format.test("My@string-with(some%text)") + "<br/>");
      // console.log('************* isValid1',format.test("My string with spaces") + "<br/>");
      // console.log('************* isValid2',format.test("MyStringContainingNoSpecialChars"));
      // var regex = new RegExp("/^[0-9a-zA-Z\_]+$/");
      notValid = notValid
        ? notValid
        : e.target.value
        ? !format.test(e.target.value)
        : false;
      console.log("notValid", notValid);
    }
    if (onlyAlphabets) {
      notValid = notValid
        ? notValid
        : e.target.value
        ? !/^[a-zA-Z \b]+$/.test(e.target.value)
        : false;
    }
    console.log("notValid", notValid);
    if (e.target.value) {
      let x = e.target.value.trimStart();
      if (!x.length) {
        return false;
      }
    }
    if (!notValid) {
      console.log("comming hereee");
      if (
        editable &&
        validationType === "mobileNo" &&
        e.target.value.length <= 10
      ) {
        if (e.target.value && /^\d+$/.test(e.target.value.replace(/,/g, ""))) {
          onChange(e);
        } else if (!e.target.value.length) {
          onChange(e);
        }
      } else if (
        editable &&
        validationType === "pincode" &&
        e.target.value.length <= 6
      ) {
        if (e.target.value && /^\d+$/.test(e.target.value.replace(/,/g, ""))) {
          onChange(e);
        } else if (!e.target.value.length) {
          onChange(e);
        }
      } else if (
        editable &&
        validationType === "passport" &&
        e.target.value.length <= 9
      ) {
        if (e.target.value.length === 9) {
          e.target.value = e.target.value.trimEnd().trimStart().toUpperCase();
          onChange(e);
        } else {
          e.target.value = e.target.value.toUpperCase();
          onChange(e);
        }
      } else if (
        editable &&
        validationType === "number" &&
        e.target.value.length <= +this.props.numberLength
      ) {
        if (e.target.value && /^\d+$/.test(e.target.value.replace(/,/g, ""))) {
          onChange(e);
        } else if (!e.target.value.length) {
          onChange(e);
        } else {
          onChange(e);
        }
      } else if (
        editable &&
        validationType === "panNo" &&
        e.target.value.length <= 10
      ) {
        onChange(e);
      } else if (
        editable &&
        validationType === "IFSC" &&
        e.target.value.length <= 11
      ) {
        onChange(e);
      } else if (
        editable &&
        validationType === "email" &&
        e.target.value.length <= 100
      ) {
        onChange(e);
      } else if (
        editable &&
        validationType !== "mobileNo" &&
        validationType !== "email" &&
        validationType !== "pincode" &&
        validationType !== "panNo" &&
        validationType !== "number" &&
        validationType !== "passport" &&
        e.target.value.length <= this.props.numberLength
      ) {
        onChange(e);
      } else if (
        !editable &&
        isRequired &&
        validationType === "mobileNo" &&
        e.target.value.length <= 10
      ) {
        if (e.target.value && /^\d+$/.test(e.target.value.replace(/,/g, ""))) {
          onChange(e);
        } else if (!e.target.value.length) {
          onChange(e);
        }
      } else if (
        !editable &&
        isRequired &&
        validationType === "panNo" &&
        e.target.value.length <= 10
      ) {
        onChange(e);
      } else if (
        !editable &&
        isRequired &&
        validationType === "pincode" &&
        e.target.value.length <= 6
      ) {
        if (e.target.value && /^\d+$/.test(e.target.value.replace(/,/g, ""))) {
          onChange(e);
        } else if (!e.target.value.length) {
          onChange(e);
        }
      } else if (
        !editable &&
        isRequired &&
        validationType === "number" &&
        e.target.value.length <= this.props.numberLength
      ) {
        if (e.target.value && /^\d+$/.test(e.target.value.replace(/,/g, ""))) {
          onChange(e);
        } else if (!e.target.value.length) {
          onChange(e);
        }
      } else if (
        !editable &&
        isRequired &&
        validationType === "email" &&
        e.target.value.length <= 100
      ) {
        onChange(e);
      } else if (
        !editable &&
        isRequired &&
        validationType !== "mobileNo" &&
        validationType !== "email" &&
        validationType !== "pincode" &&
        validationType !== "panNo" &&
        validationType !== "number" &&
        e.target.value.length <= this.props.numberLength
      ) {
        onChange(e);
      }
    }
  };

  render() {
    const {
      refValue,
      inputType,
      placeholder,
      label,
      name,
      value,
      validationType,
      prefix,
      suffix,
      isRequired,
      cssClass,
      editable,
    } = this.props;
    return (
      <div
        className={`input-container ${cssClass ? cssClass : ""}  ${
          !editable ? "noPointerEvents" : ""
        }`}
      >
        <div className={`input-wrapper`}>
          <label className={`input-label ${!editable ? "editable-false" : ""}`}>
            <span className="label-text">{label}</span>
            {!isRequired && <span className="optional-div"> optional</span>}
          </label>

          <div
            className={`input-fields-wrapper ${
              this.state.inputActive ? "input-active" : ""
            } ${
              this.state.inputEditFalse || !editable ? "editable-false" : ""
            } ${this.state.showError ? "isError" : ""}`}
          >
            {prefix && (value || value == "0") && (
              <span
                className={`input-prefix ${
                  this.state.inputEditFalse ? "editable-false" : ""
                }`}
              >
                {prefix}
              </span>
            )}
            {this.props.isTextArea ? (
              <textarea
                ref={refValue}
                className={`input-field ${
                  prefix && (value || value == "0") ? "only-prefix" : ""
                } ${suffix && (value || value == "0") && "only-suffix"} ${
                  prefix && suffix ? "both" : ""
                } ${this.state.inputEditFalse ? "editable-false" : ""}`}
                type={inputType}
                readOnly={this.state.inputEditFalse}
                placeholder={placeholder || "Enter"}
                onChange={(e) => this.handleChange(e)}
                onBlur={(e) => this.validation(e, validationType)}
                name={name}
                value={value === 0 ? value : value ? value : ""}
                onKeyPress={(evt) => {
                  if (inputType == "number") {
                    if (
                      (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
                      evt.which > 57
                    ) {
                      evt.preventDefault();
                    }
                  }
                  this.props.onKeyPress && this.props.onKeyPress(evt);
                }}
                maxLength={this.state.numberLength}
                onFocus={(e) => {
                  this.props.onInputFocus && this.props.onInputFocus(e);
                  this.setState({
                    showError: false,
                    inputActive: true,
                  });
                  this.props.getLableShrinkValue &&
                    this.props.getLableShrinkValue(true);
                }}
              />
            ) : (
              <input
                ref={refValue}
                className={`input-field ${
                  prefix && (value || value == "0") ? "only-prefix" : ""
                } ${suffix && (value || value == "0") && "only-suffix"} ${
                  prefix && suffix ? "both" : ""
                } ${this.state.inputEditFalse ? "editable-false" : ""}`}
                type={inputType}
                readOnly={this.state.inputEditFalse}
                placeholder={placeholder || "Enter"}
                onChange={(e) => this.handleChange(e)}
                onBlur={(e) => this.validation(e, validationType)}
                name={name}
                value={value === 0 ? value : value ? value : ""}
                onKeyPress={(evt) => {
                  if (inputType == "number") {
                    if (
                      (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
                      evt.which > 57
                    ) {
                      evt.preventDefault();
                    }
                  }
                  this.props.onKeyPress && this.props.onKeyPress(evt);
                }}
                maxLength={this.state.numberLength}
                onFocus={(e) => {
                  this.props.onInputFocus && this.props.onInputFocus(e);
                  this.setState({
                    showError: false,
                    inputActive: true,
                  });
                  this.props.getLableShrinkValue &&
                    this.props.getLableShrinkValue(true);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.props.onKeyDownFunc(e);
                  }
                }}
              />
            )}

            {suffix && (value || value == "0") && (
              <span
                className={`input-suffix ${
                  this.state.inputEditFalse ? "editable-false" : ""
                }`}
              >
                {suffix}
              </span>
            )}
          </div>
        </div>
        {this.props.showAmountText && (
          <div className="amountText">{this.state.amountText}</div>
        )}
        {this.state.showError && (
          <div className="error">{this.state.validationMsg}</div>
        )}
      </div>
    );
  }
}

Input.propTypes = {
  inputType: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  validationType: PropTypes.string,
  isRequired: PropTypes.bool,
  cssClass: PropTypes.string,
  editable: PropTypes.bool,
  validationMsg: PropTypes.string,
  showAmountText: PropTypes.bool,
  displayError: PropTypes.bool,
  isApplyNumberLength: PropTypes.bool,
  onlyAlphabets: PropTypes.bool,
  noDecimal: PropTypes.bool,
};

Input.defaultProps = {
  editable: true,
  isRequired: true,
  placeholder: "Enter",
  numberLength: 2000,
  isApplyNumberLength: false,
  noSpecialChar: false,
  showAmountText: false,
  onlyAlphabets: false,
  noDecimal: false,
  onBlur: false,
};

export default Input;
