import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import check from "../../Assets/images/check.svg";
import { formateDate } from "../../utils/utils";
import { connect } from "react-redux";
import { showCalendarClass } from "../../actions/dashBoardActions";

const Calendar = ({
  onCancel,
  onApply,
  dob,
  showCalendarClass,
  maxDate,
  minDate,
  prevFieldDate,
}) => {
  const [startDate, setStartDate] = useState(dob);
  useEffect(() => {
    showCalendarClass(true);
    return () => {
      showCalendarClass(false);
    };
  }, []);
  useEffect(() => {
    if (dob) {
      setStartDate(dob);
    } else if (prevFieldDate) {
      setStartDate(prevFieldDate);
    } else {
      setStartDate(new Date());
    }
  }, [dob, prevFieldDate]);
  return (
    <div role="calendar_testRole" data-testid="calendar_testId" className="calendar-wrapper">
      <div className="d-r-wrapper">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          inline
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={100}
          dropdownMode="scroll"
          maxDate={maxDate === "false" ? null : !maxDate ? new Date() : maxDate}
          minDate={minDate ? minDate : null}
        />
        <div className="btn-div">
          <button data-testid="calendar_cancelBtn"
            className="cancle-btn"
            onClick={() => {
              setStartDate(null);
              onCancel();
            }}
          >
            Cancel
          </button>
          <button data-testid="calendar_applyBtn"
            className="apply-btn"
            onClick={() => {
              onApply(startDate?.getTime());
            }}
          >
            {/*<img src={check} className="apply-img" alt="apply" />*/}
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};
const actions = {
  showCalendarClass,
};

export default connect(null, actions)(Calendar);
