import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MobileAgentPopUp from "./MobileAgentPopUp";
import { statusApi, applicationData, subStatusList } from "./data";
import OTPInput from "../../components/OTPInput2";
import otpCross from "../../Assets/images/otp-cross-icon.png";
import { convertToIST, DC_CO_label } from "./agentUtils";
import Consent from "./Consent";
import Medical from "./Medical";
import PopUp from "../PopUp";
import {
  downloadAction,
  saveDetailAction,
  videoPIVCAction,
  consentHandlerAction,
  enachInitiated,
} from "./action";
import AddNonPopUp from "./AddNonPopUp";
import {
  resendLink,
  resendFCLink,
  resendConsentLink,
  resendSpecificLink,
  docSubmitAction,
} from "../../actions/appTrackerActions";
import FormToggler from "../../components/FormToggler";
import TpaTab from "../../components/TpaTab";
import CounterOffer from "../../components/CounterOffer";
import { customerPortalStatus } from "../../actions/dashBoardActions";
import { copyLinkToClipboard, toaster } from "../../utils/utils";

// import TpaHeader from '../../components/TpaHeader';
const AgentView = (props) => {
  const dispatch = useDispatch();
  const showLoader = useSelector((state) => state.loaderReducer);
  const customerDetail = useSelector((state) => state.customerDetailReducer);
  const shouldDisableButton =
    customerDetail?.proposalBasicDetails?.issuanceType === "NEWGEN" &&
    (customerDetail?.loginFreeze === true ||
      customerDetail?.issuanceFreeze === true);

  let proposalNumber =
    customerDetail && customerDetail?.proposalBasicDetails?.proposalNumber;
  let policyNumber =
    customerDetail && customerDetail?.proposalBasicDetails?.policyNumber;
  let planName = customerDetail && customerDetail?.planName;

  const accordionDetails =
    customerDetail && customerDetail?.newgenStatusResponseDTOList;
  const addNonMedDocDetail =
    accordionDetails &&
    accordionDetails.filter((item) => {
      return item.status === "ADDITIONAL_NON_MEDICAL_REQUIREMENTS";
    });
  const addNonMedDocLock =
    addNonMedDocDetail && addNonMedDocDetail[0]?.additionalInfo?.docLock;

  const policyDocument = customerDetail && customerDetail?.policyDocuments;
  const insuredAddNonDoc =
    customerDetail &&
    customerDetail?.additionalInfoDocs?.primaryInsuredDocumentDetail?.quesList;
  //    document list
  const addInsuredNonupload =
    customerDetail &&
    customerDetail?.additionalInfoDocs?.primaryInsuredDocumentDetail
      ?.ServiceDocumentList;
  // console.log('addInsuredNonupload',addInsuredNonupload)
  let lockForm =
    addInsuredNonupload &&
    addInsuredNonupload.every((x) => {
      return x.url !== "";
    });
  const proposedAddNonDoc =
    customerDetail &&
    customerDetail?.additionalInfoDocs?.proposerDocumentDetail?.quesList;
  const docData = {
    ...customerDetail?.additionalInfoDocs,
    proposalNumber: proposalNumber,
    id: customerDetail?.additionalInfoDocs?.uwId,
    planName: planName,
  };

  const paymentRequirement =
    customerDetail && customerDetail?.paymentRequirement; // Payment Requirement Data
  const shortFallAmount = paymentRequirement?.shortFallAmount;

  const [openAcc, setOpenAcc] = useState(false);
  const [showPayment, setshowPayment] = useState(false);
  const [showCopy, setShowCopy] = useState(false);
  const [openForm, setOpenForm] = useState(2);
  const [showAddNonMed, setshowAddNonMed] = useState(false);
  const [showAcc, setshowAcc] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [showMedical, setShowMedical] = useState(false);
  const [showFormPayment, setshowFormPayment] = useState(false);
  const [show, setShow] = useState(false);
  const [desciprtion, setDescription] = useState(null);
  const [instaPopup, SetInstaPopup] = useState(false);

  // final submit OTP
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [overlay, setOverlay] = useState(false);
  const [refId, setRefId] = useState();
  const [proposalFinalSubmitted, setproposalFinalSubmitted] = useState(false);
  const [onlineLink, setOnlinkLink] = useState(null);
  const [emandateLink, setEmandateLink]=  useState()

  // enach completed
  const enachCompleted =
    accordionDetails &&
    accordionDetails.filter((item) => item.status === "PAYMENT");
  const showEnach =
    enachCompleted && enachCompleted[0]?.paymentInfo?.enachCompleted;

  // revised payment
  const revisedPayment =
    accordionDetails &&
    accordionDetails.filter((item) => item.status === "REVISED_OFFER");
  const paymentText =
    revisedPayment && revisedPayment[0]?.additionalInfo?.shortfallPremium;

  // conter receipt
  let counterReceipt =
    customerDetail && customerDetail?.counterOfferPaymentReceipts;

  // is proposal submitted
  let isProposalSubmitted =
    accordionDetails &&
    accordionDetails?.filter((ele) => {
      return ele.status === "PROPOSAL_SUBMISSION";
    });
  let instaDetails =
    accordionDetails &&
    accordionDetails?.filter((ele) => {
      return ele.subStatus === "INSTA_VERIFY_STATUS";
    });
  const PivcApprovedOrYes =
    (instaDetails &&
      instaDetails[0]?.additionalInfo?.instaVerify?.PIVCCompleteFlag ===
        "Yes" &&
      instaDetails &&
      instaDetails[0]?.additionalInfo?.instaVerify?.status === 1) ||
    (instaDetails &&
      instaDetails[0]?.additionalInfo?.instaVerify?.PIVCCompleteFlag ===
        "Yes" &&
      instaDetails &&
      instaDetails[0]?.additionalInfo?.instaVerify?.status !== 1 &&
      instaDetails &&
      instaDetails[0]?.additionalInfo?.instaVerify?.status !== -1); // 1 means approved
  const PivcReject =
    instaDetails &&
    instaDetails[0]?.additionalInfo?.instaVerify?.PIVCCompleteFlag === "Yes" &&
    instaDetails &&
    instaDetails[0]?.additionalInfo?.instaVerify?.status === -1; // -1 means rejected
  const salesDisconnect =
    instaDetails &&
    instaDetails[0]?.additionalInfo?.instaVerify?.Disposition?.toLowerCase() ===
      "sales disconnect";

  const completedReceipts =
    customerDetail?.paymentRequirement?.completedPayments
      ?.filter((data) => data.txReferenceNo && data.txReferenceNo.trim() !== "")
      ?.map((data) => data.txReferenceNo); // Filter out all completed payment receipts with empty or null URLs
  const filteredReceipts = new Set(completedReceipts);
  const filteredReceiptsArray = [...filteredReceipts];
  const anyPendingItems =
    customerDetail?.paymentRequirement?.shortFallAmount?.filter(
      (item) =>
        item?.paymentStatus === "PENDING" || item?.paymentStatus === "CREATED"
    );
  const pendingDC_CO = anyPendingItems
    ?.filter((item) => item?.statusCode === "CO" || item?.statusCode === "DC")
    ?.map((item) => DC_CO_label[item?.statusCode]);
  const isPresent = pendingDC_CO?.every((item) =>
    customerDetail?.completedAccordions?.includes(item)
  );

  const revised_offer = accordionDetails?.filter(
    (item) => item?.status === "REVISED_OFFER"
  );
  const data_change = accordionDetails?.filter(
    (item) => item?.status === "QUALITY_CHECK" && item?.subStatus === "DC"
  );
  const mergedDC_CO = revised_offer?.concat(data_change);
  const finalData = mergedDC_CO
    ?.filter((item) => item?.actual_status === "IN-PROGRESS")
    ?.map((data) => data?.status);
  let replacedQCqithDC =
    finalData?.includes("QUALITY_CHECK") &&
    finalData?.splice(finalData.indexOf("QUALITY_CHECK"), 1, "DATA_CHANGE");
  const missingElements = finalData?.filter(
    (item) => !customerDetail?.completedAccordions?.includes(item)
  );
  const pendingPaymentData = missingElements?.map((data) => DC_CO_label[data]);
  const formPayment = accordionDetails?.filter(
    (item) => item?.status === "PAYMENT"
  );
  const paymentToResend = customerDetail?.proposalBasicDetails?.methodOfPayments
    ?.filter(
      (data) =>
        data?.paymentMethod === "ONLINE_LINK" &&
        data?.paymentStatus === false &&
        data?.txPaymentStatus === "CREATED"
    )
    ?.map((item) => ({
      link: item?.link,
      txAmount: item?.txAmount,
      id: item?.id,
    }));
  const consentDetails = customerDetail?.newgenStatusResponseDTOList?.filter(
    (item) => item?.subStatus === "CUSTOMER_CONSENT_STATUS"
  );
  const documentDetails = customerDetail?.newgenStatusResponseDTOList?.filter(
    (item) =>
      item?.subStatus === "Document_Details" &&
      item?.actual_status === "COMPLETED"
  );
// add info list hide
  const isAddInfo =
    (docData?.primaryInsuredDocumentDetail === null ||
      docData?.primaryInsuredDocumentDetail?.quesList === null ||
      docData?.primaryInsuredDocumentDetail?.quesList?.length === 0) &&
    (docData?.proposerDocumentDetail === null ||
      docData?.proposerDocumentDetail?.quesList === null ||
      docData?.proposerDocumentDetail?.quesList?.length == 0);

  const isAddDoc =
    (docData?.primaryInsuredDocumentDetail === null ||
      docData?.primaryInsuredDocumentDetail?.ServiceDocumentList === null ||
      docData?.primaryInsuredDocumentDetail?.ServiceDocumentList?.length ===
        0) &&
    (docData?.proposerDocumentDetail === null ||
      docData?.proposerDocumentDetail?.ServiceDocumentList === null ||
      docData?.proposerDocumentDetail?.ServiceDocumentList?.length === 0);

  const isAddInfoDocExist = isAddInfo && isAddDoc;
  // console.log('isAddInfoDocExist',isAddInfoDocExist)

// functions

  const renderDate = (date) => {
    const istDate = convertToIST(date, false);
    return istDate;
  };
  const renderLeftDate = (date) => {
    const istDate = convertToIST(date, true);
    const leftDate = istDate.split(":");
    return (
      <div className="leftDate">
        <span> {leftDate[0]}</span>
        <span>{leftDate[1] + ":" + leftDate[2]}</span>
      </div>
    );
  };
  const enachPaymentResendLink = customerDetail?.proposalBasicDetails?.renewalPaymentDetails?.enachMcustomerLink
  const enachPaymentLinkMode = customerDetail?.proposalBasicDetails?.renewalPaymentDetails?.paymentMode
  const enachProposalNo = customerDetail?.proposalBasicDetails?.proposalNumber
  const lockedDataCheck = customerDetail?.proposalBasicDetails?.lockedTabsData
  
  const paymentDetailsCheck = lockedDataCheck && Array.isArray(lockedDataCheck) && lockedDataCheck.includes("PAYMENT_DETAILS");
  

  //rigth side completed status
  const completedStatus = (detail) => {
    let dateStatus;
    let subStatusText;
    dateStatus =
      detail && detail[0]?.actual_status === "COMPLETED" ? true : false;
    if (
      detail &&
      detail[0]?.status === "REVISED_OFFER" &&
      detail[0]?.actual_status === "IN-PROGRESS"
    ) {
      return <div>In Progress</div>;
    }
    if (dateStatus) {
      let date = detail && detail[0]?.updatedOn;
      let newdate = renderDate(date);
      //--POLICY_STATUS
      if (
        detail &&
        detail[0]?.status === "POLICY_STATUS" &&
        detail[0]?.subStatus !== "WI"
      ) {
        subStatusText = subStatusList.filter(
          (item) => item.status === detail[0]?.subStatus
        );
        return (
          <div>
            <div>{subStatusText[0]?.agentView}</div>
            <div>{"Completed:" + " " + newdate}</div>
          </div>
        );
      }
      return "Completed:" + " " + newdate;
    } else {
      //-- FINANCIAL_AND_MEDICAL_RISK_VERIFICATION || MEDICAL_RISK_VERIFICATION || QUALITY_CHECK
      if (
        detail &&
        (detail[0]?.status === "FINANCIAL_AND_MEDICAL_RISK_VERIFICATION" ||
          detail[0]?.status === "MEDICAL_RISK_VERIFICATION" ||
          detail[0]?.status === "QUALITY_CHECK" ||
          detail[0]?.status === "ADDITIONAL_NON_MEDICAL_REQUIREMENTS")
      ) {
        subStatusText = subStatusList.filter(
          (item) => item.status === detail[0]?.subStatus
        );
        return (
          <div>
            {subStatusText.length > 0 ? (
              subStatusText[0]?.agentView
            ) : (
              <div>Yet to Start</div>
            )}
          </div>
        );
      }
      //--POLICY_STATUS
      else if (
        detail &&
        detail[0]?.status === "POLICY_STATUS" &&
        (detail[0]?.subStatus === "MP" ||
          detail[0]?.subStatus === "NM" ||
          detail[0]?.subStatus === "MA" ||
          detail[0]?.subStatus === "MR" ||
          detail[0]?.subStatus === "TC" ||
          detail[0]?.subStatus === "WI")
      ) {
        subStatusText = subStatusList.filter(
          (item) => item.status === detail[0]?.subStatus
        );
        return <div>{subStatusText[0]?.agentView}</div>;
      } else {
        let status = detail && detail[0]?.actual_status;
        return (
          <div>{status === "IN-PROGRESS" ? "In-progress" : "Yet to start"}</div>
        );
      }
    }
  };

  const counterToggle = (value) => {
    setShowCounter(value);
  };
  const consentToggle = (value) => {
    setShowConsent(value);
  };
  const medicalToggle = (value) => {
    setShowMedical(value);
  };

  const renderCreateOn = (title) => {
    let detail;
    detail =
      accordionDetails &&
      accordionDetails?.filter((item) => {
        return item.status === title;
      });
    if (title === "DOC_UPLOAD") {
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.subStatus === "Document_Details";
        });
      return completedStatus(detail);
    }

    if (title === "PAYMENT_REQUIREMENT") {
      if (
        detail &&
        detail[0]?.actual_status === "IN-PROGRESS" &&
        shortFallAmount.length === 0
      ) {
        return <div>{"Completed"}</div>;
      }
    }

    if (title === "FORM_FILLING") {
      let detailSub =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.status === "PROPOSAL_SUBMISSION";
        });

      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.subStatus === "Personal_Details";
        });
      if (
        detailSub?.[0]?.actual_status === "COMPLETED" &&
        detailSub?.[0]?.status === "PROPOSAL_SUBMISSION"
      ) {
        return completedStatus(detailSub);
      } else {
        return <div className="policyNumShow">{"In-progress"}</div>;
      }
    }
    if (title === "PROPOSAL") {
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.subStatus === "Health_Details";
        });
      return completedStatus(detail);
    }
    if (
      title === "Personal_Details" ||
      title === "Insured_Details" ||
      title === "Nominee_Details" ||
      title === "Health_Details" ||
      title === "INSTA_VERIFY_STATUS" ||
      title === "CUSTOMER_CONSENT_STATUS"
    ) {
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.subStatus === title;
        });
      return completedStatus(detail);
    }
    if (title === "PAYMENT_REQUIREMENT") {
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.status === "PAYMENT_REQUIREMENT";
        });
      // console.log("detail>>>", detail);

      return completedStatus(detail);
    }
    return completedStatus(detail);
  };

  //  left date time show
  const rendertime = (title) => {
    let detail;
    let dateStatus;
    if (title === "FORM_FILLING") {
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.status === "PROPOSAL_SUBMISSION";
        });
      // dateStatus =
      //   detail && detail[0]?.actual_status === "COMPLETED" ? true : false;
      // return dateStatus;
    } else {
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.status === title;
        });
    }
    dateStatus =
      detail && detail[0]?.actual_status === "COMPLETED" ? true : false;
    // console.log('dateStatus',dateStatus)
    if (dateStatus) {
      let date = detail[0]?.updatedOn;
      let newdate = renderLeftDate(date);
      return newdate;
    }
    // else {
    //     return <div>-</div>
    // }
  };

  const downloadHandler = (fileName) => {
    if (fileName in policyDocument) {
      let file = policyDocument[fileName];
      dispatch(downloadAction(proposalNumber, file));
    } else {
      toaster("error", "File not exist");
    }
  };

  // ----
  const formFillingToggle = () => {
    setOpenAcc(!openAcc);
  };
  const toggleForm = (val) => {
    if (val < openForm) {
      setOpenForm(val);
    } else if (val !== openForm) {
      setOpenForm(val);
    } else if (val === openForm) {
      setOpenForm(val);
    }
  };
  const downloadPdf = (e, item) => {
    dispatch(
      downloadAction(
        proposalNumber,
        "Thyroid_Disorder_Questionnaire.pdf",
        (res) => {}
      )
    );
  };
  const uploadData = (e, item) => {};
 
  const renderAddNonDoc = (data) => {
    return (
      <ul>
        {data?.map((item, idx) => {
          return (
            <li className=" margin20">
              <div className="progressWrapper">
                <span>{item.documentCdValue}</span>
                <div
                  className="download_icon"
                  onClick={(e) => downloadPdf(e, item)}
                ></div>
                <div
                  className="upload_icon"
                  onClick={(e) => uploadData(e, item)}
                ></div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };
  const completeFormCheck = (item) => {
    dispatch(customerPortalStatus(proposalNumber, (res) => {}));
  };

  // download recipt
  const downloadReceipt = (e, item) => {
    dispatch(downloadAction(proposalNumber, item, (res) => {}));
  };

  //enach handler
  const enachHandler = () => {
    dispatch(resendLink("emandate", proposalNumber));
  };

  const resendLinkHandler = (title, amount, id) => {
    if (missingElements?.length > 0 && title === "add-info") {
      toaster(
        "error",
        `Please complete the pending action ${pendingPaymentData?.join(", ")}`
      );
      return;
    }

    let data = {
      currencyName: "INR",
      customerEmailId: customerDetail?.proposalBasicDetails?.emailId,
      customerMobileNo: customerDetail?.proposalBasicDetails?.mobileNo,
      id: id ? id : "",
      paymentMethod: "ONLINE_LINK",
      proposalId: customerDetail?.proposalBasicDetails?.proposalId,
      encryptProposalNo: customerDetail?.proposalBasicDetails?.uniqueProposalId,
      quoteNumber: null,
      txAmount:
        title === "form-fill"
          ? amount
          : customerDetail?.paymentRequirement?.totalAmount,
      emandate: "",
      onlyEmandate: "",
    };
    dispatch(
      resendSpecificLink(
        data,
        customerDetail?.proposalBasicDetails?.uniqueProposalId,
        (resp) => {
          if (resp?.data?.body?.paymentUrl) {
            setOnlinkLink(resp.data.body?.paymentUrl);
            setShowCopy(true);
          }
        }
      )
    );
  };

  //Insta Verify && consent handler

  const consentHandler = (title) => {
    if (title === "Insta Verify") {
      // SetInstaPopup(true)
      dispatch(resendLink("insta", proposalNumber));
      SetInstaPopup(false);
    } else if (title === "Customer Consent") {
      dispatch(resendConsentLink(proposalNumber));
    }
  };

  const cancelInstaHnadler = () => {
    SetInstaPopup(false);
    toaster("error", "PIVC link is not retriggered");
  };

  //agentConsentHandler
  const agentConsentHandler = () => {
    dispatch(resendFCLink(proposalNumber));
  };

  // green tick
  const renderComplete = (title, subStatus, heading) => {
    // console.log("klkl", title);
    let dateStatus;
    let detail;
    let addNonMedComplete;
    const Health_Details =
      accordionDetails &&
      accordionDetails?.filter((item) => {
        return item.subStatus === "Health_Details";
      });
    const completedTabs = customerDetail?.completedAccordions;
    // Check if the title is in the completedTabs array
    if (completedTabs?.includes(title)) {
      return (dateStatus = true);
    }
    if (
      title === "QUALITY_CHECK" &&
      heading === "Consent For Change In The Application Details" &&
      completedTabs?.includes("DATA_CHANGE")
    ) {
      return (dateStatus = true);
    }
    if (title === "PROPOSAL") {
      //  detail =
      // accordionDetails &&
      // accordionDetails?.filter((item) => {
      //   return item.subStatus ===  "Health_Details";
      // });
      dateStatus =
        Health_Details && Health_Details[0]?.actual_status === "COMPLETED"
          ? true
          : false;
      return dateStatus;
    }
    if (title === "FORM_FILLING") {
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.status === "PROPOSAL_SUBMISSION";
        });
      dateStatus =
        detail && detail[0]?.actual_status === "COMPLETED" ? true : false;
      return dateStatus;
    }

    if (title === "INSTA_VERIFY_STATUS") {
      return (dateStatus = PivcApprovedOrYes);
    } else if (title === "DOC_UPLOAD") {
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.subStatus === "Document_Details";
        });
    } else if (
      subStatus === "Personal_Details" ||
      subStatus === "Insured_Details" ||
      subStatus === "Nominee_Details" ||
      subStatus === "Health_Details"
    ) {
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.subStatus === subStatus;
        });
    } else if (title === "CUSTOMER_CONSENT_STATUS") {
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.subStatus === title;
        });
    } else {
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.status === title;
        });
    }
    if (title === "ADDITIONAL_NON_MEDICAL_REQUIREMENTS") {
      addNonMedComplete = detail && detail[0]?.additionalInfo?.docLock;
      detail =
        accordionDetails &&
        accordionDetails?.filter((item) => {
          return item.status === "ADDITIONAL_NON_MEDICAL_REQUIREMENTS";
        });
      dateStatus =
        detail && detail[0]?.actual_status === "COMPLETED" && addNonMedComplete
          ? true
          : false;
    } else {
      dateStatus =
        detail && detail[0]?.actual_status === "COMPLETED" ? true : false;
    }
    return dateStatus;
  };

  //dynamic accoridion
  const renderList = (accordionDetails, item) => {
    let accStatus = true;
    let tpaList = customerDetail?.tpaRequirementDetails;
    let consent = accordionDetails?.filter((item) => {
      return item.status === "QUALITY_CHECK";
    });
    const dataChangeData = Object.keys(customerDetail?.dataChangeRequirement?.oldAndNewDataDetails ?? {})?.length === 0;
    let isConsent = consent && Object.keys(consent[0]?.additionalInfo)?.length === 0;
    let detail;
    detail =
      accordionDetails &&
      accordionDetails?.filter((ele) => {
        return ele.status === item.title;
      });
    let sub_details =
      accordionDetails &&
      accordionDetails?.filter((ele) => {
        return ele.subStatus === item.title;
      });
    let accDetail = detail && detail[0]?.subStatus;
    let status_acc = detail && detail[0]?.actual_status;
    
    if (
      item.title === "ADDITIONAL_NON_MEDICAL_REQUIREMENTS" &&
      item.heading === "Additional Non-Medical Requirements"
    ) {
      if (
        accDetail === null ||
        accDetail === undefined ||
        accDetail === "" || isAddInfoDocExist
        // ||
        // (accDetail !== "AR" && accDetail !== "AI" && accDetail !== "NP")
      ) {
        accStatus = false;
      }
      
    } else if (
      item.title === "REVISED_OFFER" &&
      item.heading === "Revised Offer"
    ) {
      // if (
      //   accDetail === null ||
      //   accDetail === undefined ||
      //   accDetail === "" ||
      //   accDetail !== "CO"
      // ) {
      //   accStatus = false;
      // }
      if (
        (status_acc !== "IN-PROGRESS" && status_acc !== "COMPLETED") ||
        !customerDetail?.counterOfferRequirement
      ) {
        accStatus = false;
      }
      if (
        (status_acc === "IN-PROGRESS" || status_acc === "COMPLETED") &&
        customerDetail?.counterOfferRequirement
      ) {
        accStatus = true;
      }
    } else if (
      item.title === "PAYMENT_REQUIREMENT" &&
      item.heading === "Payment Required"
    ) {
      // if (accDetail === null || accDetail === undefined || accDetail === "") {
      //   accStatus = false;
      // }

      // if (
      //   (!customerDetail?.paymentRequirement &&
      //     customerDetail?.paymentRequirement?.shortFallAmount?.length === 0) ||
      //   (!customerDetail?.paymentRequirement &&
      //     customerDetail?.paymentRequirement?.completedPayments?.length ===
      //       0) ||
      //   (customerDetail?.paymentRequirement &&
      //     customerDetail?.paymentRequirement?.shortFallAmount?.length === 0 &&
      //     customerDetail?.paymentRequirement?.completedPayments?.length === 0)
      // ) {
      //   accStatus = false;
      // }

      const paymentReceiptExit =
        customerDetail &&
        customerDetail?.paymentRequirement?.completedPayments?.filter(
          (item) => item.txReferenceNo !== null
        );

      if (shortFallAmount?.length === 0) {
        accStatus = false;
      }
      if (paymentReceiptExit?.length === 0) {
        accStatus = false;
      }
      if (paymentReceiptExit?.length > 0) {
        accStatus = true;
      }
      if (shortFallAmount?.length > 0) {
        accStatus = true;
      }
    } else if (
      item.title === "QUALITY_CHECK" &&
      item.heading === "Consent For Change In The Application Details"
    ) {
      if (
        accDetail === null ||
        accDetail === undefined ||
        accDetail === "" ||
        (isConsent && dataChangeData)
        // ||
        // accDetail !== "DC"
      ) {
        accStatus = false;
      }
      if (
        accDetail && 
        (!isConsent || !dataChangeData)
        // ||
        // accDetail !== "DC"
      ) {
        accStatus = true;
      }
    } else if (
      item.title === "MEDICAL_RISK_VERIFICATION" &&
      item.heading === "Medical Risk Verification"
    ) {
      if (accDetail === null || accDetail === undefined || accDetail === "") {
        accStatus = false;
      }
    } else if (
      item.title === "MEDICAL_REQUIREMENT" &&
      item.heading === "Medical Requirement"
    ) {
      // if (accDetail === null || accDetail === undefined) {
      //   accStatus = false;
      // }
      if (
        (tpaList?.insuredList?.length <= 0 &&
          tpaList?.ownerList?.length <= 0) ||
        !tpaList ||
        Object?.keys(tpaList)?.length <= 0
      ) {
        accStatus = false;
      }
    } else if (
      item.title === "CUSTOMER_CONSENT_STATUS" &&
      item.heading === "Customer Consent"
    ) {
      accStatus =
        sub_details &&
        sub_details[0]?.additionalInfo?.customerConsentRequired === true;
    } else if (
      item.title === "INSTA_VERIFY_STATUS" &&
      item.heading === "Insta Verify"
    ) {
      accStatus =
        sub_details && sub_details[0]?.additionalInfo?.isInstaRequired === true;
    }
    return accStatus;
  };

  // proposer detail
  const renderProposer = (elem, accordionDetails) => {
    let showElement;
    let proposalFormList =
      accordionDetails &&
      accordionDetails?.filter((ele) => {
        return ele.status === "PROPOSAL";
      });
    let Personal_Details =
      proposalFormList &&
      proposalFormList.filter((item) => {
        return item.subStatus === "Personal_Details";
      });
    let Insured_Details =
      proposalFormList &&
      proposalFormList.filter((item) => {
        return item.subStatus === "Insured_Details";
      });
    let Nominee_Details =
      proposalFormList &&
      proposalFormList.filter((item) => {
        return item.subStatus === "Nominee_Details";
      });
    let Health_Details =
      proposalFormList &&
      proposalFormList.filter((item) => {
        return item.subStatus === "Health_Details";
      });
    let proposalReversedList = [];
    proposalReversedList &&
      proposalReversedList.push(
        Personal_Details && Personal_Details[0],
        Insured_Details && Insured_Details[0],
        Nominee_Details && Nominee_Details[0],
        Health_Details && Health_Details[0]
      );
    let listItem = proposalReversedList.filter((ele) => {
      return ele !== undefined;
    });
    showElement = listItem && listItem?.length !== 0 && (
      <ul className="formFillingAcc">
        {listItem &&
          listItem?.map((item, idx) => {
            return (
              <li
                key={idx}
                className={`StepProgress-item ${
                  renderComplete(item?.title, item?.subStatus) && "is-done"
                }`}
              >
                <span className="strong">
                  {item?.subStatus === "Personal_Details" &&
                    `Basic detail Section (${
                      customerDetail?.proposalBasicDetails?.proposerName !==
                      null
                        ? customerDetail?.proposalBasicDetails?.proposerName
                        : "Proposed"
                    })`}
                  {item?.subStatus === "Insured_Details" &&
                    `Insured detail Section (${
                      customerDetail?.proposalBasicDetails?.insuredName !== null
                        ? customerDetail?.proposalBasicDetails?.insuredName
                        : "Insured"
                    })`}
                  {item?.subStatus === "Nominee_Details" &&
                    `Nominee detail Section`}
                  {item?.subStatus === "Health_Details" &&
                    `Health Questionnaires section`}
                </span>
                <span>{renderCreateOn(item?.subStatus)}</span>
              </li>
            );
          })}
      </ul>
    );
    return showElement;
  };
  // final submit doc handler
  const submitDocHandler = () => {
    let payload = {
      proposalNumber: proposalNumber,
      policyNumber: policyNumber,
      uwId: customerDetail?.additionalInfoDocs?.uwId,
      otp: "",
      docLock: lockForm, //when all doc upload
      questionLock: false,
    };
    dispatch(
      docSubmitAction(payload, () => {
        dispatch(customerPortalStatus(proposalNumber, () => {}));
      })
    );
  };

  const finalSubmitHandler = () => {
    // let serviceDocumentList = customerDetail?.counterOfferRequirement?.counterOfferDetails?.serviceDocumentList;
    // let allURLS = serviceDocumentList?.filter(doc => doc.url && doc.url.trim() !== "") // Filter out documents with empty or null URLs
    // .map(doc => doc.url);

    if (
      proposalFinalSubmitted ||
      customerDetail?.proposalBasicDetails?.proposalFinalSubmitted
    ) {
      return;
    }
    let proposalNumber = customerDetail?.proposalBasicDetails?.proposalNumber;
    const submitData = {
      otp: "",
      requestType: "SEND_OTP",
      proposalNumber: proposalNumber,
      additionalNonMedicalQuestionnaires: {
        uwId: customerDetail?.additionalInfoDocs?.uwId,
        docLock: true,
        questionLock: true,
      },
      submitOtpRequest: {
        consentFor: "FINAL_SUBMIT_OTP",
        templateId: "1",
        otp: "",
        refId: "",
      },
    };
    dispatch(
      saveDetailAction(submitData, (res) => {
        if (res?.proposalBasicDetails?.refId) {
          setShowOtp(true);
          setRefId(res?.proposalBasicDetails?.refId);
          setOverlay(true);
          onCloseButton();
        }
      })
    );
  };

  const setDescAndShowMed = (showData, data) => {
    setShow(showData);
    setDescription(data);
  };
  const onCloseButton = () => {
    let proposalNumber = customerDetail?.proposalBasicDetails?.proposalNumber;
    dispatch(customerPortalStatus(proposalNumber, (res) => {}));
  };
  //
  const submitForm = () => {
    let proposalNumber = customerDetail?.proposalBasicDetails?.proposalNumber;
    if (otp.length !== 6) {
      return;
    }
    const submitData = {
      otp: otp,
      requestType: "VALIDATE_OTP",
      proposalNumber: proposalNumber,
      additionalNonMedicalQuestionnaires: {
        uwId: customerDetail?.additionalInfoDocs?.uwId,
        docLock: true,
        questionLock: true,
      },
      submitOtpRequest: {
        consentFor: "FINAL_SUBMIT_OTP",
        templateId: "1",
        otp: otp,
        refId: refId,
      },
    };
    dispatch(
      saveDetailAction(
        submitData,
        (res, resp) => {
          if (res?.proposalBasicDetails?.proposalFinalSubmitted) {
            setShowOtp(false);
            setOtp("");
            setOverlay(false);
            setproposalFinalSubmitted(
              res?.proposalBasicDetails?.proposalFinalSubmitted
            );
          }
          if (resp?.data?.status !== "OK") {
            setOtp("");
          }
        },
        () => {
          setOtp("");
        }
      )
    );
  };

  return (
    <>
      {showLoader["isLoaderOn"] && (
        <div className="loader-overlay">
          <div className="loader-img"></div>
        </div>
      )}
      <MobileAgentPopUp
        onClose={props.onClose}
        heading={
          customerDetail?.proposalBasicDetails?.firstName +
          " " +
          customerDetail?.proposalBasicDetails?.lastName
        }
        showStatus={props.showStatus}
        cssClass={props.cssClass}
        showOnDesktop={props.showOnDesktop}
        details={props.details}
        customerDetail={customerDetail}
        popUpBottom={""}
        showExceptionStatus={props.showExceptionStatus}
      >
        <div className="progressBarWrap margin20">
          <div className="progressWrapper">
            <ul className="StepProgress">
              {applicationData &&
                applicationData.map((item, idx) => {
                  // if (!renderList(accordionDetails, item)) {
                  //     return
                  //   }
                  return (
                    <li
                      key={idx}
                      className={`StepProgress-item 
                                    ${
                                      renderComplete(
                                        item.title,
                                        null,
                                        item.heading
                                      ) && "is-done"
                                    }
                                    ${
                                      item.heading ===
                                        "Additional Non-Medical Requirements" ||
                                      item.heading === "Revised Offer" ||
                                      item.heading ===
                                        "Consent For Change In The Application Details" ||
                                      item.heading === "Payment Required" ||
                                      item.heading ===
                                        "Medical Risk Verification" ||
                                      item.heading === "Medical Requirement"
                                        ? renderList(accordionDetails, item)
                                          ? "showAcc"
                                          : "hideAcc"
                                        : ""
                                    }
                                    `}
                      onClick={
                        // item.heading === "Form Filling"
                        //   ? formFillingToggle
                        //   :
                        item.heading === "Additional Non-Medical Requirements"
                          ? () => setshowAddNonMed(true)
                          : item.heading === "Revised Offer"
                          ? () => counterToggle(true)
                          : item.heading === "Medical Requirement"
                          ? () => medicalToggle(true)
                          : item.heading ===
                            "Consent For Change In The Application Details"
                          ? () => consentToggle(true)
                          : ""
                      }
                    >
                      <>{rendertime(item.title)}</>
                      <span
                        onClick={
                          item.heading === "Form Filling" && formFillingToggle
                        }
                        className={`strong 
                                        ${
                                          (item.heading === "Form Filling" ||
                                            item.heading ===
                                              "Additional Non-Medical Requirements" ||
                                            item.heading === "Revised Offer" ||
                                            item.heading ===
                                              "Medical Requirement" ||
                                            item.heading ===
                                              "Consent For Change In The Application Details") &&
                                          "strongText"
                                        }
                                        `}
                      >
                        {/* accordion heading */}
                        {item.heading === "Revised Offer"
                          ? "Counter Offer"
                          : item.heading}
                      </span>

                      {/* quote */}
                      {item.heading === "Quote Generated" && (
                        <div className="policyNumShow">
                          <span className="timewrap">
                            {renderCreateOn(item.title)}
                          </span>
                          <button
                            className="btn btn-link  btn-link-resendLink"
                            onClick={() => downloadHandler("BI_TAG_NAME")}
                          >
                            Download BI
                          </button>
                        </div>
                      )}
                      {/* form filling */}
                      {item.heading === "Form Filling" && (
                        <>
                          {renderCreateOn(item.title)}
                          {openAcc && (
                            <ul className="formFillingAcc">
                              {item.content.map((ele, idx) => {
                                // console.log('ele',ele)
                                return (
                                  <li
                                    key={idx}
                                    className={`StepProgress-item
                                                                ${
                                                                  ele.heading ===
                                                                    "Insta Verify" ||
                                                                  ele.heading ===
                                                                    "Customer Consent"
                                                                    ? renderList(
                                                                        accordionDetails,
                                                                        ele
                                                                      )
                                                                      ? "showAcc"
                                                                      : "hideAcc"
                                                                    : ""
                                                                }
                                                                ${
                                                                  renderComplete(
                                                                    ele.title
                                                                  ) && "is-done"
                                                                } 
                                                                ${
                                                                  ele.heading ===
                                                                    "Insta Verify" &&
                                                                  PivcReject &&
                                                                  "is-instaRejected"
                                                                }
                                                                `}
                                  >
                                    <span className="strong">
                                      {ele.heading}
                                    </span>
                                    <span>
                                      {
                                        // ele.heading === "Insta Verify" ||
                                        // ele.heading === "Customer Consent" ||
                                        // ele.heading === "Payment"
                                        //   ? ""
                                        //   :
                                        renderCreateOn(ele.title)
                                      }
                                    </span>
                                    {ele.heading === "Proposal Form" &&
                                      renderProposer(ele, accordionDetails)}
                                    {ele.heading === "Insta Verify" &&
                                      // customerDetail?.proposalBasicDetails
                                      //   ?.instaRequired === true &&
                                      !PivcApprovedOrYes && (
                                        <div className="policyNumShow">
                                          <span>
                                            To initiate the Video PIVC
                                          </span>
                                          <button
                                            className="btn btn-link  btn-link-resendLink"
                                            onClick={() => {
                                              if (salesDisconnect) {
                                                SetInstaPopup(true);
                                              } else {
                                                consentHandler(ele.heading);
                                              }
                                              // consentHandler(ele.heading)
                                              // SetInstaPopup(true)
                                            }}
                                          >
                                            Click Here
                                          </button>
                                        </div>
                                      )}
                                      {
                                        ele.heading === "Customer Consent" &&
                                        consentDetails &&
                                        consentDetails[0]?.actual_status !==
                                          "COMPLETED" && (
                                            <div  className="policyNumShow">
                                             <span><strong>Reason:</strong>{ consentDetails[0]?.additionalInfo?.customerConsentRejectedReason }
                                            </span>
                                            </div>
                                          )
                                          
                                      }
                                    {ele.heading === "Customer Consent" &&
                                      consentDetails &&
                                      consentDetails[0]?.actual_status !==
                                        "COMPLETED" && (
                                        // customerDetail?.proposalBasicDetails
                                        //   ?.customerConsentRequired === true &&
                                        <div className="policyNumShow">
                                          <span>
                                            To initiate the Customer Consent
                                          </span>
                                          <button
                                            className="btn btn-link  btn-link-resendLink"
                                            onClick={() =>
                                              consentHandler(ele.heading)
                                            }
                                          >
                                            Click Here
                                          </button>
                                        </div>
                                      )}
                                    {ele.heading === "Payment" && (
                                      <div className="policyNumShow">
                                        {formPayment?.[0]?.actual_status !==
                                          "COMPLETED" &&
                                          paymentToResend.length > 0 && (
                                            <div>
                                              <button
                                                type="button"
                                                class="btn btn-link  btn-link-resendLink"
                                                onClick={() =>
                                                  setshowFormPayment(
                                                    !showFormPayment
                                                  )
                                                }
                                                disabled={shouldDisableButton}
                                              >
                                                {!showFormPayment
                                                  ? "Show Payment Links"
                                                  : "Hide Payment Links"}
                                              </button>
                                              <span>
                                                {" "}
                                                |
                                                <button
                                                  type="button"
                                                  class="btn btn-link  btn-link-resendLink"
                                                  onClick={() => {
                                                    copyLinkToClipboard(
                                                      paymentToResend[
                                                        paymentToResend?.length -
                                                          1
                                                      ]?.link
                                                    );
                                                  }}
                                                  disabled={shouldDisableButton}
                                                >
                                                  Copy Payment Link
                                                </button>
                                              </span>
                                              {shouldDisableButton && (
                                                <div className="warning-message">
                                                  The link cannot be resent due
                                                  to a freezing Time.
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        {/* <div>
                                          Click here to initiate the
                                          <button
                                            className="btn btn-link  btn-link-resendLink"
                                            onClick={enachHandler}
                                          >
                                            Enach
                                          </button>
                                        </div> */}
                                        {showFormPayment && (
                                          <div>
                                            {paymentToResend.map((item) => {
                                              return (
                                                <div className="paymentLinsWrap">
                                                  <div className="paymentLinsRow">
                                                    <div className="txnAmount mr-5">
                                                      {<>&#8377;</>}{" "}
                                                      {item.txAmount}
                                                    </div>
                                                    <button
                                                      className="btn btn-link  btn-link-resendLink"
                                                      onClick={() =>
                                                        resendLinkHandler(
                                                          "form-fill",
                                                          item.txAmount,
                                                          item.id
                                                        )
                                                      }
                                                    >
                                                      Resend Link
                                                    </button>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        )}
                                        {customerDetail?.paymentReceipts
                                          ?.length > 0 && (
                                          <div>
                                            <span className="timewrap">
                                              Receitps :
                                            </span>
                                            {customerDetail?.paymentReceipts?.map(
                                              (item, idx) => (
                                                <div
                                                  className="receipt"
                                                  key={idx}
                                                  onClick={(e) =>
                                                    downloadReceipt(e, item)
                                                  }
                                                >
                                                  <span className="timewrap strongText">
                                                    {item}
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                    {(!paymentDetailsCheck && enachPaymentLinkMode === "E_MANDATE" && enachPaymentResendLink)  && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-link btn-link-resendLink"
                                        onClick={resendLink(
                                          "emandate",
                                          enachProposalNo
                                        )}
                                        // onClick={resendLink("emandate", data)}
                                      >
                                        Resend Emandate Link
                                      </button>
                                      {"| "}
                                      <button
                                        type="button"
                                        className="btn btn-link  btn-link-resendLink"
                                        onClick={
                                          //   getCopyLink(
                                          //   "E_MANDATE_LINK",
                                          //   this.props.activeApptrackerPID
                                          // )
                                          () => {
                                            copyLinkToClipboard(
                                              enachPaymentResendLink
                                            );
                                          }
                                        }
                                      >
                                        Copy E-Mandate Link
                                      </button>
                                    </>
                                  )}  
                                      </div>
                                      
                                      
                                    )}
                                    
                                    {ele.heading === "Proposal Submission" && (
                                      <div className="policyNumShow">
                                        {consentDetails &&
                                          consentDetails[0]?.additionalInfo
                                            ?.agentConsentRequired &&
                                          consentDetails &&
                                          !consentDetails[0]?.additionalInfo
                                            ?.agentConsent &&
                                          documentDetails?.length > 0 && (
                                            <div>
                                              <button
                                                className="btn btn-link  btn-link-resendLink"
                                                onClick={agentConsentHandler}
                                              >
                                                Resend Agent Consent link
                                              </button>
                                            </div>
                                          )}
                                        {isProposalSubmitted[0]
                                          ?.actual_status === "COMPLETED" && (
                                          <div className="policyNumShow">
                                            Policy Number :
                                            <span> {policyNumber}</span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </>
                      )}
                      {/* Medical Requirement*/}
                      {item.heading === "Medical Requirement" && (
                        <div className="policyNumShow">
                          <span className="timewrap">
                            {renderCreateOn(item.title)}
                          </span>
                        </div>
                      )}
                      {/* Additional Non-Medical Requirements*/}
                      {item.heading ===
                        "Additional Non-Medical Requirements" && (
                        <div className="policyNumShow">
                          <span className="timewrap">
                            {renderCreateOn(item.title)}
                          </span>
                        </div>
                      )}
                      {/* Revised Offer*/}
                      {/* {item.heading === "Revised Offer" && (
                        <div className="policyNumShow">
                          <button
                            className="btn btn-link  btn-link-resendLink"
                            onClick={() => downloadHandler("REVISED_OFFER_DOC")}
                          >
                            Counter Offer Letter
                          </button>
                          <button
                            className="btn btn-link  btn-link-resendLink"
                            onClick={() => downloadHandler("REVISED_BI_DOC")}
                          >
                            Revised Benefit Illustration
                          </button>
                        </div>
                      )} */}
                      {item.heading === "Revised Offer" && (
                        <div className="policyNumShow">
                          <span className="timewrap">
                            {renderCreateOn(item.title)}
                          </span>
                        </div>
                      )}
                      {/* Consent For Change In The Application Details*/}
                      {item.heading ===
                        "Consent For Change In The Application Details" && (
                        <div className="policyNumShow">
                          <span className="timewrap">
                            {renderCreateOn(item.title)}
                          </span>
                          {/* <button
                            className="btn btn-link  btn-link-resendLink"
                            onClick={() => downloadHandler("REVISED_BI_DOC")}
                          >
                            Revised Benefit Illustration
                          </button>
                          <button
                            className="btn btn-link  btn-link-resendLink"
                            onClick={() => downloadHandler("PDF_TAG_NAME")}
                          >
                            Revised Proposal Form
                          </button>
                          <button
                            className="btn btn-link  btn-link-resendLink"
                            onClick={() => downloadHandler("COVID_TAG_NAME_2")}
                          >
                            Covid Questionnaire
                          </button>
                          <button
                            className="btn btn-link  btn-link-resendLink"
                            onClick={() => downloadHandler("FORM60_TAG_NAME")}
                          >
                            Form 60
                          </button> */}
                        </div>
                      )}
                      {/* Payment Required*/}
                      {item.heading === "Payment Required" && (
                        <>
                          <span className="timewrap">
                            {renderCreateOn(item.title)}
                          </span>
                          {customerDetail?.paymentRequirement?.totalAmount >
                            0 && (
                            <div>
                              <button
                                type="button"
                                class="btn btn-link  btn-link-resendLink"
                                onClick={() => setshowPayment(!showPayment)}
                                disabled={shouldDisableButton}
                              >
                                {!showPayment
                                  ? "Show Payment Links"
                                  : "Hide Payment Links"}
                              </button>
                              {showCopy && (
                                <span>
                                  |
                                  <button
                                    type="button"
                                    class="btn btn-link  btn-link-resendLink"
                                    onClick={() => {
                                      copyLinkToClipboard(
                                        onlineLink ||
                                          customerDetail?.paymentRequirement
                                            ?.completedPayments?.[0]
                                            ?.paymentLink ||
                                          customerDetail?.paymentRequirement
                                            ?.shortFallAmount?.[0]?.paymentLink
                                      );
                                    }}
                                    disabled={shouldDisableButton}
                                  >
                                    Copy Payment Link
                                  </button>
                                </span>
                              )}
                              {shouldDisableButton && (
                                <div className="warning-message">
                                  The link cannot be resent due to a freezing
                                  Time.
                                </div>
                              )}
                            </div>
                          )}
                          {showPayment &&
                            customerDetail?.paymentRequirement &&
                            customerDetail?.paymentRequirement?.shortFallAmount
                              ?.length > 0 && (
                              <div className="paymentLinsWrap">
                                <div className="paymentLinsRow">
                                  <span className=" mr-5 txnAmount">
                                    <span className="currency-icon">
                                      &#8377;
                                    </span>
                                    {
                                      customerDetail?.paymentRequirement
                                        ?.totalAmount
                                    }
                                  </span>
                                  <span className="resendLink">
                                    <button
                                      type="button"
                                      className="btn btn-link d-block btn-link-resendLink"
                                      onClick={() => {
                                        resendLinkHandler("add-info");
                                        // setShowCopy(true);
                                      }}
                                    >
                                      Resend Link
                                    </button>
                                  </span>
                                </div>
                              </div>
                            )}
                          {completedReceipts?.length > 0 && (
                            <div>
                              <span className="timewrap">Receitps :</span>
                              {filteredReceiptsArray?.map((item, idx) => (
                                <div
                                  className="receipt"
                                  key={idx}
                                  onClick={(e) => downloadReceipt(e, item)}
                                >
                                  <span className="timewrap strongText">
                                    {item}
                                  </span>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                      {/* Quality Check*/}
                      {item.heading === "Quality Check" && (
                        <div className="policyNumShow">
                          <span className="timewrap">
                            {renderCreateOn(item.title)}
                          </span>
                        </div>
                      )}
                      {/* Medical Risk Verification */}
                      {item.heading === "Medical Risk Verification" && (
                        <div className="policyNumShow">
                          <span className="timewrap">
                            {renderCreateOn(item.title)}
                          </span>
                        </div>
                      )}
                      {/* Financial and Medical Risk Verification */}
                      {item.heading ===
                        "Financial and Medical Risk Verification" && (
                        <div className="policyNumShow">
                          <span className="timewrap">
                            {renderCreateOn(item.title)}
                          </span>
                        </div>
                      )}
                      {/* policy decision */}
                      {item.heading === "Policy Decision" && (
                        <div className="policyNumShow">
                          <span className="timewrap">
                            {renderCreateOn(item.title)}
                          </span>
                        </div>
                      )}
                    </li>
                  );
                })}
            </ul>
            <button
              className="agentViewBtn"
              onClick={() => finalSubmitHandler()}
              disabled={
                customerDetail?.proposalBasicDetails?.proposalFinalSubmitted ||
                proposalFinalSubmitted
              }
            >
              Final Submit
            </button>

            {/* otp block */}
            <div
              className="overlay__popup_nw"
              style={{ display: overlay ? "block" : "none" }}
            ></div>
            {showOtp && (
              <div
                className={`header-otp-popup popupcmn ${showOtp && "active"}`}
              >
                <div className="header-otp-popup-head">
                  <div className="header-otp-popup-left">
                    <h2> OTP </h2>
                  </div>
                  <div className="header-reject-popup-right">
                    <img
                      onClick={() => {
                        setShowOtp(false);
                        setOverlay(false);
                        onCloseButton();
                      }}
                      src={otpCross}
                      alt="otp-cross-icon"
                    />{" "}
                  </div>
                </div>

                <OTPInput
                  otp={otp}
                  resendFunc={() => {
                    setOtp("");
                    finalSubmitHandler();
                    //    this.showOtpHandler();
                  }}
                  //  triggerTimmer={this.state.showOTP}
                  getOTP={(otp) => setOtp(otp)}
                  otpKeyDownFunc={() => {}}
                />

                <div className="header-reject-popup-content-fot">
                  <button
                    onClick={submitForm}
                    className={`sbmt_btn ${
                      otp.length !== 6 ? "disabled-btn" : ""
                    }`}
                  >
                    Submit
                  </button>
                </div>
                {/* <div className="header-reject-popup-content-fot">
            <button onClick={submitHandler} className="sbmt_btn">
              Submit
            </button>
          </div> */}
              </div>
            )}
          </div>
        </div>
      </MobileAgentPopUp>
      {/* loader */}
      {/* {showLoader["isLoaderOn"] && (
            <div className="loader-overlay">
              <div className="loader-img"></div>
            </div>
          )} */}

      {/* add info */}
      {showAddNonMed && (
        <AddNonPopUp
          onClose={() => setshowAddNonMed(false)}
          heading={"Additional Non-Medical Requirements"}
          showStatus={props.showStatus}
          cssClass={"full-screen"}
          showOnDesktop={props.showOnDesktop}
          details={props.details}
          customerDetail={customerDetail}
          popUpBottom={""}
          showExceptionStatus={props.showExceptionStatus}
        >
          <div className="container tpa-container">
            {!isAddInfoDocExist ? (
              <>
                <div className="tpa-wrapper">
                  {isAddInfo ? (
                    ""
                  ) : (
                    <TpaTab
                      // tpaData={this.props.tpaData}
                      completeFormCheck={completeFormCheck}
                      data={docData}
                      formLock={false}
                      docTypeQuesOrDoc="quesList"
                      customerDetail={customerDetail}
                    />
                  )}
                </div>
                <div className="tpa-wrapper">
                  {isAddDoc ? (
                    ""
                  ) : (
                    <TpaTab
                      // tpaData={this.props.tpaData}
                      completeFormCheck={completeFormCheck}
                      data={docData}
                      formLock={false}
                      docTypeQuesOrDoc="ServiceDocumentList"
                      hideSection={!addNonMedDocLock}
                      customerDetail={customerDetail}
                    />
                  )}
                </div>
              </>
            ) : (
              <>Document lists are not available!</>
            )}
            {/* {!addNonMedDocLock && (
              <button
                className="agentViewBtn"
                onClick={() => submitDocHandler()}
                disabled={!lockForm}
              >
                Submit
              </button>
            )} */}
          </div>
        </AddNonPopUp>
      )}
      {/* counter offer */}
      {showCounter && (
        <AddNonPopUp
          onClose={() => setShowCounter(false)}
          heading={"Counter Offer"}
          showStatus={props.showStatus}
          cssClass={"full-screen"}
          cssClassBtm={"bottom-content"}
          showOnDesktop={props.showOnDesktop}
          details={props.details}
          customerDetail={customerDetail}
          popUpBottom={""}
          showExceptionStatus={props.showExceptionStatus}
        >
          <CounterOffer
            customerDetail={customerDetail}
            counterOfferRequirement={customerDetail?.counterOfferRequirement}
            proposalBasicDetails={customerDetail?.proposalBasicDetails}
            documentList={policyDocument}
            accordionDetails={accordionDetails}
          />
        </AddNonPopUp>
      )}
      {/* data change */}
      {showConsent && (
        <AddNonPopUp
          onClose={() => setShowConsent(false)}
          heading={"Consent For Change In The Application Details"}
          showStatus={props.showStatus}
          cssClass={"full-screen"}
          cssClassBtm={"bottom-content"}
          showOnDesktop={props.showOnDesktop}
          details={props.details}
          customerDetail={customerDetail}
          popUpBottom={""}
          showExceptionStatus={props.showExceptionStatus}
        >
          <Consent
            accDetails={customerDetail}
            accordionDetails={accordionDetails}
            proposalNo={customerDetail?.proposalBasicDetails?.proposalNumber}
            policyNo={customerDetail?.proposalBasicDetails?.policyNumber}
          />
        </AddNonPopUp>
      )}

      {showMedical && (
        <AddNonPopUp
          onClose={() => setShowMedical(false)}
          heading={"Medical Requirement"}
          showStatus={props.showStatus}
          cssClass={"full-screen"}
          cssClassBtm={"bottom-content"}
          showOnDesktop={props.showOnDesktop}
          details={props.details}
          customerDetail={customerDetail}
          popUpBottom={""}
          showExceptionStatus={props.showExceptionStatus}
        >
          <Medical
            accDetails={customerDetail}
            // accordionDetails={accordionDetails}
            // proposalNo={customerDetail?.proposalBasicDetails?.proposalNumber}
            // policyNo={customerDetail?.proposalBasicDetails?.policyNumber}
            setDescAndShowMed={setDescAndShowMed}
          />
        </AddNonPopUp>
      )}

      {show && (
        <PopUp
          onClose={() => setShow(false)}
          heading={""}
          // showStatus={props.showStatus}
          addCss={"toolText-popup"}
          // medPopoup={popupHeight}
          showOnDesktop={true}
        >
          {desciprtion}
        </PopUp>
      )}
      {instaPopup && (
        <PopUp
          onClose={() => SetInstaPopup(false)}
          heading={""}
          // showStatus={props.showStatus}
          addCss={"toolText-popup"}
          // medPopoup={popupHeight}
          showOnDesktop={true}
        >
          Have you spoken to the policyholder and clarified all the doubts to
          proceed PIVC
          <div className="d-flex insta-wrap">
            <div
              className="insta-btn insta-yes"
              onClick={() => {
                consentHandler("Insta Verify");
              }}
            >
              Yes
            </div>
            <div
              className="insta-btn insta-no"
              onClick={() => {
                cancelInstaHnadler();
              }}
            >
              No
            </div>
          </div>
        </PopUp>
      )}
    </>
  );
};

export default AgentView;
