const sortOptions = [
  {
    name: "LAST UPDATE",
    reqKey: "LastUpdated",
  },
  {
    name: "APPLICATION DATE",
    reqKey: "ApplicationDate",
  },
  {
    name: "OWNER NAME",
    reqKey: "OwnerName",
  },
  {
    name: "PROPOSAL NO.",
    reqKey: "proposalNo",
  },
  {
    name: "PREMIUM",
    reqKey: "premium",
  },
  {
    name: "SUM ASSURED",
    reqKey: "sumAssured",
  },
];

export default sortOptions;
