import React from "react";
import { connect } from "react-redux";
import MobilePopUp from "./../MobilePopUp/MobilePopUp";
import {
  fetchStatus,
  clearStatus,
  resendLink,
  fetchPaymentLinks,
  resendSpecificLink,
  resendFCLink,
  getCopyLink,
  callCopyLink,
  triggerInsta2Notification,
  resendConsentLink,
} from "../../actions/appTrackerActions";
import { customerPortalStatus } from "../../actions/dashBoardActions";
import { agentCode, copyLinkToClipboard } from "../../utils/utils";
import linkIcon from "../../Assets/images/linkIcon.svg";
import FormToggler from "../../components/FormToggler";
import AgentView from "../AgentView/AgentView";
class AppTracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      instaVerifyLink: "",
      emandateLink: "",
      onlinePaymentLink: "",
      openForm: 2,
    };
  }

  componentDidMount() {
    const { customerId, proposalId, proposalNo, uniqueProposalId } =
      this.props.data;
    let payload = {
      customerId: customerId,
      proposalId: proposalId,
    };
    console.log("proposalId", proposalId);
    this.props.fetchStatus(agentCode, uniqueProposalId, payload, (res) => {
      console.log(`res`, res);
      res.map((item) => {
        if (
          item.status === "Insta Verify" &&
          item?.links &&
          item?.links[0]?.link
        ) {
          this.setState({
            instaVerifyLink: item?.links && item?.links[0].link,
          });
        }
        if (
          item.status === "Payment" &&
          item?.links?.find((item1) => item1.paymentMethods === "EMANDATE")
        ) {
          this.setState({
            emandateLink: item?.links?.find(
              (item1) => item1.paymentMethods === "EMANDATE"
            )?.link,
          });
        }
        if (
          item.status === "Payment" &&
          item.links.find((item1) => item1.paymentMethods === "Online Link")
        ) {
          this.setState({
            onlinePaymentLink: item?.links?.find(
              (item1) => item1.paymentMethods === "Online Link"
            )?.link,
          });
        }
      });
    });

    this.props.fetchPaymentLinks(uniqueProposalId);

    // for agent view -- customer portal api call
    if (this.props.showAgentStatus) {
      this.props.customerPortalStatus(proposalNo, () => {});
    }
  }
  toggleForm = (val) => {
    if (val < this.state.openForm) {
      this.setState({
        openForm: val,
      });
    } else if (val !== this.state.openForm) {
      this.setState({
        openForm: val,
      });
    } else if (val === this.state.openForm) {
      this.setState({
        openForm: 2,
      });
    }
  };
  componentWillUnmount() {
    this.props.clearStatus();
  }

  toggleLinks = () => {
    this.setState({
      showDetails: !this.state.showDetails,
    });
  };

  render() {
    console.log("showAgentStatus", this.props.showAgentStatus);
    const { data, appStatus, paymentStatus, resendFCLink } = this.props;
    console.log("data>>", data);
    // let issuaneData = [];
    const policyData = appStatus.filter(
      (item, index) => item.status === "Policy Issued"
    );
    // const issuaneData = policyData.length > 0 && policyData[0] && policyData[0].workflowStageStatusMap
    const finalData =
      policyData &&
      policyData.length > 0 &&
      policyData[0] &&
      policyData[0].underwritingDecisionStatuses;

    const resendInstaWhtsapArr = [
      appStatus.find((el) => el.status === "Personal Details"),
    ];

    const resendInstaWhatsap = resendInstaWhtsapArr?.[0]?.completed;
    // // console.log("issuance",p_data)
    // p_data.length > 0 && p_data[0] && p_data[0].underwritingDecisionStatuses &&
    //   p_data[0].underwritingDecisionStatuses.forEach((item) => {
    //     if (!issuaneData.length) {
    //       issuaneData = [item]
    //     } else {

    //       let duplicateObj = issuaneData.findIndex(itm => itm.workFlowStage === item.workFlowStage);

    //       if (duplicateObj !== -1) {
    //         issuaneData[duplicateObj] = item
    //       } else {
    //         issuaneData = [...issuaneData, item]
    //       }
    //     }
    //   });
    // console.log("issuance==", issuaneData)
    // const finalData = {}
    // Object.keys(issuaneData) && Object.keys(issuaneData).length > 0 &&
    //   Object.keys(issuaneData).forEach((val, index) => {
    //     if (val && val !== "null") {
    //       console.log("checking data====", val, issuaneData[val])
    //       if (issuaneData[val] && issuaneData[val].length > 0) {
    //         let item1 = issuaneData[val][0]
    //         issuaneData[val].forEach((item, index) => {
    //           if (item1 && item1.id < item.id) {
    //             item1 = item;
    //           }
    //         })
    //         finalData[val] = [item1];
    //       } else {
    //         finalData[val] = issuaneData[val]
    //       }

    //     }
    //   });
    // console.log("=========>checking", finalData)
    // console.log('this.props.customerDetail',this.props.customerDetail)
    return (
      <>
        {!this.props.showAgentStatus ? (
          <MobilePopUp
            heading={data && data.customerName}
            onClose={this.props.toggleStatusModal}
            showStatus={true}
            cssClass="statusCard"
            showOnDesktop={true}
            details={data}
          >
            <div className="padding10">
              {appStatus && appStatus.length > 0 && (
                <FormToggler
                  toggleForm={this.toggleForm}
                  openForm={this.state.openForm}
                  cssClass2="doc-toggler"
                  index={0}
                  heading={`Proposal App Tracker`}
                >
                  <div className="progressBarWrap margin20">
                    <div className="progressWrapper">
                      <ul className="StepProgress">
                        {appStatus &&
                          appStatus.length > 0 &&
                          appStatus.map((item, index) => {
                            const date = item.date ? item.date.trim() : null;
                            const dateTimeObj = date && date.split(" ");
                            const dataObj =
                              date &&
                              date.length > 0 &&
                              dateTimeObj[0].split("-");
                            const timeObj =
                              date &&
                              date.length > 0 &&
                              dateTimeObj[1].split(":");
                            const status =
                              item && item.status && item?.status.split("_");
                            // console.log("STATUSSSSSSSS", status.join(" ").toLowerCase())
                            const resendPaymnetLink =
                              item &&
                              item.status?.toLowerCase() === "payment" &&
                              item.link1 &&
                              item.link1.toLowerCase() === "created";
                            const resendEmandateLink =
                              item &&
                              item.status?.toLowerCase() === "payment" &&
                              item.link2 &&
                              item.link2.toLowerCase() === "created";
                            const resendInstaLink =
                              item &&
                              item.status?.toLowerCase() === "insta verify" &&
                              item.link1 &&
                              item.link1.toLowerCase() === "created";
                            console.log("DATAAAAA", resendInstaLink);
                            const resendFincareConsentLink =
                              item &&
                              item.status?.toLowerCase() === "declaration" &&
                              item.link1 &&
                              item.link1.toLowerCase() === "created";
                            const fincareConsentLinkCompleted =
                              item &&
                              item.status?.toLowerCase() === "declaration" &&
                              item.link1 &&
                              item.link1.toLowerCase() === "completed";
                            return (
                              <>
                                {item.status !== "Policy Issued" && (
                                  <li
                                    key={`status-${index}`}
                                    className={`StepProgress-item ${
                                      item.completed
                                        ? "is-done"
                                        : item.status?.toLowerCase() ===
                                            "insta verify" &&
                                          item.link1 &&
                                          item.link1.toLowerCase() ===
                                            "rejected"
                                        ? "is-rejected"
                                        : item.status ===
                                            "Customer Consent Verify" &&
                                          item.links &&
                                          item.links[0]
                                            ?.customerConsentRejected === true
                                        ? "is-consentRejected"
                                        : item.status === "Declaration" &&
                                          item.links &&
                                          item.links[0]
                                            ?.agentConsentRejected === true
                                        ? "is-consentRejected"
                                        : ""
                                    }`}
                                  >
                                    <span className="leftDate">
                                      {dataObj &&
                                        dataObj.length > 0 &&
                                        dataObj[0]}{" "}
                                      {dataObj &&
                                        dataObj.length > 0 &&
                                        dataObj[1]}
                                      <span className="timewrap">
                                        {timeObj &&
                                          timeObj.length > 0 &&
                                          timeObj[0] + ":" + timeObj[1]}
                                      </span>
                                    </span>
                                    <span className="strong">
                                      {status && status.join(" ").toLowerCase()}
                                    </span>
                                    {item.status ===
                                      "Customer Consent Verify" &&
                                      item.completed === false &&
                                      item?.links &&
                                      item.links?.length > 0 &&
                                      item?.links[0]?.link && (
                                        <button
                                          className="btn btn-link  btn-link-resendLink"
                                          onClick={resendConsentLink(
                                            data.proposalNo
                                          )}
                                          // disabled={!item.completed}
                                        >
                                          Resend Consent Link
                                        </button>
                                      )}
                                    {/* Insta Verify Status  */}
                                    {item.status === "Insta Verify" &&
                                      item?.links &&
                                      item?.links.length > 0 &&
                                      item.links[0] &&
                                      item.links[0].instaRequired === true && (
                                        <div className="policyNumShow">
                                          Insta-Verify Eligible
                                          <br />
                                          <button
                                            className="btn btn-link  btn-link-resendLink"
                                            onClick={triggerInsta2Notification(
                                              data.proposalNo
                                            )}
                                            disabled={!resendInstaWhatsap}
                                          >
                                            Resend Insta Whatsapp notification
                                          </button>
                                        </div>
                                      )}
                                    {item.status === "Insta Verify" &&
                                      item?.links &&
                                      item?.links.length > 0 &&
                                      (item.links[0] === null ||
                                        item.links[0].instaRequired ===
                                          false) && (
                                        <div className="policyNumShow">
                                          Insta-Verify Not Required
                                        </div>
                                      )}
                                    {item?.status === "Payment" &&
                                      item?.transactionId && (
                                        <div className="policyNumShow">{`Transaction ID : ${item?.transactionId}`}</div>
                                      )}
                                    {resendPaymnetLink &&
                                      paymentStatus &&
                                      paymentStatus.length !== 0 && (
                                        <>
                                          <button
                                            type="button"
                                            className="btn btn-link  btn-link-resendLink"
                                            onClick={() => this.toggleLinks()}
                                          >
                                            {this.state.showDetails
                                              ? "Hide Payment Links"
                                              : "Show Payment Links"}
                                          </button>
                                          {"| "}
                                          <button
                                            type="button"
                                            className="btn btn-link  btn-link-resendLink"
                                            onClick={
                                              //   getCopyLink(
                                              //   "ONLINE_PAYMENT_LINK",
                                              //   this.props.activeApptrackerPID
                                              // )
                                              () => {
                                                copyLinkToClipboard(
                                                  this.state.onlinePaymentLink
                                                );
                                              }
                                            }
                                          >
                                            Copy Payment Link
                                          </button>
                                        </>
                                      )}
                                    {this.state.showDetails &&
                                      resendPaymnetLink &&
                                      paymentStatus &&
                                      paymentStatus.length !== 0 && (
                                        <div className="paymentLinsWrap">
                                          {paymentStatus.map((item, i) => {
                                            return (
                                              <div
                                                className="paymentLinsRow"
                                                Key={i}
                                              >
                                                <span className="txnAmount">
                                                  <img
                                                    src={linkIcon}
                                                    alt="Link Icon"
                                                  ></img>
                                                  <span className="currency-icon">
                                                    &#8377;
                                                  </span>
                                                  {item.txAmount}
                                                </span>
                                                {item &&
                                                item.txPaymentStatus?.toLowerCase() ===
                                                  "expired" ? (
                                                  <span className="expiredLink">
                                                    Expired
                                                  </span>
                                                ) : (
                                                  <span className="resendLink">
                                                    <button
                                                      type="button"
                                                      className="btn btn-link d-block btn-link-resendLink"
                                                      onClick={resendSpecificLink(
                                                        item,
                                                        data.uniqueProposalId
                                                      )}
                                                    >
                                                      Resend Link
                                                    </button>
                                                  </span>
                                                )}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      )}
                                    {/* {resendPaymnetLink && <button type="button" className="btn btn-link d-block btn-link-resendLink" onClick={resendLink('payment', data.proposalNo)}>Resend Payment Link</button>}  */}
                                    {resendEmandateLink && (
                                      <>
                                        <button
                                          type="button"
                                          className="btn btn-link btn-link-resendLink"
                                          onClick={resendLink(
                                            "emandate",
                                            data.proposalNo
                                          )}
                                          // onClick={resendLink("emandate", data)}
                                        >
                                          Resend Emandate Link
                                        </button>
                                        {"| "}
                                        <button
                                          type="button"
                                          className="btn btn-link  btn-link-resendLink"
                                          onClick={
                                            //   getCopyLink(
                                            //   "E_MANDATE_LINK",
                                            //   this.props.activeApptrackerPID
                                            // )
                                            () => {
                                              copyLinkToClipboard(
                                                this.state.emandateLink
                                              );
                                            }
                                          }
                                        >
                                          Copy E-Mandate Link
                                        </button>
                                      </>
                                    )}
                                    {resendInstaLink && (
                                      <>
                                        <button
                                          type="button"
                                          className="btn btn-link  btn-link-resendLink"
                                          onClick={resendLink("insta", data)}
                                        >
                                          Resend Insta Link
                                        </button>
                                        {"| "}
                                        <button
                                          type="button"
                                          className="btn btn-link  btn-link-resendLink"
                                          onClick={
                                            //   getCopyLink(
                                            //   "INSTA_VERIFY_LINK",
                                            //   this.props.activeApptrackerPID
                                            // )
                                            () => {
                                              copyLinkToClipboard(
                                                this.state.instaVerifyLink
                                              );
                                            }
                                          }
                                        >
                                          Copy Instaverify Link
                                        </button>
                                      </>
                                    )}
                                    {resendFincareConsentLink && (
                                      <button
                                        type="button"
                                        className="btn btn-link d-block btn-link-resendLink"
                                        onClick={() =>
                                          resendFCLink(
                                            data.proposalNo,
                                            this.props.subChannelName
                                          )
                                        }
                                      >
                                        Resend SP Verification Link
                                      </button>
                                    )}
                                    {fincareConsentLinkCompleted && (
                                      <button
                                        type="button"
                                        className="btn btn-link d-block btn-link-resendLink completed"
                                        onClick={() =>
                                          resendFCLink(
                                            data.proposalNo,
                                            this.props.subChannelName
                                          )
                                        }
                                      >
                                        Resend SP Verification Link
                                      </button>
                                    )}

                                    {item.status === "Proposal Submitted" && (
                                      <div className="policyNumShow">
                                        Policy Number :
                                        <span> {data.policyNumber}</span>
                                      </div>
                                    )}
                                    {item.status === "Underwriting" && (
                                      <div className="policyNumShow">
                                        Auto Underwriting Decision :{" "}
                                        <span>{data.overallUWDecisionCd}</span>
                                      </div>
                                    )}
                                  </li>
                                )}
                              </>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </FormToggler>
              )}
              {
                // Object.keys(finalData) && Object.keys(finalData).length > 0 &&
                finalData && finalData.length > 0 && (
                  <div className="margin20">
                    <FormToggler
                      toggleForm={this.toggleForm}
                      openForm={this.state.openForm}
                      cssClass2="doc-toggler"
                      index={1}
                      heading={`Policy Issuance App Tracker`}
                    >
                      <div className="progressBarWrap margin20">
                        <div className="progressWrapper">
                          <ul className="StepProgress">
                            {/* {Object.keys(finalData) && Object.keys(finalData).length > 0 &&
                        Object.keys(finalData).map((val, index) => { */}
                            {finalData &&
                              finalData.length > 0 &&
                              finalData.map((item, index) => {
                                return (
                                  <>
                                    {item && item.workFlowStage !== "null" && (
                                      <li
                                        className={`StepProgress-item ${
                                          item.uwDecision === "Completed" ||
                                          item.uwDecision === "Verified" ||
                                          item.uwDecision === "Complete" ||
                                          item.uwDecision === "Issued" ||
                                          (item.workFlowStage ===
                                            "Payment Collected" &&
                                            item.uwDecision ===
                                              "Acknowledge") ||
                                          (item.workFlowStage ===
                                            "Policy Status" &&
                                            item.uwDecision === "Policy Issued")
                                            ? "is-done"
                                            : ""
                                        }`}
                                        // className="issuance_StepProgress-item"
                                        key={item.workFlowStage}
                                      >
                                        <span className="strong">
                                          {item.workFlowStage}
                                        </span>
                                        {/* {finalData[val] && finalData[val].length > 0 && */}
                                        <div>
                                          {item.showLink &&
                                          item.workFlowStage !==
                                            "Medical TPA" ? (
                                            <button
                                              className="btn btn-link btn-link-resendLink"
                                              onClick={(e) =>
                                                this.props.openTpaProp(
                                                  e,
                                                  data,
                                                  item
                                                )
                                              }
                                            >
                                              {/* {issuaneData[val][issuaneData[val].length - 1].uwDecision} */}
                                              Additional Documents
                                            </button>
                                          ) : (
                                            item.uwDecision
                                          )}
                                        </div>
                                        {/* } */}
                                      </li>
                                    )}
                                  </>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </FormToggler>
                  </div>
                )
              }
            </div>
          </MobilePopUp>
        ) : (
          <AgentView
            onClose={this.props.toggleStatusModal}
            heading={data && data.customerName}
            showStatus={true}
            cssClass="statusCard"
            showOnDesktop={true}
            details={data}
            showExceptionStatus={true}
            // customerDetail={this.props.customerDetail}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  console.log("state>>", state);
  return {
    appStatus: state.TrackerData.appStatus,
    paymentStatus: state.TrackerData.paymentStatus,
    copyLink: state.TrackerData.copyLink,
    // instaVerifyLink: state.TrackerData.instaVerifyLink,
    // emandateLink: state.TrackerData.emandateLink,
    // onlinePaymentLink: state.TrackerData.onlinePaymentLink
    // customerDetail: state.customerDetailReducer
  };
};
export default connect(mapStateToProps, {
  fetchStatus,
  clearStatus,
  fetchPaymentLinks,
  resendSpecificLink,
  resendFCLink,
  getCopyLink,
  callCopyLink,
  customerPortalStatus,
})(AppTracker);
