import React, { Children } from "react";
import minusImg from "../../Assets/images/minus.svg";
import plusImg from "../../Assets/images/plus.svg";
import checkImg from "../../Assets/images/check.svg";
import errorIcon from "../../Assets/images/errorStepper.svg";
let timer = 0;
class FormToggler extends React.Component {

    render() {
        const { heading, children, openForm, index, cssClass, hasError, cssClass2, disableMark, isPwrRider, toggleForm } = this.props;
        const isOpen = index === openForm ? true : false

        return (
            <div className={`FormTogglerWrap ${cssClass2} ${isOpen ? 'expanded-section' : 'collapsed-section'}`}>
                <div ref={heading} className={`formToggleDiv ${isOpen ? 'openInfo' : ''}`} onClick={() => toggleForm(index,isPwrRider)}>{heading}
                    {isOpen?
                        <img style={{ width: '12px' }} src={minusImg} alt="close" />
                        :<img style={{ width: '12px' }} src={plusImg} alt="open"/>
                    }
                    {!disableMark && (hasError ? <img className="checkMark" src={errorIcon} alt="check"/> : ( cssClass?.includes('visited') && <img className="checkMark" src={checkImg} alt="check"/>))}
                </div>
                {<div className="FormInfoDiv" style={{display:isOpen?'block':'none'}}>
                    {children}
                </div>}
            </div>
        );
    }
    componentDidUpdate(prevProps){   
        const lastOpen = prevProps.openForm===this.props.index;    
        const { openForm, index } = this.props;
        const isOpen = index === openForm ? true : false
        // if(isOpen && !lastOpen){
        //     clearTimeout(timer);
        //     timer = setTimeout(()=>{                
        //         let currentDiv = document.querySelectorAll('.FormTogglerWrap')[index-1];
        //         let previousDiv = document.querySelectorAll('.FormTogglerWrap')[index-2];
        //         let yPosition = (currentDiv.offsetTop - currentDiv.scrollTop + currentDiv.clientTop);
        //         if(currentDiv && previousDiv && ((currentDiv.clientHeight+yPosition) > window.innerHeight)){
        //                 previousDiv.scrollIntoView(true);
        //         }
        //         clearTimeout(timer);
        //     },200)
        // }
    }
}

export default FormToggler;
