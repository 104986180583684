import React from "react";
import searchImg from "../../Assets/images/search.svg";
import closeImg from '../../Assets/images/close.svg'

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
  }
  handelChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };
  checkEnterKey = (e) => {
    if (e && e.key === "Enter") {
      this.props.applySearch(this.state.searchText);
    }
  };
  applySearch = () => {
    this.props.applySearch(this.state.searchText);
  };
  clearSearch = () => {
    this.setState(
      {
        searchText: "",
      },
      () => {
        this.props.applySearch(this.state.searchText);
      }
    );
  };
  componentWillReceiveProps = (nextProps) => {
    if (this.props.searchQuery !== nextProps.searchQuery) {
      this.setState({
        searchText: nextProps.searchQuery,
      });
    }
  };
  render() {
    if (this.props.isVisible) {
      return (
        <div data-testid="searchBox_component" className="searchBox-mainWrap">
          <div className="app-g-search">
            {this.props.showIcon && !this.state.searchText && (
              <img
                className="searchIcon"
                alt="Search"
                src={searchImg}
                onClick={() => this.checkEnterKey()}
              />
            )}
            <i
              className="fa fa-arrow-left backBtn"
              onClick={this.clearSearch}
            ></i>
            <input
              type="text"
              placeholder="Search"
              className="app-g-search-input"
              value={this.state.searchText}
              onChange={this.handelChange}
              onKeyUp={this.checkEnterKey}
            />
            {this.state.searchText && (
              <img
              className="searchIcon"
              alt="close"
              src={closeImg}
              onClick={this.clearSearch}
            />
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
export default SearchBox;
